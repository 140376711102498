.LandingPage2Template .homepageBanner #login-container {

  @media (max-width:991px) {
    position: relative;
    left: 0px;
  }
}

.GigyaLoginPageTemplate .BodyContent #login-container {
  position: absolute;
  margin-left: 50px;
  z-index: 1;
}
.seqirusb2b-flu360 .GigyaLoginPageTemplate .BodyContent #login-container {
  margin-left: 30px;
}

.GigyaLoginPageTemplate .no-link{
      border-top: 1px solid #DBE0E2
}

@media only screen and (min-width: 912px) {
  .GigyaLoginPageTemplate .BodyContent {
    #login-container_content {
      margin: 0 0;
    }
  }
}

@media screen and (min-width: 1280px) {
  .GigyaLoginPageTemplate .BodyContent #login-container {
    margin-left: 70px;
    margin-top: 52px;
  }
  .seqirusb2b-flu360 .GigyaLoginPageTemplate .BodyContent #login-container {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
   .GigyaLoginPageTemplate .BodyContent {
    #login-container {
      margin-left: 20px;
    }

    .gigya-login-form {
      padding: 0px !important;
    }

    .login_logintext {
      margin-right: 0px;
      margin-left: 0px;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .seqirusb2b-flu360 .GigyaLoginPageTemplate .BodyContent {
    #login-container {
      position: relative !important;
      margin-left: 0;
    }

    .gigya-login-form {
      padding: 20px !important;
    }

    cx-banner {
      display: none;
    }
  }
}

@media only screen and (max-width: 991px) {
  .GigyaLoginPageTemplate .BodyContent {
    #login-container {
      position: relative !important;
      margin-left: 0;
    }

    .gigya-login-form {
      padding: 20px !important;
    }

    .login_logintext {
      margin-right: 0;
    }

    cx-banner {
      display: none;
    }
  }
}

.homepageBanner {
  app-custom-gigya-raas {
    position: absolute;

    @media (max-width:991px) {
      position: relative;
    }

    right: 0;
    top:50px;
  }

  .gigya-login-form {
    width: 100%;
    display: flex;
    justify-content: end;
    .login_container{
      box-shadow: none !important;
    }
  }

  .login_container {
    margin-right: 0px;
  }
}

.myprofile_tophybrisheader {
  color: #2A3237;
  font-family: 'CamptonSemiBold';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 31px;
}

.profile_view app-custom-gigya-raas {
  width: 88% !important;
  margin-top: 17px;
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 17px;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid #DBE0E2;
}
@media only screen and (max-width:991px) {
  .profile_view .gigya-profile-form{
    width: 80% !important;
    margin-top: 17px;
    padding: 20px;
    margin-left: 16px;
    border: 1px solid #DBE0E2;
    margin-bottom: 17px;
    padding-left: 37px;
    padding-right: 35px;
  } 
}

#login-container .gigya-screen.landscape {
  padding-top: 40px !important;
}

.GigyaRegistrationPageTemplate{
  .verify_subheader2{
    clear: both;
  }

  .verify_header{
    margin-bottom: 0px;
    font-family: campton-medium;
  }

  .createprofile_nomargin{
    width: 93%;
  }
}
.GigyaRegistrationPageTemplate .login_container {
  display: flex;
  background-color: #ffffff;
  padding: 50px 40px;
  width: 50%;
  flex-direction: column;
  margin-left: 0px;
  font-family: campton-book;
  box-shadow:none !important;
}
.GigyaRegistrationPageTemplate .login_container{
  margin-top:1%;
}
body:not(.loggedin):has(app-homepage-uk-banner) .LandingPage2Template {
  #login-container .gigya-screen.landscape {
    padding-right: 14px;
    padding-top: 66px !important;
  }
}
.GigyaLoginPageTemplate .login_logintext{
  padding-left: 0px !important;
}
.LandingPage2Template .login_logintext{
  padding-left: 0px !important;
}
.GigyaLoginPageTemplate input#username:focus{
border:3px solid #ccc;
 
}
.LandingPage2Template input#username:focus{
  border:1px solid #ccc;
}
.LandingPage2Template input#gigya-password-25296147414305348:focus{
  border:1px solid #ccc;
}
.LandingPage2Template cx-page-slot.homepageBanner.has-components{
  position:relative !important;
}

.seqirusb2b-flu360 .GigyaLoginPageTemplate .BodyContent .is-initialized img {
  max-height: 664px;
}
.LandingPage2Template app-custom-gigya-raas{
  .customemailerror, .custompwderror {
    color: #BE1114;
    font-weight: 600;
    margin-top: -5px;
    position: absolute;
    font-size: 13px !important;
    font-family: 'Campton-Book';
    line-height: 15px;
}
}