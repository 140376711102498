@charset "UTF-8";
.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

cx-org-list .btn, cx-org-list .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list a, cx-org-list button.button,
cx-org-list a.button, cx-org-list a.link,
cx-org-list button.link {
  display: inline-block;
  font-weight: "normal";
  color: #14293a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 14px 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  cx-org-list .btn, cx-org-list .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list a, cx-org-list button.button,
  cx-org-list a.button, cx-org-list a.link,
  cx-org-list button.link {
    transition: none;
  }
}
cx-org-list .btn:hover, cx-org-list .cx-organization-user-register-button a:hover, .cx-organization-user-register-button cx-org-list a:hover, cx-org-list button.button:hover,
cx-org-list a.button:hover, cx-org-list a.link:hover,
cx-org-list button.link:hover {
  color: #14293a;
  text-decoration: none;
}
cx-org-list .btn:focus, cx-org-list .cx-organization-user-register-button a:focus, .cx-organization-user-register-button cx-org-list a:focus, cx-org-list button.button:focus,
cx-org-list a.button:focus, cx-org-list a.link:focus,
cx-org-list button.link:focus, cx-org-list .btn.focus, cx-org-list .cx-organization-user-register-button a.focus, .cx-organization-user-register-button cx-org-list a.focus, cx-org-list button.focus.button,
cx-org-list a.focus.button, cx-org-list a.focus.link,
cx-org-list button.focus.link {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
cx-org-list .btn.disabled, cx-org-list .cx-organization-user-register-button a.disabled, .cx-organization-user-register-button cx-org-list a.disabled, cx-org-list button.disabled.button,
cx-org-list a.disabled.button, cx-org-list a.disabled.link,
cx-org-list button.disabled.link, cx-org-list .btn:disabled, cx-org-list .cx-organization-user-register-button a:disabled, .cx-organization-user-register-button cx-org-list a:disabled, cx-org-list button.button:disabled,
cx-org-list a.button:disabled, cx-org-list a.link:disabled,
cx-org-list button.link:disabled {
  opacity: 0.65;
}
cx-org-list .btn:not(:disabled):not(.disabled), cx-org-list .cx-organization-user-register-button a:not(:disabled):not(.disabled), .cx-organization-user-register-button cx-org-list a:not(:disabled):not(.disabled), cx-org-list button.button:not(:disabled):not(.disabled),
cx-org-list a.button:not(:disabled):not(.disabled), cx-org-list a.link:not(:disabled):not(.disabled),
cx-org-list button.link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
cx-org-list a.btn.disabled, cx-org-list .cx-organization-user-register-button a.disabled, .cx-organization-user-register-button cx-org-list a.disabled,
cx-org-list a.disabled.button, cx-org-list a.disabled.link,
cx-org-list fieldset:disabled a.btn,
cx-org-list fieldset:disabled .cx-organization-user-register-button a,
.cx-organization-user-register-button cx-org-list fieldset:disabled a,
cx-org-list fieldset:disabled a.button,
cx-org-list fieldset:disabled a.link {
  pointer-events: none;
}
cx-org-list .btn-primary, cx-org-list a.button.primary,
cx-org-list a.button.primary.disabled,
cx-org-list a.button.primary:not(:disabled):not(.disabled),
cx-org-list button.button.primary,
cx-org-list button.button.primary.disabled,
cx-org-list button.button.primary:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-org-list .btn-primary:hover, cx-org-list a.button.primary:hover,
cx-org-list a.button.primary:hover:not(:disabled):not(.disabled),
cx-org-list button.button.primary:hover,
cx-org-list button.button.primary:hover:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
}
cx-org-list .btn-primary:focus, cx-org-list a.button.primary:focus,
cx-org-list a.button.primary:focus:not(:disabled):not(.disabled),
cx-org-list button.button.primary:focus,
cx-org-list button.button.primary:focus:active:not(:disabled):not(.disabled), cx-org-list .btn-primary.focus, cx-org-list a.focus.button.primary,
cx-org-list a.focus.button.primary:not(:disabled):not(.disabled),
cx-org-list button.focus.button.primary,
cx-org-list button.focus.button.primary:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}
cx-org-list .btn-primary.disabled, cx-org-list a.disabled.button.primary,
cx-org-list a.disabled.button.primary:not(:disabled):not(.disabled),
cx-org-list button.disabled.button.primary,
cx-org-list button.disabled.button.primary:active:not(:disabled):not(.disabled), cx-org-list .btn-primary:disabled, cx-org-list a.button.primary:disabled,
cx-org-list a.button.primary:disabled:not(:disabled):not(.disabled),
cx-org-list button.button.primary:disabled,
cx-org-list button.button.primary:disabled:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-org-list .btn-primary:not(:disabled):not(.disabled):active, cx-org-list a.button.primary:not(:disabled):not(.disabled):active,
cx-org-list button.button.primary:not(:disabled):not(.disabled):active, cx-org-list .btn-primary:not(:disabled):not(.disabled).active, cx-org-list a.button.primary:not(:disabled):not(.disabled).active,
cx-org-list button.button.primary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-primary.dropdown-toggle, .show > cx-org-list a.dropdown-toggle.button.primary,
.show > cx-org-list button.dropdown-toggle.button.primary {
  color: #fff;
  background-color: #185f94;
  border-color: #165889;
}
cx-org-list .btn-primary:not(:disabled):not(.disabled):active:focus, cx-org-list a.button.primary:not(:disabled):not(.disabled):active:focus,
cx-org-list button.button.primary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-primary:not(:disabled):not(.disabled).active:focus, cx-org-list a.button.primary:not(:disabled):not(.disabled).active:focus,
cx-org-list button.button.primary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-primary.dropdown-toggle:focus, .show > cx-org-list a.dropdown-toggle.button.primary:focus,
.show > cx-org-list button.dropdown-toggle.button.primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}
cx-org-list .btn-secondary, cx-org-list .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list a, cx-org-list button.button.secondary,
cx-org-list a.button.secondary {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-secondary:hover, cx-org-list .cx-organization-user-register-button a:hover, .cx-organization-user-register-button cx-org-list a:hover, cx-org-list button.button.secondary:hover,
cx-org-list a.button.secondary:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
cx-org-list .btn-secondary:focus, cx-org-list .cx-organization-user-register-button a:focus, .cx-organization-user-register-button cx-org-list a:focus, cx-org-list button.button.secondary:focus,
cx-org-list a.button.secondary:focus, cx-org-list .btn-secondary.focus, cx-org-list .cx-organization-user-register-button a.focus, .cx-organization-user-register-button cx-org-list a.focus, cx-org-list button.focus.button.secondary,
cx-org-list a.focus.button.secondary {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-org-list .btn-secondary.disabled, cx-org-list .cx-organization-user-register-button a.disabled, .cx-organization-user-register-button cx-org-list a.disabled, cx-org-list button.disabled.button.secondary,
cx-org-list a.disabled.button.secondary, cx-org-list .btn-secondary:disabled, cx-org-list .cx-organization-user-register-button a:disabled, .cx-organization-user-register-button cx-org-list a:disabled, cx-org-list button.button.secondary:disabled,
cx-org-list a.button.secondary:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-secondary:not(:disabled):not(.disabled):active, cx-org-list .cx-organization-user-register-button a:not(:disabled):not(.disabled):active, .cx-organization-user-register-button cx-org-list a:not(:disabled):not(.disabled):active, cx-org-list button.button.secondary:not(:disabled):not(.disabled):active,
cx-org-list a.button.secondary:not(:disabled):not(.disabled):active, cx-org-list .btn-secondary:not(:disabled):not(.disabled).active, cx-org-list .cx-organization-user-register-button a:not(:disabled):not(.disabled).active, .cx-organization-user-register-button cx-org-list a:not(:disabled):not(.disabled).active, cx-org-list button.button.secondary:not(:disabled):not(.disabled).active,
cx-org-list a.button.secondary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-secondary.dropdown-toggle, .show > cx-org-list .cx-organization-user-register-button a.dropdown-toggle, .cx-organization-user-register-button .show > cx-org-list a.dropdown-toggle, .show > cx-org-list button.dropdown-toggle.button.secondary,
.show > cx-org-list a.dropdown-toggle.button.secondary {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
cx-org-list .btn-secondary:not(:disabled):not(.disabled):active:focus, cx-org-list .cx-organization-user-register-button a:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button cx-org-list a:not(:disabled):not(.disabled):active:focus, cx-org-list button.button.secondary:not(:disabled):not(.disabled):active:focus,
cx-org-list a.button.secondary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-secondary:not(:disabled):not(.disabled).active:focus, cx-org-list .cx-organization-user-register-button a:not(:disabled):not(.disabled).active:focus, .cx-organization-user-register-button cx-org-list a:not(:disabled):not(.disabled).active:focus, cx-org-list button.button.secondary:not(:disabled):not(.disabled).active:focus,
cx-org-list a.button.secondary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-secondary.dropdown-toggle:focus, .show > cx-org-list .cx-organization-user-register-button a.dropdown-toggle:focus, .cx-organization-user-register-button .show > cx-org-list a.dropdown-toggle:focus, .show > cx-org-list button.dropdown-toggle.button.secondary:focus,
.show > cx-org-list a.dropdown-toggle.button.secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-org-list .btn-success {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-org-list .btn-success:hover {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
}
cx-org-list .btn-success:focus, cx-org-list .btn-success.focus {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}
cx-org-list .btn-success.disabled, cx-org-list .btn-success:disabled {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-org-list .btn-success:not(:disabled):not(.disabled):active, cx-org-list .btn-success:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #275e15;
  border-color: #225313;
}
cx-org-list .btn-success:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-success:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}
cx-org-list .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
cx-org-list .btn-info:focus, cx-org-list .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
cx-org-list .btn-info.disabled, cx-org-list .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-info:not(:disabled):not(.disabled):active, cx-org-list .btn-info:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
cx-org-list .btn-info:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-info:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
cx-org-list .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
cx-org-list .btn-warning:focus, cx-org-list .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
cx-org-list .btn-warning.disabled, cx-org-list .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-warning:not(:disabled):not(.disabled):active, cx-org-list .btn-warning:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
cx-org-list .btn-warning:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-warning:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
cx-org-list .btn-danger {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-org-list .btn-danger:hover {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
}
cx-org-list .btn-danger:focus, cx-org-list .btn-danger.focus {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}
cx-org-list .btn-danger.disabled, cx-org-list .btn-danger:disabled {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-org-list .btn-danger:not(:disabled):not(.disabled):active, cx-org-list .btn-danger:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a80002;
  border-color: #9b0001;
}
cx-org-list .btn-danger:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-danger:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}
cx-org-list .btn-light {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-org-list .btn-light:hover {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
}
cx-org-list .btn-light:focus, cx-org-list .btn-light.focus {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}
cx-org-list .btn-light.disabled, cx-org-list .btn-light:disabled {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-org-list .btn-light:not(:disabled):not(.disabled):active, cx-org-list .btn-light:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d8d8d8;
  border-color: #d1d1d1;
}
cx-org-list .btn-light:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-light:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}
cx-org-list .btn-dark {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-dark:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
cx-org-list .btn-dark:focus, cx-org-list .btn-dark.focus {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-org-list .btn-dark.disabled, cx-org-list .btn-dark:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-dark:not(:disabled):not(.disabled):active, cx-org-list .btn-dark:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
cx-org-list .btn-dark:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-dark:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
cx-org-list .btn-background {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-org-list .btn-background:hover {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}
cx-org-list .btn-background:focus, cx-org-list .btn-background.focus {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
cx-org-list .btn-background.disabled, cx-org-list .btn-background:disabled {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-org-list .btn-background:not(:disabled):not(.disabled):active, cx-org-list .btn-background:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-background.dropdown-toggle {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}
cx-org-list .btn-background:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-background:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
cx-org-list .btn-text {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-org-list .btn-text:hover {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
}
cx-org-list .btn-text:focus, cx-org-list .btn-text.focus {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}
cx-org-list .btn-text.disabled, cx-org-list .btn-text:disabled {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-org-list .btn-text:not(:disabled):not(.disabled):active, cx-org-list .btn-text:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-text.dropdown-toggle {
  color: #fff;
  background-color: #070e14;
  border-color: #04070b;
}
cx-org-list .btn-text:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-text:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}
cx-org-list .btn-inverse {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-org-list .btn-inverse:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
cx-org-list .btn-inverse:focus, cx-org-list .btn-inverse.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
cx-org-list .btn-inverse.disabled, cx-org-list .btn-inverse:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-org-list .btn-inverse:not(:disabled):not(.disabled):active, cx-org-list .btn-inverse:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-inverse.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
cx-org-list .btn-inverse:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-inverse:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
cx-org-list .btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-org-list .btn-transparent:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
cx-org-list .btn-transparent:focus, cx-org-list .btn-transparent.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-org-list .btn-transparent.disabled, cx-org-list .btn-transparent:disabled {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-org-list .btn-transparent:not(:disabled):not(.disabled):active, cx-org-list .btn-transparent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-transparent.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
cx-org-list .btn-transparent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-transparent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-org-list .btn-primary-accent {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-org-list .btn-primary-accent:hover {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
}
cx-org-list .btn-primary-accent:focus, cx-org-list .btn-primary-accent.focus {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}
cx-org-list .btn-primary-accent.disabled, cx-org-list .btn-primary-accent:disabled {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-org-list .btn-primary-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-primary-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #03416e;
  border-color: #033a61;
}
cx-org-list .btn-primary-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-primary-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}
cx-org-list .btn-success-accent {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-org-list .btn-success-accent:hover {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
}
cx-org-list .btn-success-accent:focus, cx-org-list .btn-success-accent.focus {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}
cx-org-list .btn-success-accent.disabled, cx-org-list .btn-success-accent:disabled {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-org-list .btn-success-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-success-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #d7f4b8;
  border-color: #d1f3ac;
}
cx-org-list .btn-success-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-success-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}
cx-org-list .btn-danger-accent {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-org-list .btn-danger-accent:hover {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
}
cx-org-list .btn-danger-accent:focus, cx-org-list .btn-danger-accent.focus {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}
cx-org-list .btn-danger-accent.disabled, cx-org-list .btn-danger-accent:disabled {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-org-list .btn-danger-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-danger-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffbebe;
  border-color: #ffb1b1;
}
cx-org-list .btn-danger-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-danger-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}
cx-org-list .btn-warning-accent {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-org-list .btn-warning-accent:hover {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
}
cx-org-list .btn-warning-accent:focus, cx-org-list .btn-warning-accent.focus {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}
cx-org-list .btn-warning-accent.disabled, cx-org-list .btn-warning-accent:disabled {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-org-list .btn-warning-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-warning-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffe5ac;
  border-color: #ffe19f;
}
cx-org-list .btn-warning-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-warning-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}
cx-org-list .btn-info-accent {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-org-list .btn-info-accent:hover {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
}
cx-org-list .btn-info-accent:focus, cx-org-list .btn-info-accent.focus {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}
cx-org-list .btn-info-accent.disabled, cx-org-list .btn-info-accent:disabled {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-org-list .btn-info-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-info-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #add7fd;
  border-color: #a0d1fc;
}
cx-org-list .btn-info-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-info-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}
cx-org-list .btn-medium {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-org-list .btn-medium:hover {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
}
cx-org-list .btn-medium:focus, cx-org-list .btn-medium.focus {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}
cx-org-list .btn-medium.disabled, cx-org-list .btn-medium:disabled {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-org-list .btn-medium:not(:disabled):not(.disabled):active, cx-org-list .btn-medium:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-medium.dropdown-toggle {
  color: #212529;
  background-color: #b7bcc4;
  border-color: #b0b5be;
}
cx-org-list .btn-medium:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-medium:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}
cx-org-list .btn-background-dark {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-org-list .btn-background-dark:hover {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
}
cx-org-list .btn-background-dark:focus, cx-org-list .btn-background-dark.focus {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}
cx-org-list .btn-background-dark.disabled, cx-org-list .btn-background-dark:disabled {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-org-list .btn-background-dark:not(:disabled):not(.disabled):active, cx-org-list .btn-background-dark:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #0e1118;
  border-color: #090b10;
}
cx-org-list .btn-background-dark:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-background-dark:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}
cx-org-list .btn-visual-focus {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-org-list .btn-visual-focus:hover {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
}
cx-org-list .btn-visual-focus:focus, cx-org-list .btn-visual-focus.focus {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}
cx-org-list .btn-visual-focus.disabled, cx-org-list .btn-visual-focus:disabled {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-org-list .btn-visual-focus:not(:disabled):not(.disabled):active, cx-org-list .btn-visual-focus:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-visual-focus.dropdown-toggle {
  color: #fff;
  background-color: #3d7df4;
  border-color: #3174f4;
}
cx-org-list .btn-visual-focus:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-visual-focus:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}
cx-org-list .btn-background-focus {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-org-list .btn-background-focus:hover {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
}
cx-org-list .btn-background-focus:focus, cx-org-list .btn-background-focus.focus {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}
cx-org-list .btn-background-focus.disabled, cx-org-list .btn-background-focus:disabled {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-org-list .btn-background-focus:not(:disabled):not(.disabled):active, cx-org-list .btn-background-focus:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-background-focus.dropdown-toggle {
  color: #fff;
  background-color: rgba(32, 154, 241, 0.1);
  border-color: rgba(20, 149, 240, 0.1);
}
cx-org-list .btn-background-focus:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-background-focus:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}
cx-org-list .btn-border-focus {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-org-list .btn-border-focus:hover {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
}
cx-org-list .btn-border-focus:focus, cx-org-list .btn-border-focus.focus {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}
cx-org-list .btn-border-focus.disabled, cx-org-list .btn-border-focus:disabled {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-org-list .btn-border-focus:not(:disabled):not(.disabled):active, cx-org-list .btn-border-focus:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(125, 181, 222, 0.6);
  border-color: rgba(115, 175, 219, 0.6);
}
cx-org-list .btn-border-focus:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-border-focus:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}
cx-org-list .btn-outline-primary {
  color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-org-list .btn-outline-primary:hover {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-org-list .btn-outline-primary:focus, cx-org-list .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}
cx-org-list .btn-outline-primary.disabled, cx-org-list .btn-outline-primary:disabled {
  color: #1f7bc0;
  background-color: transparent;
}
cx-org-list .btn-outline-primary:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-primary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
cx-org-list .btn-outline-primary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}
cx-org-list .btn-outline-secondary {
  color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-outline-secondary:focus, cx-org-list .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-org-list .btn-outline-secondary.disabled, cx-org-list .btn-outline-secondary:disabled {
  color: #6c7079;
  background-color: transparent;
}
cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-org-list .btn-outline-success {
  color: #38871f;
  border-color: #38871f;
}
cx-org-list .btn-outline-success:hover {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-org-list .btn-outline-success:focus, cx-org-list .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}
cx-org-list .btn-outline-success.disabled, cx-org-list .btn-outline-success:disabled {
  color: #38871f;
  background-color: transparent;
}
cx-org-list .btn-outline-success:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-success:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
cx-org-list .btn-outline-success:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}
cx-org-list .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-outline-info:focus, cx-org-list .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
cx-org-list .btn-outline-info.disabled, cx-org-list .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
cx-org-list .btn-outline-info:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-info:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
cx-org-list .btn-outline-info:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
cx-org-list .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-outline-warning:focus, cx-org-list .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
cx-org-list .btn-outline-warning.disabled, cx-org-list .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
cx-org-list .btn-outline-warning:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-warning:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
cx-org-list .btn-outline-warning:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
cx-org-list .btn-outline-danger {
  color: #db0002;
  border-color: #db0002;
}
cx-org-list .btn-outline-danger:hover {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-org-list .btn-outline-danger:focus, cx-org-list .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}
cx-org-list .btn-outline-danger.disabled, cx-org-list .btn-outline-danger:disabled {
  color: #db0002;
  background-color: transparent;
}
cx-org-list .btn-outline-danger:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-danger:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
cx-org-list .btn-outline-danger:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}
cx-org-list .btn-outline-light {
  color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-org-list .btn-outline-light:hover {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-org-list .btn-outline-light:focus, cx-org-list .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
cx-org-list .btn-outline-light.disabled, cx-org-list .btn-outline-light:disabled {
  color: #f1f1f1;
  background-color: transparent;
}
cx-org-list .btn-outline-light:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-light:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
cx-org-list .btn-outline-light:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
cx-org-list .btn-outline-dark {
  color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-outline-dark:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-outline-dark:focus, cx-org-list .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-org-list .btn-outline-dark.disabled, cx-org-list .btn-outline-dark:disabled {
  color: #6c7079;
  background-color: transparent;
}
cx-org-list .btn-outline-dark:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-dark:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
cx-org-list .btn-outline-dark:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
cx-org-list .btn-outline-background {
  color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-org-list .btn-outline-background:hover {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-org-list .btn-outline-background:focus, cx-org-list .btn-outline-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
cx-org-list .btn-outline-background.disabled, cx-org-list .btn-outline-background:disabled {
  color: #f4f4f4;
  background-color: transparent;
}
cx-org-list .btn-outline-background:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-background:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-background.dropdown-toggle {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
cx-org-list .btn-outline-background:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-background:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
cx-org-list .btn-outline-text {
  color: #14293a;
  border-color: #14293a;
}
cx-org-list .btn-outline-text:hover {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-org-list .btn-outline-text:focus, cx-org-list .btn-outline-text.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}
cx-org-list .btn-outline-text.disabled, cx-org-list .btn-outline-text:disabled {
  color: #14293a;
  background-color: transparent;
}
cx-org-list .btn-outline-text:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-text:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-text.dropdown-toggle {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
cx-org-list .btn-outline-text:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-text:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}
cx-org-list .btn-outline-inverse {
  color: #ffffff;
  border-color: #ffffff;
}
cx-org-list .btn-outline-inverse:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-org-list .btn-outline-inverse:focus, cx-org-list .btn-outline-inverse.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-org-list .btn-outline-inverse.disabled, cx-org-list .btn-outline-inverse:disabled {
  color: #ffffff;
  background-color: transparent;
}
cx-org-list .btn-outline-inverse:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-inverse:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-inverse.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
cx-org-list .btn-outline-inverse:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-inverse:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
cx-org-list .btn-outline-transparent {
  color: transparent;
  border-color: transparent;
}
cx-org-list .btn-outline-transparent:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-org-list .btn-outline-transparent:focus, cx-org-list .btn-outline-transparent.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
cx-org-list .btn-outline-transparent.disabled, cx-org-list .btn-outline-transparent:disabled {
  color: transparent;
  background-color: transparent;
}
cx-org-list .btn-outline-transparent:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-transparent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-transparent.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
cx-org-list .btn-outline-transparent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-transparent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
cx-org-list .btn-outline-primary-accent {
  color: #055f9f;
  border-color: #055f9f;
}
cx-org-list .btn-outline-primary-accent:hover {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-org-list .btn-outline-primary-accent:focus, cx-org-list .btn-outline-primary-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}
cx-org-list .btn-outline-primary-accent.disabled, cx-org-list .btn-outline-primary-accent:disabled {
  color: #055f9f;
  background-color: transparent;
}
cx-org-list .btn-outline-primary-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-primary-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
cx-org-list .btn-outline-primary-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-primary-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}
cx-org-list .btn-outline-success-accent {
  color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-org-list .btn-outline-success-accent:hover {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-org-list .btn-outline-success-accent:focus, cx-org-list .btn-outline-success-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}
cx-org-list .btn-outline-success-accent.disabled, cx-org-list .btn-outline-success-accent:disabled {
  color: #f0fbe4;
  background-color: transparent;
}
cx-org-list .btn-outline-success-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-success-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
cx-org-list .btn-outline-success-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-success-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}
cx-org-list .btn-outline-danger-accent {
  color: #fff1f1;
  border-color: #fff1f1;
}
cx-org-list .btn-outline-danger-accent:hover {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-org-list .btn-outline-danger-accent:focus, cx-org-list .btn-outline-danger-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}
cx-org-list .btn-outline-danger-accent.disabled, cx-org-list .btn-outline-danger-accent:disabled {
  color: #fff1f1;
  background-color: transparent;
}
cx-org-list .btn-outline-danger-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-danger-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
cx-org-list .btn-outline-danger-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-danger-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}
cx-org-list .btn-outline-warning-accent {
  color: #fff5df;
  border-color: #fff5df;
}
cx-org-list .btn-outline-warning-accent:hover {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-org-list .btn-outline-warning-accent:focus, cx-org-list .btn-outline-warning-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}
cx-org-list .btn-outline-warning-accent.disabled, cx-org-list .btn-outline-warning-accent:disabled {
  color: #fff5df;
  background-color: transparent;
}
cx-org-list .btn-outline-warning-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-warning-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
cx-org-list .btn-outline-warning-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-warning-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}
cx-org-list .btn-outline-info-accent {
  color: #deeffe;
  border-color: #deeffe;
}
cx-org-list .btn-outline-info-accent:hover {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-org-list .btn-outline-info-accent:focus, cx-org-list .btn-outline-info-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}
cx-org-list .btn-outline-info-accent.disabled, cx-org-list .btn-outline-info-accent:disabled {
  color: #deeffe;
  background-color: transparent;
}
cx-org-list .btn-outline-info-accent:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-info-accent:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
cx-org-list .btn-outline-info-accent:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-info-accent:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}
cx-org-list .btn-outline-medium {
  color: #d3d6db;
  border-color: #d3d6db;
}
cx-org-list .btn-outline-medium:hover {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-org-list .btn-outline-medium:focus, cx-org-list .btn-outline-medium.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}
cx-org-list .btn-outline-medium.disabled, cx-org-list .btn-outline-medium:disabled {
  color: #d3d6db;
  background-color: transparent;
}
cx-org-list .btn-outline-medium:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-medium:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-medium.dropdown-toggle {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
cx-org-list .btn-outline-medium:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-medium:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}
cx-org-list .btn-outline-background-dark {
  color: #212738;
  border-color: #212738;
}
cx-org-list .btn-outline-background-dark:hover {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-org-list .btn-outline-background-dark:focus, cx-org-list .btn-outline-background-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}
cx-org-list .btn-outline-background-dark.disabled, cx-org-list .btn-outline-background-dark:disabled {
  color: #212738;
  background-color: transparent;
}
cx-org-list .btn-outline-background-dark:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-background-dark:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
cx-org-list .btn-outline-background-dark:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-background-dark:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}
cx-org-list .btn-outline-visual-focus {
  color: #6d9df7;
  border-color: #6d9df7;
}
cx-org-list .btn-outline-visual-focus:hover {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-org-list .btn-outline-visual-focus:focus, cx-org-list .btn-outline-visual-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}
cx-org-list .btn-outline-visual-focus.disabled, cx-org-list .btn-outline-visual-focus:disabled {
  color: #6d9df7;
  background-color: transparent;
}
cx-org-list .btn-outline-visual-focus:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-visual-focus:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-visual-focus.dropdown-toggle {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
cx-org-list .btn-outline-visual-focus:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-visual-focus:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}
cx-org-list .btn-outline-background-focus {
  color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-org-list .btn-outline-background-focus:hover {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-org-list .btn-outline-background-focus:focus, cx-org-list .btn-outline-background-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}
cx-org-list .btn-outline-background-focus.disabled, cx-org-list .btn-outline-background-focus:disabled {
  color: rgba(80, 176, 244, 0.1);
  background-color: transparent;
}
cx-org-list .btn-outline-background-focus:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-background-focus:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-background-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
cx-org-list .btn-outline-background-focus:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-background-focus:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}
cx-org-list .btn-outline-border-focus {
  color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-org-list .btn-outline-border-focus:hover {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-org-list .btn-outline-border-focus:focus, cx-org-list .btn-outline-border-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}
cx-org-list .btn-outline-border-focus.disabled, cx-org-list .btn-outline-border-focus:disabled {
  color: rgba(166, 204, 232, 0.6);
  background-color: transparent;
}
cx-org-list .btn-outline-border-focus:not(:disabled):not(.disabled):active, cx-org-list .btn-outline-border-focus:not(:disabled):not(.disabled).active, .show > cx-org-list .btn-outline-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
cx-org-list .btn-outline-border-focus:not(:disabled):not(.disabled):active:focus, cx-org-list .btn-outline-border-focus:not(:disabled):not(.disabled).active:focus, .show > cx-org-list .btn-outline-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}
cx-org-list .btn-link, cx-org-list a.link,
cx-org-list button.link {
  font-weight: "normal";
  color: #1f7bc0;
  text-decoration: none;
}
cx-org-list .btn-link:hover, cx-org-list a.link:hover,
cx-org-list button.link:hover {
  color: #14517e;
  text-decoration: underline;
}
cx-org-list .btn-link:focus, cx-org-list a.link:focus,
cx-org-list button.link:focus, cx-org-list .btn-link.focus, cx-org-list a.focus.link,
cx-org-list button.focus.link {
  text-decoration: underline;
}
cx-org-list .btn-link:disabled, cx-org-list a.link:disabled,
cx-org-list button.link:disabled, cx-org-list .btn-link.disabled, cx-org-list a.disabled.link,
cx-org-list button.disabled.link {
  color: #6c757d;
  pointer-events: none;
}
cx-org-list .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
cx-org-list .btn-sm, cx-org-list button.button,
cx-org-list a.button, cx-org-list a.link,
cx-org-list button.link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
cx-org-list .btn-block, cx-org-list .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list a {
  display: block;
  width: 100%;
}
cx-org-list .btn-block + .btn-block, cx-org-list .cx-organization-user-register-button a + .btn-block, .cx-organization-user-register-button cx-org-list a + .btn-block, cx-org-list .cx-organization-user-register-button .btn-block + a, .cx-organization-user-register-button cx-org-list .btn-block + a, cx-org-list .cx-organization-user-register-button a + a, .cx-organization-user-register-button cx-org-list a + a {
  margin-top: 0.5rem;
}
cx-org-list input[type=submit].btn-block,
cx-org-list input[type=reset].btn-block,
cx-org-list input[type=button].btn-block {
  width: 100%;
}
cx-org-list a.link,
cx-org-list button.link {
  color: var(--cx-color-primary);
  padding: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
}
cx-org-list a.link:focus,
cx-org-list button.link:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-org-list a.link:hover, cx-org-list a.link:focus,
cx-org-list button.link:hover,
cx-org-list button.link:focus {
  text-decoration: none;
}
cx-org-list a.link.disabled,
cx-org-list button.link.disabled {
  color: var(--cx-color-text);
  cursor: not-allowed;
}
cx-org-list button.button,
cx-org-list a.button {
  padding: var(--cx-spatial-base);
  margin: var(--cx-spatial-base);
  font-size: 1rem;
  line-height: 1.5rem;
}
cx-org-list button.button:focus,
cx-org-list a.button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
cx-org-list button.button:focus,
cx-org-list a.button:focus {
  box-shadow: none;
}
.cxFeat_a11yListOversizedFocus cx-org-list button.button,
.cxFeat_a11yListOversizedFocus cx-org-list a.button {
  color: var(--cx-color-primary);
}

cx-org-list button.button.active,
cx-org-list a.button.active {
  color: var(--cx-color-primary);
}
cx-org-list button.button.icon,
cx-org-list a.button.icon {
  padding: 0;
}
cx-org-list a.link:hover,
cx-org-list button.link:hover {
  color: var(--cx-color-primary);
}
cx-org-list a.button.primary,
cx-org-list a.button.primary.disabled,
cx-org-list a.button.primary:not(:disabled):not(.disabled),
cx-org-list button.button.primary,
cx-org-list button.button.primary.disabled,
cx-org-list button.button.primary:active:not(:disabled):not(.disabled) {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
}
cx-org-list a.button.primary:hover,
cx-org-list a.button.primary.disabled:hover,
cx-org-list a.button.primary:not(:disabled):not(.disabled):hover,
cx-org-list button.button.primary:hover,
cx-org-list button.button.primary.disabled:hover,
cx-org-list button.button.primary:active:not(:disabled):not(.disabled):hover {
  background-color: var(--cx-color-primary-accent);
  border-color: var(--cx-color-primary-accent);
}
.cx-theme-high-contrast-dark cx-org-list a.button.primary,
.cx-theme-high-contrast-dark cx-org-list a.button.primary.disabled,
.cx-theme-high-contrast-dark cx-org-list a.button.primary:not(:disabled):not(.disabled),
.cx-theme-high-contrast-dark cx-org-list button.button.primary,
.cx-theme-high-contrast-dark cx-org-list button.button.primary.disabled,
.cx-theme-high-contrast-dark cx-org-list button.button.primary:active:not(:disabled):not(.disabled) {
  color: var(--cx-color-medium);
}
cx-org-list .form-buttons {
  display: flex;
  padding: var(--cx-spatial-base);
  place-content: space-between;
}
@media (max-width: 991.98px) {
  cx-org-list .form-buttons {
    flex-direction: column;
  }
}
cx-org-list .form-buttons button,
cx-org-list .form-buttons a {
  flex: 1;
  margin: var(--cx-spatial-base);
}

html[dir=rtl] cx-org-list cx-org-message .close {
  left: 20px;
  right: auto;
}

cx-org-list cx-org-message {
  position: absolute;
  width: 100%;
}
cx-org-list cx-org-message > * {
  transition: all 0.4s;
  position: absolute;
  width: 100%;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
cx-org-list cx-org-message > *:first-child:not(.terminated) {
  z-index: 1;
  opacity: 1;
  pointer-events: initial;
}
cx-org-list cx-org-message > *.terminated + * {
  opacity: 1;
}
cx-org-list cx-org-message > * .inner {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px;
}
cx-org-list cx-org-message .close {
  position: absolute;
  right: 20px;
  margin-top: -5px;
}
cx-org-list cx-org-message p {
  margin: 0;
}
cx-org-list cx-org-message cx-icon {
  align-self: flex-start;
  margin: 5px;
}
cx-org-list cx-org-message cx-org-notification {
  display: flex;
  background-color: #deeecc;
}
cx-org-list cx-org-message cx-org-notification.error {
  background-color: var(--cx-color-danger);
}
cx-org-list cx-org-message cx-org-confirmation {
  flex: 100%;
  flex-direction: column;
  width: 100%;
  display: block;
}
cx-org-list cx-org-message cx-org-confirmation.info {
  background-color: #cbe6fe;
}
cx-org-list cx-org-message cx-org-confirmation .messageTitle {
  font-weight: bold;
}
cx-org-list cx-org-message cx-org-confirmation .message {
  display: flex;
}
cx-org-list cx-org-message cx-org-confirmation .actions {
  flex: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
cx-org-list cx-org-message cx-org-confirmation .actions button {
  margin-inline-start: 20px;
}

cx-org-list.ghost .header h3 {
  color: transparent;
  background-color: var(--cx-color-ghost);
  border-radius: var(--cx-ghost-radius);
  display: inline-block;
}
cx-org-list.ghost ng-select.sort {
  min-width: 200px;
  background-color: var(--cx-color-ghost);
}
cx-org-list.ghost ng-select.sort .ng-select-container {
  visibility: hidden;
}
cx-org-list.ghost cx-table.vertical-stacked table tr:hover:not(.is-current),
cx-org-list.ghost cx-table.vertical table tr:hover:not(.is-current) {
  background: none;
}
cx-org-list.ghost cx-table.vertical-stacked table td,
cx-org-list.ghost cx-table.vertical table td {
  position: relative;
}
cx-org-list.ghost cx-table.vertical-stacked table td .text,
cx-org-list.ghost cx-table.vertical table td .text {
  display: flex;
}
@media (min-width: 768px) {
  cx-org-list.ghost cx-table.vertical-stacked table td .text,
  cx-org-list.ghost cx-table.vertical table td .text {
    min-height: 74px;
  }
}
cx-org-list.ghost cx-table.vertical-stacked table td:before,
cx-org-list.ghost cx-table.vertical table td:before {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  margin-inline-start: 20px;
  height: 20px;
  background: var(--cx-color-ghost);
  border-radius: var(--cx-ghost-radius);
}
@media (min-width: 768px) {
  cx-org-list.ghost cx-table.vertical-stacked table td:before,
  cx-org-list.ghost cx-table.vertical table td:before {
    top: 25px;
    left: 0;
  }
}
cx-org-list.ghost cx-table.vertical-stacked table td:last-child:before,
cx-org-list.ghost cx-table.vertical table td:last-child:before {
  width: calc(100% - 40px);
  margin-inline-end: 20px;
}
@media (max-width: 991.98px) {
  cx-org-list.ghost cx-table.vertical-stacked table tr td:before,
  cx-org-list.ghost cx-table.vertical table tr td:before {
    margin-top: -10px;
    width: calc(100% - 60px);
  }
}

cx-org-list .card .header .title-bar .title cx-popover, cx-org-list .title cx-popover {
  text-transform: initial;
}
cx-org-list .card .header .title-bar .title h3 button, cx-org-list .title h3 button {
  border: none;
  background-color: inherit;
}
cx-org-list .card .header .title-bar .title h3 button cx-icon, cx-org-list .title h3 button cx-icon {
  pointer-events: none;
  color: var(--cx-color, var(--cx-color-info));
}
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .card .header .title-bar .title h2, cx-org-list .card .header .cxFeat_a11yOrganizationListHeadingOrder .title-bar .title h2, .cxFeat_a11yOrganizationListHeadingOrder cx-org-list .title h2,
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .card .header .title-bar .title h3,
cx-org-list .card .header .cxFeat_a11yOrganizationListHeadingOrder .title-bar .title h3,
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .title h3 {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  text-transform: uppercase;
}
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .card .header .title-bar .title h2 button, cx-org-list .card .header .cxFeat_a11yOrganizationListHeadingOrder .title-bar .title h2 button, .cxFeat_a11yOrganizationListHeadingOrder cx-org-list .title h2 button,
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .card .header .title-bar .title h3 button,
cx-org-list .card .header .cxFeat_a11yOrganizationListHeadingOrder .title-bar .title h3 button,
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .title h3 button {
  border: none;
  background-color: inherit;
}
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .card .header .title-bar .title h2 button cx-icon, cx-org-list .card .header .cxFeat_a11yOrganizationListHeadingOrder .title-bar .title h2 button cx-icon, .cxFeat_a11yOrganizationListHeadingOrder cx-org-list .title h2 button cx-icon,
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .card .header .title-bar .title h3 button cx-icon,
cx-org-list .card .header .cxFeat_a11yOrganizationListHeadingOrder .title-bar .title h3 button cx-icon,
.cxFeat_a11yOrganizationListHeadingOrder cx-org-list .title h3 button cx-icon {
  pointer-events: none;
  color: var(--cx-color, var(--cx-color-info));
}

cx-org-list {
  width: 100%;
  position: relative;
}
cx-org-list .content-wrapper,
cx-org-list form {
  display: contents;
}
cx-org-list cx-split-view:not([active-view="1"]) .list table th.amount, cx-org-list cx-split-view:not([active-view="1"]) .list table th.dateRange, cx-org-list cx-split-view:not([active-view="1"]) .list table th.currency, cx-org-list cx-split-view:not([active-view="1"]) .list table th.uid, cx-org-list cx-split-view:not([active-view="1"]) .list table th.roles, cx-org-list cx-split-view:not([active-view="1"]) .list table th.limit,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.amount,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.dateRange,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.currency,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.uid,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.roles,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.limit {
  opacity: 0;
  width: 0.1%;
}
cx-org-list cx-split-view:not([active-view="1"]) .list table th.active,
cx-org-list cx-split-view:not([active-view="1"]) .list table td.active {
  width: 100px;
}
cx-org-list .is-empty {
  margin: 0;
  padding: 20px;
}
@media (min-width: 768px) {
  cx-org-list {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }
}
@media (max-width: 767.98px) {
  cx-org-list cx-split-view {
    width: 100vw;
  }
}
cx-org-list cx-split-view cx-view {
  height: auto;
}
cx-org-list cx-split-view cx-view:not([position="0"]) {
  align-self: flex-start;
}
@media (min-width: 992px) {
  cx-org-list cx-split-view {
    padding-top: 40px;
  }
  cx-org-list cx-split-view cx-view:not([position="0"]) {
    display: flex;
  }
  cx-org-list cx-split-view:not([active-view="1"]) cx-view[position="0"] {
    padding-inline-end: var(--cx-split-gutter);
    border-inline-end: solid 0 var(--cx-color-light);
    border-inline-end-width: calc(min(var(--cx-active-view) - 1, 1) * 1px);
  }
}
.cxFeat_a11yOrganizationLinkableCells cx-org-list cx-view[position="0"] tr {
  cursor: pointer;
}

cx-org-list cx-view[position="0"] > cx-table.vertical table {
  border-top: solid 1px var(--cx-color-light);
  border-bottom: solid 1px var(--cx-color-light);
}
.cx-theme-high-contrast-dark cx-org-list cx-view .header, .cx-theme-high-contrast-light cx-org-list cx-view .header {
  background-color: var(--cx-color-background);
}
.cx-theme-high-contrast-dark cx-org-list cx-view .main .details, .cx-theme-high-contrast-light cx-org-list cx-view .main .details {
  background-color: var(--cx-color-background);
}
cx-org-list .vertical-stacked table {
  table-layout: auto;
}
cx-org-list .vertical-stacked tbody {
  border-top: solid 1px var(--cx-color-light);
}
cx-org-list .vertical-stacked tbody:last-child {
  border-bottom: solid 1px var(--cx-color-light);
}
cx-org-list .vertical-stacked tbody tr:first-child > * {
  padding-top: 20px;
}
cx-org-list .vertical-stacked tbody tr:last-child > * {
  padding-bottom: 20px;
}
cx-org-list .vertical-stacked tbody th cx-table-data-cell,
cx-org-list .vertical-stacked tbody td cx-table-data-cell {
  min-height: auto;
}
cx-org-list .vertical-stacked tbody th cx-table-data-cell a,
cx-org-list .vertical-stacked tbody td cx-table-data-cell a {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 991.98px) {
  cx-org-list .vertical-stacked tbody th:not(:first-child),
  cx-org-list .vertical-stacked tbody td:not(:first-child) {
    width: 100%;
  }
  cx-org-list .vertical-stacked tbody td {
    padding-inline-start: 40px;
  }
}
cx-org-list .footer {
  display: flex;
  justify-content: flex-end;
  padding: 40px;
}
cx-org-list section + .footer {
  padding-top: 0;
}
@media (max-width: 991.98px) {
  cx-org-list cx-pagination {
    margin: 0 var(--cx-spatial-md);
  }
}
cx-org-list cx-pagination a {
  border-radius: 0;
}
cx-org-list cx-pagination a:not(.current) {
  background-color: var(--cx-color-inverse);
}
.cx-theme-high-contrast-dark cx-org-list cx-pagination a:not(.current), .cx-theme-high-contrast-light cx-org-list cx-pagination a:not(.current) {
  background-color: var(--cx-color-background);
}
cx-org-list cx-pagination a.disabled {
  color: var(--cx-color-light);
}
cx-org-list cx-org-toggle-link-cell {
  padding-inline-start: calc((var(--cx-depth-level) + 3) * 20px);
}
cx-org-list cx-org-toggle-link-cell button.tree-item-toggle {
  margin-inline-start: -40px;
  min-width: 40px;
}
@media (max-width: 991.98px) {
  cx-org-list cx-org-toggle-link-cell {
    padding-inline-start: calc(3rem + var(--cx-depth-level) * 1.5rem);
  }
}

.cx-theme-high-contrast-dark cx-org-list cx-table.vertical-stacked tbody:not(:last-child), .cx-theme-high-contrast-light cx-org-list cx-table.vertical-stacked tbody:not(:last-child), .cx-theme-high-contrast-dark cx-org-list cx-table.vertical tr:not(:last-child), .cx-theme-high-contrast-light cx-org-list cx-table.vertical tr:not(:last-child) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.125);
}
.cx-theme-high-contrast-dark cx-org-list cx-table.vertical-stacked tbody:not(:last-child), .cx-theme-high-contrast-dark cx-org-list cx-table.vertical tr:not(:last-child) {
  border-bottom: solid 1px var(--cx-color-text);
}
cx-org-list cx-table.vertical-stacked tbody:hover:not(.is-current), cx-org-list cx-table.vertical tr:hover:not(.is-current) {
  background-color: var(--cx-color-background);
}
cx-org-list cx-table.vertical-stacked tbody.is-current, cx-org-list cx-table.vertical tr.is-current {
  outline: solid 3px var(--cx-color-primary);
  outline-offset: -3px;
  background: var(--cx-color-background-focus);
}
cx-org-list cx-table.vertical-stacked tbody:focus-within, cx-org-list cx-table.vertical tr:focus-within {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
  outline-offset: -2px;
}
.cxFeat_a11yListOversizedFocus cx-org-list cx-table.vertical-stacked tbody cx-org-active-link-cell a:focus, .cxFeat_a11yListOversizedFocus cx-org-list cx-table.vertical tr cx-org-active-link-cell a:focus {
  outline: none;
}

cx-org-list cx-table.vertical-stacked tbody .hide-focus-border, cx-org-list cx-table.vertical tr .hide-focus-border {
  outline: none;
}
cx-org-list cx-org-sub-list:not(.has-nested-view) cx-table.vertical th:last-child,
cx-org-list cx-org-sub-list:not(.has-nested-view) cx-table.vertical td:last-child,
cx-org-list cx-org-sub-list:not(.has-nested-view) table th:last-child,
cx-org-list cx-org-sub-list:not(.has-nested-view) table td:last-child {
  background-image: none;
}
cx-org-list cx-org-sub-list cx-table.vertical table {
  table-layout: auto;
}
cx-org-list cx-org-sub-list cx-table.vertical table thead {
  display: none;
}
.cxFeat_a11yListOversizedFocus cx-org-list cx-org-sub-list cx-table.vertical table tr:focus-within {
  outline: none;
}

cx-org-list cx-org-sub-list cx-table.vertical table tr td.actions {
  width: 1px;
}
cx-org-list cx-org-sub-list cx-table.vertical table tr td.actions:last-child {
  padding-inline-end: 30px;
  padding-inline-start: 5px;
}
cx-org-list cx-org-sub-list cx-table.vertical table tr td a.is-current {
  outline: solid 3px var(--cx-color-primary);
  outline-offset: -3px;
  background: var(--cx-color-background-focus);
}
cx-org-list cx-org-sub-list cx-table.vertical table tr th:last-child,
cx-org-list cx-org-sub-list cx-table.vertical table tr td:last-child {
  width: 1px;
}
cx-org-list cx-org-sub-list cx-table.vertical table tr th:last-child:not(:only-child),
cx-org-list cx-org-sub-list cx-table.vertical table tr td:last-child:not(:only-child) {
  max-width: none;
}
cx-org-list cx-table {
  width: 100%;
}
cx-org-list cx-table.vertical td {
  height: 74px;
}
cx-org-list cx-table.vertical td cx-table-data-cell {
  display: inline;
  align-items: center;
  height: 74px;
  padding-inline-start: 20px;
}
cx-org-list cx-table.vertical tr td:last-child, cx-org-list cx-table.vertical-stacked tbody {
  background-image: var(--list-bg);
  background-position: var(--list-bg-pos);
  background-repeat: no-repeat;
  background-size: 12px;
}
cx-org-list cx-table td:last-child a {
  padding-inline-end: 40px;
}
.cxFeat_a11yListOversizedFocus cx-org-list cx-table td:last-child a {
  padding-inline-end: unset;
  margin-inline-end: 40px;
}

cx-org-list cx-table td:last-child span {
  padding-inline-end: 20px;
}
cx-org-list cx-table.vertical-stacked tbody {
  display: table;
}
cx-org-list cx-table thead {
  pointer-events: none;
}
cx-org-list cx-table thead th {
  height: 60px;
}
cx-org-list cx-table th,
cx-org-list cx-table td {
  padding: 0;
}
cx-org-list cx-table th {
  font-size: 14px;
}
cx-org-list cx-table td {
  font-size: 16px;
  max-width: 1px;
}
cx-org-list cx-table td .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
cx-org-list cx-table td .text.button {
  color: var(--cx-color-primary);
}
cx-org-list cx-table td ul.text {
  margin: 0;
  padding: 0;
}
cx-org-list cx-table td ul.text li {
  display: inline-block;
  white-space: pre-wrap;
}
cx-org-list cx-table td ul.text li:not(:last-child):after {
  content: ", ";
}
.cxFeat_a11yUnitsListKeyboardControls cx-org-list cx-table td cx-org-active-link-cell a,
.cxFeat_a11yUnitsListKeyboardControls cx-org-list cx-table td cx-org-toggle-link-cell a {
  color: var(--cx-color-primary);
  text-decoration: underline;
}

cx-org-list cx-table td a {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 74px;
}
.cxFeat_a11yUnitsListKeyboardControls cx-org-list cx-table td a {
  color: inherit;
  text-decoration: none;
}

.cxFeat_a11yListOversizedFocus cx-org-list cx-table td a {
  min-height: unset;
}

@media (min-width: 992px) {
  cx-org-list cx-table td a .text {
    padding-inline-start: 0;
  }
}
cx-org-list cx-table td a[tabindex="0"] {
  text-decoration: underline;
  color: var(--cx-color-primary);
}
cx-org-list cx-table td a[tabindex="-1"] {
  color: inherit;
  text-decoration: none;
}
.cxFeat_a11yUnitsListKeyboardControls cx-org-list cx-table td a[tabindex="0"] {
  text-decoration: unset;
  color: unset;
}
.cxFeat_a11yUnitsListKeyboardControls cx-org-list cx-table td a[tabindex="-1"] {
  color: unset;
  text-decoration: unset;
}

cx-org-list cx-table td.actions button {
  flex: auto;
}
cx-org-list cx-table .active span {
  color: var(--cx-color-alert, var(--cx-color-danger));
}
cx-org-list cx-table .active span.is-active {
  color: var(--cx-color-success);
}

cx-org-list .list .header {
  padding: 0 6px 38px 6px;
  min-height: 130px;
}
cx-org-list .list .header h3 {
  text-transform: uppercase;
  margin: 0 0 23px 0;
}
@media (max-width: 767.98px) {
  cx-org-list .list .header h3 {
    font-size: 1.1rem;
  }
}
@media (max-width: 991.98px) {
  cx-org-list .list .header {
    padding: 20px;
  }
}
cx-org-list .list .header .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
cx-org-list .list .header .actions label {
  align-items: center;
  display: inline-flex;
}
@media (max-width: 767.98px) {
  cx-org-list .list .header .actions label {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    padding-bottom: 0;
  }
}
cx-org-list .list .header .actions label span {
  margin-inline-end: 0.5rem;
}
cx-org-list .list .header .actions label .sort {
  margin-inline-end: auto;
  min-width: 170px;
}
cx-org-list .list .header .actions button {
  padding: 0 15px;
}
cx-org-list .list .header .actions a,
cx-org-list .list .header .actions button {
  text-transform: uppercase;
}
cx-org-list .list .header .actions ng-select .ng-select-container.ng-has-value {
  max-height: 40px;
}

cx-org-list .card {
  width: 100%;
}
@media (max-width: 767.98px) {
  cx-org-list .card {
    border: none;
  }
}

cx-org-list .card .header {
  display: flex;
  justify-content: space-between;
  background-color: var(--cx-color-inverse);
  border-bottom: solid 1px var(--cx-color-light);
  padding: 40px 30px 20px;
}
.cx-theme-high-contrast-dark cx-org-list .card .header, cx-org-list .cx-theme-high-contrast-dark .card .header, .cx-theme-high-contrast-light cx-org-list .card .header, cx-org-list .cx-theme-high-contrast-light .card .header {
  background-color: var(--cx-color-background);
}
@media (min-width: 768px) {
  cx-org-list .card .header {
    height: 130px;
    align-items: center;
  }
}
cx-org-list .card .header .title-bar {
  display: flex;
  align-items: center;
  flex: 100%;
  justify-content: space-between;
}
cx-org-list .card .header h3 {
  margin: 0 0 5px 0;
  font-size: 22px;
  text-transform: uppercase;
}
cx-org-list .card .header h4 {
  font-size: 16px;
}
cx-org-list .card .header .actions {
  display: flex;
  align-items: baseline;
}
cx-org-list .card .header .actions a,
cx-org-list .card .header .actions button {
  text-transform: uppercase;
}
@media (min-width: 768px) {
  cx-org-list .card .header .actions a,
  cx-org-list .card .header .actions button {
    margin-inline-start: 10px;
  }
}
@media (max-width: 767.98px) {
  cx-org-list .card .header .actions a,
  cx-org-list .card .header .actions button {
    margin-inline-end: 10px;
  }
}
cx-org-list .card .header .close cx-icon {
  color: var(--cx-color-text);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
@media (max-width: 767.98px) {
  cx-org-list .card .header {
    padding: 40px 30px 25px 30px;
  }
  cx-org-list .card .header .title-bar {
    flex-wrap: wrap;
  }
  cx-org-list .card .header .actions {
    flex: 100%;
  }
}

cx-org-list .card .main {
  position: relative;
  background: var(--cx-color-background);
}

cx-org-list .card section {
  display: flex;
  margin: 40px;
  background-color: var(--cx-color-inverse);
  border: solid 1px var(--cx-color-light);
}
.cx-theme-high-contrast-light cx-org-list .card section, cx-org-list .cx-theme-high-contrast-light .card section {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.cx-theme-high-contrast-dark cx-org-list .card section, cx-org-list .cx-theme-high-contrast-dark .card section {
  border: solid 1px var(--cx-color-text);
}
.cx-theme-high-contrast-dark cx-org-list .card section, cx-org-list .cx-theme-high-contrast-dark .card section, .cx-theme-high-contrast-light cx-org-list .card section, cx-org-list .cx-theme-high-contrast-light .card section {
  background-color: var(--cx-color-background);
}
cx-org-list .card section.link-list {
  display: flex;
  flex-direction: column;
}
.cx-theme-high-contrast-dark cx-org-list .card section.link-list, cx-org-list .cx-theme-high-contrast-dark .card section.link-list, .cx-theme-high-contrast-light cx-org-list .card section.link-list, cx-org-list .cx-theme-high-contrast-light .card section.link-list {
  background-color: var(--cx-color-background);
}
cx-org-list .card section.link-list a {
  padding: 25px 30px;
  justify-content: flex-start;
  min-height: 74px;
  background-image: var(--list-bg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: var(--list-bg-pos);
}
cx-org-list .card section.link-list a:not(:last-child) {
  border-bottom: solid 1px var(--cx-color-light);
}
.cx-theme-high-contrast-light cx-org-list .card section.link-list a:not(:last-child), cx-org-list .cx-theme-high-contrast-light .card section.link-list a:not(:last-child) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.125);
}
.cx-theme-high-contrast-dark cx-org-list .card section.link-list a:not(:last-child), cx-org-list .cx-theme-high-contrast-dark .card section.link-list a:not(:last-child) {
  border-bottom: solid 1px var(--cx-color-text);
}
cx-org-list .card section.link-list a:hover:not(.is-current) {
  background-color: var(--cx-color-background);
}
cx-org-list .card section.link-list a.is-current {
  outline: solid 3px var(--cx-color-primary);
  outline-offset: -3px;
  background-color: var(--cx-color-background-focus);
}

cx-org-list .details {
  padding-top: 30px;
  padding-inline-start: 30px;
  padding-inline-end: 10px;
  display: flex;
  flex-wrap: wrap;
}
cx-org-list .details .property {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  cx-org-list .details .property {
    flex: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-org-list .details .property {
    flex: 100%;
  }
}
cx-org-list .details .property.full-width {
  flex: 100%;
}
cx-org-list .details label,
cx-org-list .details legend {
  font-weight: bold;
  font-size: 18px;
}
cx-org-list .details label.form-check,
cx-org-list .details fieldset {
  font-weight: normal;
  padding-inline-end: 20px;
  padding-bottom: 10px;
  margin: 0;
}
cx-org-list .details .value {
  font-size: 16px;
  padding-inline-end: 20px;
}
cx-org-list .details .value.is-active {
  color: var(--cx-color-success);
}
cx-org-list .details .value.is-inactive {
  color: var(--cx-color-alert, var(--cx-color-danger));
}
cx-org-list .details ul.value {
  margin: 0;
  padding: 0;
  padding-inline-end: 20px;
}
cx-org-list .details ul.value li {
  display: inline;
  white-space: pre-wrap;
}
cx-org-list .details ul.value li:not(:last-child):after {
  content: ", ";
}
cx-org-list cx-org-disable-info > section {
  padding: 25px 30px;
}
cx-org-list cx-org-disable-info > section ul {
  margin: 0;
}
cx-org-list cx-org-disable-info > section ul > li:only-child {
  list-style: none;
  margin-inline-start: -1.5em;
}
cx-org-list cx-org-disable-info > section > .cx-icon {
  align-self: flex-start;
  margin-top: 4px;
  color: var(--cx-color, var(--cx-color-info));
}

cx-org-list ng-select {
  font-weight: normal;
}
cx-org-list ng-select .ng-select-container {
  max-height: 48px;
}
cx-org-list form section {
  padding-bottom: 20px;
}
cx-org-list label {
  max-width: 100%;
  padding-inline-end: 20px;
  padding-bottom: 10px;
  margin: 0;
}
@media (min-width: 768px) {
  cx-org-list label {
    flex: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-org-list label {
    flex: 100%;
  }
}
cx-org-list label .form-check {
  margin: 0;
}
cx-org-list .full-width {
  flex: 100%;
}
cx-org-list .form-group {
  padding-inline-end: 20px;
}
@media (min-width: 768px) {
  cx-org-list .form-group {
    flex: 50%;
  }
}
@media (max-width: 767.98px) {
  cx-org-list .form-group {
    flex: 100%;
  }
}
cx-org-list .form-group cx-form-errors {
  position: relative;
}
cx-org-list .form-group cx-form-errors p {
  padding-inline-start: 30px;
  font-size: 16px;
  font-weight: normal;
}
.cxFeat_a11yImproveContrast cx-org-list .form-group cx-form-errors p {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.cx-theme-high-contrast-dark cx-org-list .form-group cx-form-errors p::after {
  color: var(--cx-color-medium);
}
cx-org-list ng-select {
  font-weight: normal;
}
cx-org-list ng-select .ng-select-container {
  max-height: 48px;
}
@media (max-width: 767.98px) {
  cx-org-list .ng-dropdown-panel span {
    white-space: normal;
  }
}

cx-page-layout.CompanyPageTemplate {
  max-width: var(--cx-page-width-max);
  margin: auto;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  cx-page-layout.CompanyPageTemplate cx-page-slot {
    margin: 0;
  }
}
cx-page-layout.CompanyPageTemplate cx-banner cx-media {
  --cx-img-filter: invert(34%) sepia(61%) saturate(1353%) hue-rotate(178deg)
    brightness(90%) contrast(90%);
}
.cx-theme-high-contrast-dark cx-page-layout.CompanyPageTemplate cx-banner cx-media {
  --cx-img-filter: invert(68%) sepia(15%) saturate(1363%) hue-rotate(161deg)
    brightness(105%) contrast(108%);
}
.cx-theme-high-contrast-light cx-page-layout.CompanyPageTemplate cx-banner cx-media {
  --cx-img-filter: invert(22%) sepia(100%) saturate(7497%)
    hue-rotate(213deg) brightness(90%) contrast(101%);
}
cx-page-layout.CompanyPageTemplate .BodyContent {
  justify-content: space-between;
}
@media (min-width: 576px) {
  cx-page-layout.CompanyPageTemplate .BodyContent {
    margin: 0 10px;
  }
}
cx-page-layout.CompanyPageTemplate cx-banner p {
  all: unset;
}
cx-page-layout.CompanyPageTemplate cx-banner {
  flex: 100%;
  margin-top: 20px;
}
@media (min-width: 768px) {
  cx-page-layout.CompanyPageTemplate cx-banner {
    flex: 0 0 calc(33% - 10px);
  }
}
@media (max-width: 991.98px) {
  cx-page-layout.CompanyPageTemplate cx-banner {
    flex: calc(50% - 40px);
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }
}
@media (max-width: 767.98px) {
  cx-page-layout.CompanyPageTemplate cx-banner {
    flex: 100%;
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }
}
cx-page-layout.CompanyPageTemplate cx-banner cx-generic-link,
cx-page-layout.CompanyPageTemplate cx-banner a {
  height: 100%;
}
cx-page-layout.CompanyPageTemplate cx-banner a {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: min-content 1fr;
  gap: 3% 30px;
  grid-template-areas: "media ." "media .";
  padding: 25px 25px 25px 30px;
  border: solid 1px var(--cx-color-light);
}
cx-page-layout.CompanyPageTemplate cx-banner a:hover {
  text-decoration: none;
  border-color: var(--cx-color-background);
  background: var(--cx-color-background);
}
cx-page-layout.CompanyPageTemplate cx-banner a cx-media {
  grid-area: media;
}
cx-page-layout.CompanyPageTemplate cx-banner a cx-media img {
  filter: var(--cx-img-filter);
  width: 50px;
  height: 50px;
}
cx-page-layout.CompanyPageTemplate cx-banner a p {
  color: var(--cx-color-text);
}
cx-page-layout.CompanyPageTemplate cx-banner a p.headline {
  font-weight: bold;
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: min-content 1fr;
  gap: 3% 30px;
  grid-template-areas: "media heading" "media content";
  padding: 25px 25px 25px 30px;
  border: solid 1px var(--cx-color-light);
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner a {
  display: unset;
  border: unset;
  padding: unset;
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner a:hover {
  border-color: unset;
  background: unset;
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner:hover {
  text-decoration: none;
  border-color: var(--cx-color-background);
  background: var(--cx-color-background);
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner cx-media {
  grid-area: media;
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner cx-media img {
  filter: var(--cx-img-filter);
  width: 50px;
  height: 50px;
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner .content {
  grid-area: content;
}
.cxFeat_a11yOrganizationsBanner cx-page-layout.CompanyPageTemplate cx-banner .headline {
  grid-area: heading;
  font-weight: bold;
}

cx-org-unit-list {
  display: contents;
}

cx-org-list {
  --list-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 44.21 69.76'%3E%3Cpolyline fill='none' stroke='%23D3D6DA' stroke-width='10' points='5,5 35,35 5,65 '/%3E%3C/svg%3E%0A");
  --list-bg-pos: calc(100% - 26px) 50%;
}

html[dir=rtl] cx-org-list {
  --list-bg: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 44.21 69.76' %3E%3Cpolyline transform='scale (-1, 1)' transform-origin='center' fill='none' stroke='%23D3D6DA' stroke-width='10' points='5,5 35,35 5,65 '/%3E%3C/svg%3E");
  --list-bg-pos: 25px;
}

.my-company-popover .popover-details > .property {
  margin-bottom: 0.375rem;
}
.my-company-popover .popover-details > .property > label {
  font-weight: bold;
  margin-bottom: 0;
}
.my-company-popover .value {
  font-size: 0.75rem;
}

.hint-popover {
  font-size: 0.75rem;
}
.hint-popover p {
  margin: 0;
}

cx-order-approval-list .cx-order-approval-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-width: 1px 0 1px 0;
  border-color: var(--cx-color-light);
  border-style: solid;
}
cx-order-approval-list .cx-order-approval-table tr {
  width: 100%;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-table tr {
    border-width: 1px 0 0 0;
    border-color: var(--cx-color-light);
    border-style: solid;
  }
  cx-order-approval-list .cx-order-approval-table tr:first-child {
    border-width: 0;
    padding: 1.25rem 0 0 0;
  }
}
cx-order-approval-list .cx-order-approval-table th {
  padding: 1.5rem 0 1.125rem 0;
  text-align: start;
}
cx-order-approval-list .cx-order-approval-table th:last-child {
  text-align: end;
}
cx-order-approval-list .cx-order-approval-table td {
  width: 15%;
  padding: 1.625rem 0;
}
@media (min-width: 768px) {
  cx-order-approval-list .cx-order-approval-table td {
    text-align: start;
  }
  cx-order-approval-list .cx-order-approval-table td:last-child {
    text-align: end;
  }
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-order-approval-list .cx-order-approval-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-order-approval-list .cx-order-approval-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-thead-mobile {
    display: none;
  }
}
cx-order-approval-list .cx-order-approval-code .cx-order-approval-value {
  text-decoration: underline;
}
cx-order-approval-list .cx-order-approval-po-code,
cx-order-approval-list .cx-order-approval-date,
cx-order-approval-list .cx-order-approval-status,
cx-order-approval-list .cx-order-approval-total,
cx-order-approval-list .cx-order-approval-placed {
  text-align: center;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-po-code,
  cx-order-approval-list .cx-order-approval-date,
  cx-order-approval-list .cx-order-approval-status,
  cx-order-approval-list .cx-order-approval-total,
  cx-order-approval-list .cx-order-approval-placed {
    text-align: start;
  }
}
cx-order-approval-list .cx-order-approval-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 110px;
  }
}
cx-order-approval-list .cx-order-approval-value {
  color: var(--cx-color-text);
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: "normal";
    line-height: var(--cx-line-height, 1.2222222222);
  }
}
cx-order-approval-list .cx-order-approval-form-group {
  padding: 0;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-form-group {
    padding: 1.25rem;
  }
}
cx-order-approval-list .cx-order-approval-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-sort.top {
    flex-direction: column;
    padding-top: 0;
  }
}
cx-order-approval-list .cx-order-approval-sort.bottom {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-sort.bottom {
    flex-direction: column;
    padding-top: 0;
  }
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-pagination {
    margin: 0 auto;
  }
}
cx-order-approval-list .cx-order-approval-no-order {
  font-size: var(--cx-font-size, 1rem);
  font-weight: "normal";
  line-height: var(--cx-line-height, 1.2222222222);
  min-height: 415px;
}
@media (max-width: 767.98px) {
  cx-order-approval-list .cx-order-approval-no-order {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
cx-order-approval-list .cx-order-approval-no-order .btn, cx-order-approval-list .cx-order-approval-no-order .cx-organization-user-register-button a, .cx-organization-user-register-button cx-order-approval-list .cx-order-approval-no-order a, cx-order-approval-list .cx-order-approval-no-order cx-org-list a.link, cx-org-list cx-order-approval-list .cx-order-approval-no-order a.link,
cx-order-approval-list .cx-order-approval-no-order cx-org-list button.link,
cx-org-list cx-order-approval-list .cx-order-approval-no-order button.link, cx-order-approval-list .cx-order-approval-no-order cx-org-list button.button, cx-org-list cx-order-approval-list .cx-order-approval-no-order button.button,
cx-order-approval-list .cx-order-approval-no-order cx-org-list a.button,
cx-org-list cx-order-approval-list .cx-order-approval-no-order a.button {
  margin: 1.25rem 0;
}

cx-order-approval-detail-form {
  margin-bottom: 15px;
}
cx-order-approval-detail-form .cx-approval-form-header {
  padding: 15px;
  margin-bottom: 15px;
  background-color: var(--cx-color-background);
}
cx-order-approval-detail-form .cx-approval-form-header .cx-approval-form-label {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-order-approval-detail-form textarea.form-control {
  margin-bottom: 15px;
}
@media (max-width: 991.98px) {
  cx-order-approval-detail-form a.btn, cx-order-approval-detail-form .cx-organization-user-register-button a, .cx-organization-user-register-button cx-order-approval-detail-form a, cx-order-approval-detail-form cx-org-list a.link, cx-org-list cx-order-approval-detail-form a.link,
  cx-order-approval-detail-form cx-org-list a.button,
  cx-org-list cx-order-approval-detail-form a.button {
    margin-bottom: 15px;
  }
}
@media (max-width: 991.98px) {
  cx-order-approval-detail-form button {
    margin-bottom: 15px;
  }
}

cx-order-detail-permission-results {
  margin: 45px 0px 15px;
}
cx-order-detail-permission-results .cx-approval-header {
  padding: 15px;
  background-color: var(--cx-color-background);
}
cx-order-detail-permission-results .cx-approval-header .cx-approval-label {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-order-detail-permission-results .cx-approval-table {
  padding: 15px;
}
cx-order-detail-permission-results .cx-approval-table th,
cx-order-detail-permission-results .cx-approval-table td {
  padding-top: 0.75rem;
  padding-inline-end: 0.938rem;
  padding-bottom: 0.75rem;
  padding-inline-start: 0.938rem;
  vertical-align: top;
  border-top: 1px solid var(--cx-color-light);
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-thead-mobile {
  background-color: var(--cx-color-dark);
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-thead-mobile th {
  text-align: start;
  color: var(--cx-color-inverse);
}
@media (max-width: 991.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-thead-mobile {
    display: none;
  }
}
cx-order-detail-permission-results .cx-approval-table.table-striped tr:nth-child(odd) {
  background-color: var(--cx-color-background);
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-permissionCode {
  width: 25%;
}
@media (max-width: 991.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-permissionCode {
    width: 100%;
  }
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-approverName {
  width: 17%;
}
@media (max-width: 991.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-approverName {
    width: 100%;
  }
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-statusDisplay {
  width: 13%;
}
@media (max-width: 991.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-statusDisplay {
    width: 100%;
  }
}
cx-order-detail-permission-results .cx-approval-table .cx-approval-table-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (min-width: 992px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-table-label {
    display: none;
  }
}
@media (max-width: 991.98px) {
  cx-order-detail-permission-results .cx-approval-table .cx-approval-table-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 110px;
    max-width: 110px;
  }
}
@media (max-width: 991.98px) {
  cx-order-detail-permission-results .cx-approval-table td {
    width: 100%;
    display: flex;
    align-items: baseline;
    border-width: 0;
    padding: 0.313rem 1.25rem;
  }
  cx-order-detail-permission-results .cx-approval-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-order-detail-permission-results .cx-approval-table td:last-child {
    padding-bottom: 1.25rem;
  }
}

.cx-organization-user-register-button .santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}
.cx-organization-user-register-button .santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}
.cx-organization-user-register-button .btn, .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cx-organization-user-register-button a.link,
.cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cx-organization-user-register-button button.link, .cx-organization-user-register-button cx-org-list button.button, cx-org-list .cx-organization-user-register-button button.button,
.cx-organization-user-register-button cx-org-list a.button,
cx-org-list .cx-organization-user-register-button a.button {
  display: inline-block;
  font-weight: "normal";
  color: #14293a;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 14px 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .cx-organization-user-register-button .btn, .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cx-organization-user-register-button a.link,
  .cx-organization-user-register-button cx-org-list button.link,
  cx-org-list .cx-organization-user-register-button button.link, .cx-organization-user-register-button cx-org-list button.button, cx-org-list .cx-organization-user-register-button button.button,
  .cx-organization-user-register-button cx-org-list a.button,
  cx-org-list .cx-organization-user-register-button a.button {
    transition: none;
  }
}
.cx-organization-user-register-button .btn:hover, .cx-organization-user-register-button a:hover,
.cx-organization-user-register-button cx-org-list button.link:hover,
cx-org-list .cx-organization-user-register-button button.link:hover, .cx-organization-user-register-button cx-org-list button.button:hover, cx-org-list .cx-organization-user-register-button button.button:hover {
  color: #14293a;
  text-decoration: none;
}
.cx-organization-user-register-button .btn:focus, .cx-organization-user-register-button a:focus,
.cx-organization-user-register-button cx-org-list button.link:focus,
cx-org-list .cx-organization-user-register-button button.link:focus, .cx-organization-user-register-button cx-org-list button.button:focus, cx-org-list .cx-organization-user-register-button button.button:focus, .cx-organization-user-register-button .btn.focus, .cx-organization-user-register-button a.focus,
.cx-organization-user-register-button cx-org-list button.focus.link,
cx-org-list .cx-organization-user-register-button button.focus.link, .cx-organization-user-register-button cx-org-list button.focus.button, cx-org-list .cx-organization-user-register-button button.focus.button {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.25);
}
.cx-organization-user-register-button .btn.disabled, .cx-organization-user-register-button a.disabled,
.cx-organization-user-register-button cx-org-list button.disabled.link,
cx-org-list .cx-organization-user-register-button button.disabled.link, .cx-organization-user-register-button cx-org-list button.disabled.button, cx-org-list .cx-organization-user-register-button button.disabled.button, .cx-organization-user-register-button .btn:disabled, .cx-organization-user-register-button a:disabled,
.cx-organization-user-register-button cx-org-list button.link:disabled,
cx-org-list .cx-organization-user-register-button button.link:disabled, .cx-organization-user-register-button cx-org-list button.button:disabled, cx-org-list .cx-organization-user-register-button button.button:disabled {
  opacity: 0.65;
}
.cx-organization-user-register-button .btn:not(:disabled):not(.disabled), .cx-organization-user-register-button a:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.link:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.link:not(:disabled):not(.disabled), .cx-organization-user-register-button cx-org-list button.button:not(:disabled):not(.disabled), cx-org-list .cx-organization-user-register-button button.button:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.cx-organization-user-register-button a.btn.disabled, .cx-organization-user-register-button a.disabled,
.cx-organization-user-register-button fieldset:disabled a.btn,
.cx-organization-user-register-button fieldset:disabled a {
  pointer-events: none;
}
.cx-organization-user-register-button .btn-background {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.cx-organization-user-register-button .btn-background:hover {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}
.cx-organization-user-register-button .btn-background:focus, .cx-organization-user-register-button .btn-background.focus {
  color: #212529;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
.cx-organization-user-register-button .btn-background.disabled, .cx-organization-user-register-button .btn-background:disabled {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.cx-organization-user-register-button .btn-background:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-background:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-background.dropdown-toggle {
  color: #212529;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}
.cx-organization-user-register-button .btn-background:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-background:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5);
}
.cx-organization-user-register-button .btn-text {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.cx-organization-user-register-button .btn-text:hover {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
}
.cx-organization-user-register-button .btn-text:focus, .cx-organization-user-register-button .btn-text.focus {
  color: #fff;
  background-color: #0a151e;
  border-color: #070e14;
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}
.cx-organization-user-register-button .btn-text.disabled, .cx-organization-user-register-button .btn-text:disabled {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.cx-organization-user-register-button .btn-text:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-text:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-text.dropdown-toggle {
  color: #fff;
  background-color: #070e14;
  border-color: #04070b;
}
.cx-organization-user-register-button .btn-text:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-text:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 73, 88, 0.5);
}
.cx-organization-user-register-button .btn-inverse {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.cx-organization-user-register-button .btn-inverse:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.cx-organization-user-register-button .btn-inverse:focus, .cx-organization-user-register-button .btn-inverse.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.cx-organization-user-register-button .btn-inverse.disabled, .cx-organization-user-register-button .btn-inverse:disabled {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.cx-organization-user-register-button .btn-inverse:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-inverse:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-inverse.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.cx-organization-user-register-button .btn-inverse:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-inverse:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.cx-organization-user-register-button .btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.cx-organization-user-register-button .btn-transparent:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.cx-organization-user-register-button .btn-transparent:focus, .cx-organization-user-register-button .btn-transparent.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.cx-organization-user-register-button .btn-transparent.disabled, .cx-organization-user-register-button .btn-transparent:disabled {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.cx-organization-user-register-button .btn-transparent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-transparent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-transparent.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}
.cx-organization-user-register-button .btn-transparent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-transparent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.cx-organization-user-register-button .btn-primary, .cx-organization-user-register-button cx-org-list a.button.primary, cx-org-list .cx-organization-user-register-button a.button.primary,
.cx-organization-user-register-button cx-org-list a.button.primary.disabled,
cx-org-list .cx-organization-user-register-button a.button.primary.disabled,
.cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary,
cx-org-list .cx-organization-user-register-button button.button.primary,
.cx-organization-user-register-button cx-org-list button.button.primary.disabled,
cx-org-list .cx-organization-user-register-button button.button.primary.disabled,
.cx-organization-user-register-button cx-org-list button.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.cx-organization-user-register-button .btn-primary:hover, .cx-organization-user-register-button cx-org-list a.button.primary:hover, cx-org-list .cx-organization-user-register-button a.button.primary:hover,
.cx-organization-user-register-button cx-org-list a.button.primary:hover:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:hover:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary:hover,
cx-org-list .cx-organization-user-register-button button.button.primary:hover,
.cx-organization-user-register-button cx-org-list button.button.primary:hover:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:hover:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
}
.cx-organization-user-register-button .btn-primary:focus, .cx-organization-user-register-button cx-org-list a.button.primary:focus, cx-org-list .cx-organization-user-register-button a.button.primary:focus,
.cx-organization-user-register-button cx-org-list a.button.primary:focus:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:focus:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary:focus,
cx-org-list .cx-organization-user-register-button button.button.primary:focus,
.cx-organization-user-register-button cx-org-list button.button.primary:focus:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:focus:active:not(:disabled):not(.disabled), .cx-organization-user-register-button .btn-primary.focus, .cx-organization-user-register-button cx-org-list a.focus.button.primary, cx-org-list .cx-organization-user-register-button a.focus.button.primary,
.cx-organization-user-register-button cx-org-list a.focus.button.primary:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.focus.button.primary:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.focus.button.primary,
cx-org-list .cx-organization-user-register-button button.focus.button.primary,
.cx-organization-user-register-button cx-org-list button.focus.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.focus.button.primary:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1a669f;
  border-color: #185f94;
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}
.cx-organization-user-register-button .btn-primary.disabled,
.cx-organization-user-register-button cx-org-list a.disabled.button.primary,
cx-org-list .cx-organization-user-register-button a.disabled.button.primary,
.cx-organization-user-register-button cx-org-list a.disabled.button.primary:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.disabled.button.primary:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.disabled.button.primary,
cx-org-list .cx-organization-user-register-button button.disabled.button.primary,
.cx-organization-user-register-button cx-org-list button.disabled.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.disabled.button.primary:active:not(:disabled):not(.disabled), .cx-organization-user-register-button .btn-primary:disabled, .cx-organization-user-register-button cx-org-list a.button.primary:disabled, cx-org-list .cx-organization-user-register-button a.button.primary:disabled,
.cx-organization-user-register-button cx-org-list a.button.primary:disabled:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:disabled:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary:disabled,
cx-org-list .cx-organization-user-register-button button.button.primary:disabled,
.cx-organization-user-register-button cx-org-list button.button.primary:disabled:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:disabled:active:not(:disabled):not(.disabled) {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled):active, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled):active,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled):active,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled).active, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled).active, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled).active,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled).active,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-primary.dropdown-toggle, .show > .cx-organization-user-register-button cx-org-list a.dropdown-toggle.button.primary, cx-org-list .show > .cx-organization-user-register-button a.dropdown-toggle.button.primary,
.show > .cx-organization-user-register-button cx-org-list button.dropdown-toggle.button.primary,
cx-org-list .show > .cx-organization-user-register-button button.dropdown-toggle.button.primary {
  color: #fff;
  background-color: #185f94;
  border-color: #165889;
}
.cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled):active:focus, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled):active:focus,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled):active:focus,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled).active:focus, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled).active:focus, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled).active:focus,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled).active:focus,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-primary.dropdown-toggle:focus, .show > .cx-organization-user-register-button cx-org-list a.dropdown-toggle.button.primary:focus, cx-org-list .show > .cx-organization-user-register-button a.dropdown-toggle.button.primary:focus,
.show > .cx-organization-user-register-button cx-org-list button.dropdown-toggle.button.primary:focus,
cx-org-list .show > .cx-organization-user-register-button button.dropdown-toggle.button.primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 143, 201, 0.5);
}
.cx-organization-user-register-button .btn-secondary, .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list button.button.secondary, cx-org-list .cx-organization-user-register-button button.button.secondary,
.cx-organization-user-register-button cx-org-list a.button.secondary,
cx-org-list .cx-organization-user-register-button a.button.secondary {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-secondary:hover, .cx-organization-user-register-button a:hover, .cx-organization-user-register-button cx-org-list button.button.secondary:hover, cx-org-list .cx-organization-user-register-button button.button.secondary:hover,
.cx-organization-user-register-button cx-org-list a.button.secondary:hover,
cx-org-list .cx-organization-user-register-button a.button.secondary:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
.cx-organization-user-register-button .btn-secondary:focus, .cx-organization-user-register-button a:focus, .cx-organization-user-register-button cx-org-list button.button.secondary:focus, cx-org-list .cx-organization-user-register-button button.button.secondary:focus,
.cx-organization-user-register-button cx-org-list a.button.secondary:focus,
cx-org-list .cx-organization-user-register-button a.button.secondary:focus, .cx-organization-user-register-button .btn-secondary.focus, .cx-organization-user-register-button a.focus, .cx-organization-user-register-button cx-org-list button.focus.button.secondary, cx-org-list .cx-organization-user-register-button button.focus.button.secondary,
.cx-organization-user-register-button cx-org-list a.focus.button.secondary,
cx-org-list .cx-organization-user-register-button a.focus.button.secondary {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
.cx-organization-user-register-button .btn-secondary.disabled, .cx-organization-user-register-button a.disabled, .cx-organization-user-register-button cx-org-list button.disabled.button.secondary, cx-org-list .cx-organization-user-register-button button.disabled.button.secondary,
.cx-organization-user-register-button cx-org-list a.disabled.button.secondary,
cx-org-list .cx-organization-user-register-button a.disabled.button.secondary, .cx-organization-user-register-button .btn-secondary:disabled, .cx-organization-user-register-button a:disabled, .cx-organization-user-register-button cx-org-list button.button.secondary:disabled, cx-org-list .cx-organization-user-register-button button.button.secondary:disabled,
.cx-organization-user-register-button cx-org-list a.button.secondary:disabled,
cx-org-list .cx-organization-user-register-button a.button.secondary:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button a:not(:disabled):not(.disabled):active, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled):active, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled).active, .cx-organization-user-register-button a:not(:disabled):not(.disabled).active, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled).active, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-secondary.dropdown-toggle, .show > .cx-organization-user-register-button a.dropdown-toggle, .show > .cx-organization-user-register-button cx-org-list button.dropdown-toggle.button.secondary, cx-org-list .show > .cx-organization-user-register-button button.dropdown-toggle.button.secondary {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
.cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button a:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled):active:focus, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled).active:focus, .cx-organization-user-register-button a:not(:disabled):not(.disabled).active:focus, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled).active:focus, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-secondary.dropdown-toggle:focus, .show > .cx-organization-user-register-button a.dropdown-toggle:focus, .show > .cx-organization-user-register-button cx-org-list button.dropdown-toggle.button.secondary:focus, cx-org-list .show > .cx-organization-user-register-button button.dropdown-toggle.button.secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
.cx-organization-user-register-button .btn-success {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.cx-organization-user-register-button .btn-success:hover {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
}
.cx-organization-user-register-button .btn-success:focus, .cx-organization-user-register-button .btn-success.focus {
  color: #fff;
  background-color: #2b6818;
  border-color: #275e15;
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}
.cx-organization-user-register-button .btn-success.disabled, .cx-organization-user-register-button .btn-success:disabled {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.cx-organization-user-register-button .btn-success:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-success:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #275e15;
  border-color: #225313;
}
.cx-organization-user-register-button .btn-success:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-success:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 153, 65, 0.5);
}
.cx-organization-user-register-button .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.cx-organization-user-register-button .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.cx-organization-user-register-button .btn-info:focus, .cx-organization-user-register-button .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.cx-organization-user-register-button .btn-info.disabled, .cx-organization-user-register-button .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.cx-organization-user-register-button .btn-info:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-info:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.cx-organization-user-register-button .btn-info:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-info:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.cx-organization-user-register-button .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.cx-organization-user-register-button .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.cx-organization-user-register-button .btn-warning:focus, .cx-organization-user-register-button .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.cx-organization-user-register-button .btn-warning.disabled, .cx-organization-user-register-button .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.cx-organization-user-register-button .btn-warning:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-warning:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.cx-organization-user-register-button .btn-warning:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.cx-organization-user-register-button .btn-danger {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.cx-organization-user-register-button .btn-danger:hover {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
}
.cx-organization-user-register-button .btn-danger:focus, .cx-organization-user-register-button .btn-danger.focus {
  color: #fff;
  background-color: #b50002;
  border-color: #a80002;
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}
.cx-organization-user-register-button .btn-danger.disabled, .cx-organization-user-register-button .btn-danger:disabled {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.cx-organization-user-register-button .btn-danger:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-danger:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a80002;
  border-color: #9b0001;
}
.cx-organization-user-register-button .btn-danger:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 38, 40, 0.5);
}
.cx-organization-user-register-button .btn-light {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.cx-organization-user-register-button .btn-light:hover {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
}
.cx-organization-user-register-button .btn-light:focus, .cx-organization-user-register-button .btn-light.focus {
  color: #212529;
  background-color: #dedede;
  border-color: #d8d8d8;
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}
.cx-organization-user-register-button .btn-light.disabled, .cx-organization-user-register-button .btn-light:disabled {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.cx-organization-user-register-button .btn-light:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-light:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d8d8d8;
  border-color: #d1d1d1;
}
.cx-organization-user-register-button .btn-light:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-light:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 211, 0.5);
}
.cx-organization-user-register-button .btn-dark {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-dark:hover {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
}
.cx-organization-user-register-button .btn-dark:focus, .cx-organization-user-register-button .btn-dark.focus {
  color: #fff;
  background-color: #5a5d65;
  border-color: #54575e;
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
.cx-organization-user-register-button .btn-dark.disabled, .cx-organization-user-register-button .btn-dark:disabled {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-dark:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-dark:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #54575e;
  border-color: #4e5157;
}
.cx-organization-user-register-button .btn-dark:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 133, 141, 0.5);
}
.cx-organization-user-register-button .btn-primary-accent {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.cx-organization-user-register-button .btn-primary-accent:hover {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
}
.cx-organization-user-register-button .btn-primary-accent:focus, .cx-organization-user-register-button .btn-primary-accent.focus {
  color: #fff;
  background-color: #04497a;
  border-color: #03416e;
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}
.cx-organization-user-register-button .btn-primary-accent.disabled, .cx-organization-user-register-button .btn-primary-accent:disabled {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.cx-organization-user-register-button .btn-primary-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-primary-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #03416e;
  border-color: #033a61;
}
.cx-organization-user-register-button .btn-primary-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-primary-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(43, 119, 173, 0.5);
}
.cx-organization-user-register-button .btn-success-accent {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.cx-organization-user-register-button .btn-success-accent:hover {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
}
.cx-organization-user-register-button .btn-success-accent:focus, .cx-organization-user-register-button .btn-success-accent.focus {
  color: #212529;
  background-color: #ddf6c3;
  border-color: #d7f4b8;
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}
.cx-organization-user-register-button .btn-success-accent.disabled, .cx-organization-user-register-button .btn-success-accent:disabled {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.cx-organization-user-register-button .btn-success-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-success-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #d7f4b8;
  border-color: #d1f3ac;
}
.cx-organization-user-register-button .btn-success-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-success-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 219, 200, 0.5);
}
.cx-organization-user-register-button .btn-danger-accent {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.cx-organization-user-register-button .btn-danger-accent:hover {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
}
.cx-organization-user-register-button .btn-danger-accent:focus, .cx-organization-user-register-button .btn-danger-accent.focus {
  color: #212529;
  background-color: #ffcbcb;
  border-color: #ffbebe;
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}
.cx-organization-user-register-button .btn-danger-accent.disabled, .cx-organization-user-register-button .btn-danger-accent:disabled {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.cx-organization-user-register-button .btn-danger-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-danger-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffbebe;
  border-color: #ffb1b1;
}
.cx-organization-user-register-button .btn-danger-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-danger-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 210, 211, 0.5);
}
.cx-organization-user-register-button .btn-warning-accent {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.cx-organization-user-register-button .btn-warning-accent:hover {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
}
.cx-organization-user-register-button .btn-warning-accent:focus, .cx-organization-user-register-button .btn-warning-accent.focus {
  color: #212529;
  background-color: #ffe9b9;
  border-color: #ffe5ac;
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}
.cx-organization-user-register-button .btn-warning-accent.disabled, .cx-organization-user-register-button .btn-warning-accent:disabled {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.cx-organization-user-register-button .btn-warning-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-warning-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #ffe5ac;
  border-color: #ffe19f;
}
.cx-organization-user-register-button .btn-warning-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-warning-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 214, 196, 0.5);
}
.cx-organization-user-register-button .btn-info-accent {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.cx-organization-user-register-button .btn-info-accent:hover {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
}
.cx-organization-user-register-button .btn-info-accent:focus, .cx-organization-user-register-button .btn-info-accent.focus {
  color: #212529;
  background-color: #b9ddfd;
  border-color: #add7fd;
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}
.cx-organization-user-register-button .btn-info-accent.disabled, .cx-organization-user-register-button .btn-info-accent:disabled {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.cx-organization-user-register-button .btn-info-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-info-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #add7fd;
  border-color: #a0d1fc;
}
.cx-organization-user-register-button .btn-info-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-info-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 209, 222, 0.5);
}
.cx-organization-user-register-button .btn-medium {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.cx-organization-user-register-button .btn-medium:hover {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
}
.cx-organization-user-register-button .btn-medium:focus, .cx-organization-user-register-button .btn-medium.focus {
  color: #212529;
  background-color: #bec2ca;
  border-color: #b7bcc4;
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}
.cx-organization-user-register-button .btn-medium.disabled, .cx-organization-user-register-button .btn-medium:disabled {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.cx-organization-user-register-button .btn-medium:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-medium:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-medium.dropdown-toggle {
  color: #212529;
  background-color: #b7bcc4;
  border-color: #b0b5be;
}
.cx-organization-user-register-button .btn-medium:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-medium:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 187, 192, 0.5);
}
.cx-organization-user-register-button .btn-background-dark {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.cx-organization-user-register-button .btn-background-dark:hover {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
}
.cx-organization-user-register-button .btn-background-dark:focus, .cx-organization-user-register-button .btn-background-dark.focus {
  color: #fff;
  background-color: #131620;
  border-color: #0e1118;
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}
.cx-organization-user-register-button .btn-background-dark.disabled, .cx-organization-user-register-button .btn-background-dark:disabled {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.cx-organization-user-register-button .btn-background-dark:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-background-dark:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #0e1118;
  border-color: #090b10;
}
.cx-organization-user-register-button .btn-background-dark:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-background-dark:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 71, 86, 0.5);
}
.cx-organization-user-register-button .btn-visual-focus {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.cx-organization-user-register-button .btn-visual-focus:hover {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
}
.cx-organization-user-register-button .btn-visual-focus:focus, .cx-organization-user-register-button .btn-visual-focus.focus {
  color: #fff;
  background-color: #4985f5;
  border-color: #3d7df4;
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}
.cx-organization-user-register-button .btn-visual-focus.disabled, .cx-organization-user-register-button .btn-visual-focus:disabled {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.cx-organization-user-register-button .btn-visual-focus:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-visual-focus:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-visual-focus.dropdown-toggle {
  color: #fff;
  background-color: #3d7df4;
  border-color: #3174f4;
}
.cx-organization-user-register-button .btn-visual-focus:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-visual-focus:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 139, 216, 0.5);
}
.cx-organization-user-register-button .btn-background-focus {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.cx-organization-user-register-button .btn-background-focus:hover {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
}
.cx-organization-user-register-button .btn-background-focus:focus, .cx-organization-user-register-button .btn-background-focus.focus {
  color: #fff;
  background-color: rgba(44, 160, 242, 0.1);
  border-color: rgba(32, 154, 241, 0.1);
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}
.cx-organization-user-register-button .btn-background-focus.disabled, .cx-organization-user-register-button .btn-background-focus:disabled {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.cx-organization-user-register-button .btn-background-focus:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-background-focus:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-background-focus.dropdown-toggle {
  color: #fff;
  background-color: rgba(32, 154, 241, 0.1);
  border-color: rgba(20, 149, 240, 0.1);
}
.cx-organization-user-register-button .btn-background-focus:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-background-focus:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 69, 88, 0.5);
}
.cx-organization-user-register-button .btn-border-focus {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.cx-organization-user-register-button .btn-border-focus:hover {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
}
.cx-organization-user-register-button .btn-border-focus:focus, .cx-organization-user-register-button .btn-border-focus.focus {
  color: #212529;
  background-color: rgba(136, 187, 224, 0.6);
  border-color: rgba(125, 181, 222, 0.6);
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}
.cx-organization-user-register-button .btn-border-focus.disabled, .cx-organization-user-register-button .btn-border-focus:disabled {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.cx-organization-user-register-button .btn-border-focus:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-border-focus:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(125, 181, 222, 0.6);
  border-color: rgba(115, 175, 219, 0.6);
}
.cx-organization-user-register-button .btn-border-focus:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-border-focus:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(127, 155, 176, 0.5);
}
.cx-organization-user-register-button .btn-outline-background {
  color: #f4f4f4;
  border-color: #f4f4f4;
}
.cx-organization-user-register-button .btn-outline-background:hover {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.cx-organization-user-register-button .btn-outline-background:focus, .cx-organization-user-register-button .btn-outline-background.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
.cx-organization-user-register-button .btn-outline-background.disabled, .cx-organization-user-register-button .btn-outline-background:disabled {
  color: #f4f4f4;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-background:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-background:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-background.dropdown-toggle {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.cx-organization-user-register-button .btn-outline-background:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-background:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-background.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}
.cx-organization-user-register-button .btn-outline-text {
  color: #14293a;
  border-color: #14293a;
}
.cx-organization-user-register-button .btn-outline-text:hover {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.cx-organization-user-register-button .btn-outline-text:focus, .cx-organization-user-register-button .btn-outline-text.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}
.cx-organization-user-register-button .btn-outline-text.disabled, .cx-organization-user-register-button .btn-outline-text:disabled {
  color: #14293a;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-text:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-text:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-text.dropdown-toggle {
  color: #fff;
  background-color: #14293a;
  border-color: #14293a;
}
.cx-organization-user-register-button .btn-outline-text:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-text:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-text.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 41, 58, 0.5);
}
.cx-organization-user-register-button .btn-outline-inverse {
  color: #ffffff;
  border-color: #ffffff;
}
.cx-organization-user-register-button .btn-outline-inverse:hover {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.cx-organization-user-register-button .btn-outline-inverse:focus, .cx-organization-user-register-button .btn-outline-inverse.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.cx-organization-user-register-button .btn-outline-inverse.disabled, .cx-organization-user-register-button .btn-outline-inverse:disabled {
  color: #ffffff;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-inverse:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-inverse:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-inverse.dropdown-toggle {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff;
}
.cx-organization-user-register-button .btn-outline-inverse:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-inverse:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.cx-organization-user-register-button .btn-outline-transparent {
  color: transparent;
  border-color: transparent;
}
.cx-organization-user-register-button .btn-outline-transparent:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.cx-organization-user-register-button .btn-outline-transparent:focus, .cx-organization-user-register-button .btn-outline-transparent.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.cx-organization-user-register-button .btn-outline-transparent.disabled, .cx-organization-user-register-button .btn-outline-transparent:disabled {
  color: transparent;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-transparent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-transparent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-transparent.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.cx-organization-user-register-button .btn-outline-transparent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-transparent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.cx-organization-user-register-button .btn-outline-primary {
  color: #1f7bc0;
  border-color: #1f7bc0;
}
.cx-organization-user-register-button .btn-outline-primary:hover {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.cx-organization-user-register-button .btn-outline-primary:focus, .cx-organization-user-register-button .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}
.cx-organization-user-register-button .btn-outline-primary.disabled, .cx-organization-user-register-button .btn-outline-primary:disabled {
  color: #1f7bc0;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-primary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1f7bc0;
  border-color: #1f7bc0;
}
.cx-organization-user-register-button .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(31, 123, 192, 0.5);
}
.cx-organization-user-register-button .btn-outline-secondary {
  color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-outline-secondary:focus, .cx-organization-user-register-button .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
.cx-organization-user-register-button .btn-outline-secondary.disabled, .cx-organization-user-register-button .btn-outline-secondary:disabled {
  color: #6c7079;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-secondary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
.cx-organization-user-register-button .btn-outline-success {
  color: #38871f;
  border-color: #38871f;
}
.cx-organization-user-register-button .btn-outline-success:hover {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.cx-organization-user-register-button .btn-outline-success:focus, .cx-organization-user-register-button .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}
.cx-organization-user-register-button .btn-outline-success.disabled, .cx-organization-user-register-button .btn-outline-success:disabled {
  color: #38871f;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-success:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-success:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38871f;
  border-color: #38871f;
}
.cx-organization-user-register-button .btn-outline-success:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 135, 31, 0.5);
}
.cx-organization-user-register-button .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.cx-organization-user-register-button .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.cx-organization-user-register-button .btn-outline-info:focus, .cx-organization-user-register-button .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.cx-organization-user-register-button .btn-outline-info.disabled, .cx-organization-user-register-button .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-info:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-info:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.cx-organization-user-register-button .btn-outline-info:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.cx-organization-user-register-button .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.cx-organization-user-register-button .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.cx-organization-user-register-button .btn-outline-warning:focus, .cx-organization-user-register-button .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.cx-organization-user-register-button .btn-outline-warning.disabled, .cx-organization-user-register-button .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-warning:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.cx-organization-user-register-button .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.cx-organization-user-register-button .btn-outline-danger {
  color: #db0002;
  border-color: #db0002;
}
.cx-organization-user-register-button .btn-outline-danger:hover {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.cx-organization-user-register-button .btn-outline-danger:focus, .cx-organization-user-register-button .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}
.cx-organization-user-register-button .btn-outline-danger.disabled, .cx-organization-user-register-button .btn-outline-danger:disabled {
  color: #db0002;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-danger:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #db0002;
  border-color: #db0002;
}
.cx-organization-user-register-button .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 0, 2, 0.5);
}
.cx-organization-user-register-button .btn-outline-light {
  color: #f1f1f1;
  border-color: #f1f1f1;
}
.cx-organization-user-register-button .btn-outline-light:hover {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.cx-organization-user-register-button .btn-outline-light:focus, .cx-organization-user-register-button .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
.cx-organization-user-register-button .btn-outline-light.disabled, .cx-organization-user-register-button .btn-outline-light:disabled {
  color: #f1f1f1;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-light:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-light:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
.cx-organization-user-register-button .btn-outline-light:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
.cx-organization-user-register-button .btn-outline-dark {
  color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-outline-dark:hover {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-outline-dark:focus, .cx-organization-user-register-button .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
.cx-organization-user-register-button .btn-outline-dark.disabled, .cx-organization-user-register-button .btn-outline-dark:disabled {
  color: #6c7079;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-dark:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #6c7079;
  border-color: #6c7079;
}
.cx-organization-user-register-button .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 112, 121, 0.5);
}
.cx-organization-user-register-button .btn-outline-primary-accent {
  color: #055f9f;
  border-color: #055f9f;
}
.cx-organization-user-register-button .btn-outline-primary-accent:hover {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.cx-organization-user-register-button .btn-outline-primary-accent:focus, .cx-organization-user-register-button .btn-outline-primary-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}
.cx-organization-user-register-button .btn-outline-primary-accent.disabled, .cx-organization-user-register-button .btn-outline-primary-accent:disabled {
  color: #055f9f;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-primary-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-primary-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-primary-accent.dropdown-toggle {
  color: #fff;
  background-color: #055f9f;
  border-color: #055f9f;
}
.cx-organization-user-register-button .btn-outline-primary-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-primary-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-primary-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 95, 159, 0.5);
}
.cx-organization-user-register-button .btn-outline-success-accent {
  color: #f0fbe4;
  border-color: #f0fbe4;
}
.cx-organization-user-register-button .btn-outline-success-accent:hover {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.cx-organization-user-register-button .btn-outline-success-accent:focus, .cx-organization-user-register-button .btn-outline-success-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}
.cx-organization-user-register-button .btn-outline-success-accent.disabled, .cx-organization-user-register-button .btn-outline-success-accent:disabled {
  color: #f0fbe4;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-success-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-success-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-success-accent.dropdown-toggle {
  color: #212529;
  background-color: #f0fbe4;
  border-color: #f0fbe4;
}
.cx-organization-user-register-button .btn-outline-success-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-success-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-success-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 251, 228, 0.5);
}
.cx-organization-user-register-button .btn-outline-danger-accent {
  color: #fff1f1;
  border-color: #fff1f1;
}
.cx-organization-user-register-button .btn-outline-danger-accent:hover {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.cx-organization-user-register-button .btn-outline-danger-accent:focus, .cx-organization-user-register-button .btn-outline-danger-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}
.cx-organization-user-register-button .btn-outline-danger-accent.disabled, .cx-organization-user-register-button .btn-outline-danger-accent:disabled {
  color: #fff1f1;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-danger-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-danger-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-danger-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff1f1;
  border-color: #fff1f1;
}
.cx-organization-user-register-button .btn-outline-danger-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-danger-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-danger-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 241, 241, 0.5);
}
.cx-organization-user-register-button .btn-outline-warning-accent {
  color: #fff5df;
  border-color: #fff5df;
}
.cx-organization-user-register-button .btn-outline-warning-accent:hover {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.cx-organization-user-register-button .btn-outline-warning-accent:focus, .cx-organization-user-register-button .btn-outline-warning-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}
.cx-organization-user-register-button .btn-outline-warning-accent.disabled, .cx-organization-user-register-button .btn-outline-warning-accent:disabled {
  color: #fff5df;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-warning-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-warning-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-warning-accent.dropdown-toggle {
  color: #212529;
  background-color: #fff5df;
  border-color: #fff5df;
}
.cx-organization-user-register-button .btn-outline-warning-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-warning-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-warning-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 245, 223, 0.5);
}
.cx-organization-user-register-button .btn-outline-info-accent {
  color: #deeffe;
  border-color: #deeffe;
}
.cx-organization-user-register-button .btn-outline-info-accent:hover {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.cx-organization-user-register-button .btn-outline-info-accent:focus, .cx-organization-user-register-button .btn-outline-info-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}
.cx-organization-user-register-button .btn-outline-info-accent.disabled, .cx-organization-user-register-button .btn-outline-info-accent:disabled {
  color: #deeffe;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-info-accent:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-info-accent:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-info-accent.dropdown-toggle {
  color: #212529;
  background-color: #deeffe;
  border-color: #deeffe;
}
.cx-organization-user-register-button .btn-outline-info-accent:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-info-accent:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-info-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 239, 254, 0.5);
}
.cx-organization-user-register-button .btn-outline-medium {
  color: #d3d6db;
  border-color: #d3d6db;
}
.cx-organization-user-register-button .btn-outline-medium:hover {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.cx-organization-user-register-button .btn-outline-medium:focus, .cx-organization-user-register-button .btn-outline-medium.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}
.cx-organization-user-register-button .btn-outline-medium.disabled, .cx-organization-user-register-button .btn-outline-medium:disabled {
  color: #d3d6db;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-medium:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-medium:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-medium.dropdown-toggle {
  color: #212529;
  background-color: #d3d6db;
  border-color: #d3d6db;
}
.cx-organization-user-register-button .btn-outline-medium:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-medium:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-medium.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 214, 219, 0.5);
}
.cx-organization-user-register-button .btn-outline-background-dark {
  color: #212738;
  border-color: #212738;
}
.cx-organization-user-register-button .btn-outline-background-dark:hover {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.cx-organization-user-register-button .btn-outline-background-dark:focus, .cx-organization-user-register-button .btn-outline-background-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}
.cx-organization-user-register-button .btn-outline-background-dark.disabled, .cx-organization-user-register-button .btn-outline-background-dark:disabled {
  color: #212738;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-background-dark:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-background-dark:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-background-dark.dropdown-toggle {
  color: #fff;
  background-color: #212738;
  border-color: #212738;
}
.cx-organization-user-register-button .btn-outline-background-dark:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-background-dark:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-background-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 39, 56, 0.5);
}
.cx-organization-user-register-button .btn-outline-visual-focus {
  color: #6d9df7;
  border-color: #6d9df7;
}
.cx-organization-user-register-button .btn-outline-visual-focus:hover {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.cx-organization-user-register-button .btn-outline-visual-focus:focus, .cx-organization-user-register-button .btn-outline-visual-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}
.cx-organization-user-register-button .btn-outline-visual-focus.disabled, .cx-organization-user-register-button .btn-outline-visual-focus:disabled {
  color: #6d9df7;
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-visual-focus:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-visual-focus:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-visual-focus.dropdown-toggle {
  color: #212529;
  background-color: #6d9df7;
  border-color: #6d9df7;
}
.cx-organization-user-register-button .btn-outline-visual-focus:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-visual-focus:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-visual-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 157, 247, 0.5);
}
.cx-organization-user-register-button .btn-outline-background-focus {
  color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.cx-organization-user-register-button .btn-outline-background-focus:hover {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.cx-organization-user-register-button .btn-outline-background-focus:focus, .cx-organization-user-register-button .btn-outline-background-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}
.cx-organization-user-register-button .btn-outline-background-focus.disabled, .cx-organization-user-register-button .btn-outline-background-focus:disabled {
  color: rgba(80, 176, 244, 0.1);
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-background-focus:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-background-focus:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-background-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(80, 176, 244, 0.1);
  border-color: rgba(80, 176, 244, 0.1);
}
.cx-organization-user-register-button .btn-outline-background-focus:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-background-focus:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-background-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 176, 244, 0.5);
}
.cx-organization-user-register-button .btn-outline-border-focus {
  color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.cx-organization-user-register-button .btn-outline-border-focus:hover {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.cx-organization-user-register-button .btn-outline-border-focus:focus, .cx-organization-user-register-button .btn-outline-border-focus.focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}
.cx-organization-user-register-button .btn-outline-border-focus.disabled, .cx-organization-user-register-button .btn-outline-border-focus:disabled {
  color: rgba(166, 204, 232, 0.6);
  background-color: transparent;
}
.cx-organization-user-register-button .btn-outline-border-focus:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-outline-border-focus:not(:disabled):not(.disabled).active, .show > .cx-organization-user-register-button .btn-outline-border-focus.dropdown-toggle {
  color: #212529;
  background-color: rgba(166, 204, 232, 0.6);
  border-color: rgba(166, 204, 232, 0.6);
}
.cx-organization-user-register-button .btn-outline-border-focus:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-outline-border-focus:not(:disabled):not(.disabled).active:focus, .show > .cx-organization-user-register-button .btn-outline-border-focus.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 204, 232, 0.5);
}
.cx-organization-user-register-button .btn-link, .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cx-organization-user-register-button a.link,
.cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cx-organization-user-register-button button.link {
  font-weight: "normal";
  color: #1f7bc0;
  text-decoration: none;
}
.cx-organization-user-register-button .btn-link:hover, .cx-organization-user-register-button cx-org-list a.link:hover, cx-org-list .cx-organization-user-register-button a.link:hover,
.cx-organization-user-register-button cx-org-list button.link:hover,
cx-org-list .cx-organization-user-register-button button.link:hover {
  color: #14517e;
  text-decoration: underline;
}
.cx-organization-user-register-button .btn-link:focus, .cx-organization-user-register-button cx-org-list a.link:focus, cx-org-list .cx-organization-user-register-button a.link:focus,
.cx-organization-user-register-button cx-org-list button.link:focus,
cx-org-list .cx-organization-user-register-button button.link:focus, .cx-organization-user-register-button .btn-link.focus, .cx-organization-user-register-button cx-org-list a.focus.link, cx-org-list .cx-organization-user-register-button a.focus.link,
.cx-organization-user-register-button cx-org-list button.focus.link,
cx-org-list .cx-organization-user-register-button button.focus.link {
  text-decoration: underline;
}
.cx-organization-user-register-button .btn-link:disabled, .cx-organization-user-register-button cx-org-list a.link:disabled, cx-org-list .cx-organization-user-register-button a.link:disabled,
.cx-organization-user-register-button cx-org-list button.link:disabled,
cx-org-list .cx-organization-user-register-button button.link:disabled, .cx-organization-user-register-button .btn-link.disabled, .cx-organization-user-register-button cx-org-list a.disabled.link, cx-org-list .cx-organization-user-register-button a.disabled.link,
.cx-organization-user-register-button cx-org-list button.disabled.link,
cx-org-list .cx-organization-user-register-button button.disabled.link {
  color: #6c757d;
  pointer-events: none;
}
.cx-organization-user-register-button .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.cx-organization-user-register-button .btn-sm, .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cx-organization-user-register-button a.link,
.cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cx-organization-user-register-button button.link, .cx-organization-user-register-button cx-org-list button.button, cx-org-list .cx-organization-user-register-button button.button,
.cx-organization-user-register-button cx-org-list a.button,
cx-org-list .cx-organization-user-register-button a.button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.cx-organization-user-register-button .btn-block, .cx-organization-user-register-button a {
  display: block;
  width: 100%;
}
.cx-organization-user-register-button .btn-block + .btn-block, .cx-organization-user-register-button a + .btn-block, .cx-organization-user-register-button .btn-block + a, .cx-organization-user-register-button a + a {
  margin-top: 0.5rem;
}
.cx-organization-user-register-button input[type=submit].btn-block,
.cx-organization-user-register-button input[type=reset].btn-block,
.cx-organization-user-register-button input[type=button].btn-block {
  width: 100%;
}
.cx-organization-user-register-button button:focus {
  outline: none;
}
.cx-organization-user-register-button button.link {
  background-color: transparent;
  border-style: none;
  padding: 0;
  cursor: pointer;
}
.cx-organization-user-register-button button.link:disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.8;
}
.cx-organization-user-register-button .btn, .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cx-organization-user-register-button a.link,
.cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cx-organization-user-register-button button.link, .cx-organization-user-register-button cx-org-list button.button, cx-org-list .cx-organization-user-register-button button.button,
.cx-organization-user-register-button cx-org-list a.button,
cx-org-list .cx-organization-user-register-button a.button {
  font-size: 1.188rem;
  font-weight: var(--cx-font-weight-bold);
  text-transform: var(--cx-text-transform);
  line-height: 1;
  padding: 12px 24px;
  min-height: 48px;
  min-width: 48px;
  border-radius: var(--cx-buttons-border-radius);
}
.cxFeat_a11yImproveContrast .cx-organization-user-register-button .btn, .cxFeat_a11yImproveContrast .cx-organization-user-register-button a,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button button.link, .cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list button.button, cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button button.button {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}

.cx-organization-user-register-button .btn-sm, .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cx-organization-user-register-button a.link,
.cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cx-organization-user-register-button button.link, .cx-organization-user-register-button cx-org-list button.button, cx-org-list .cx-organization-user-register-button button.button,
.cx-organization-user-register-button cx-org-list a.button,
cx-org-list .cx-organization-user-register-button a.button {
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  max-height: 40px;
  min-width: 40px;
}
.cx-organization-user-register-button .btn-lg {
  height: 55px;
  max-height: 55px;
  min-width: 55px;
}
.cx-organization-user-register-button .btn-primary, .cx-organization-user-register-button cx-org-list a.button.primary, cx-org-list .cx-organization-user-register-button a.button.primary,
.cx-organization-user-register-button cx-org-list a.button.primary.disabled,
cx-org-list .cx-organization-user-register-button a.button.primary.disabled,
.cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary,
cx-org-list .cx-organization-user-register-button button.button.primary,
.cx-organization-user-register-button cx-org-list button.button.primary.disabled,
cx-org-list .cx-organization-user-register-button button.button.primary.disabled,
.cx-organization-user-register-button cx-org-list button.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:active:not(:disabled):not(.disabled) {
  background-color: var(--cx-color-primary);
  border: 3px solid var(--cx-color-primary);
}
.cx-organization-user-register-button .btn-primary:hover, .cx-organization-user-register-button cx-org-list a.button.primary:hover, cx-org-list .cx-organization-user-register-button a.button.primary:hover,
.cx-organization-user-register-button cx-org-list a.button.primary:hover:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:hover:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary:hover,
cx-org-list .cx-organization-user-register-button button.button.primary:hover,
.cx-organization-user-register-button cx-org-list button.button.primary:hover:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:hover:active:not(:disabled):not(.disabled) {
  background-color: var(--cx-color-primary-accent);
  border-color: var(--cx-color-primary-accent);
}
.cx-organization-user-register-button .btn-primary.active, .cx-organization-user-register-button cx-org-list a.active.button.primary, cx-org-list .cx-organization-user-register-button a.active.button.primary,
.cx-organization-user-register-button cx-org-list a.active.button.primary:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.active.button.primary:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.active.button.primary,
cx-org-list .cx-organization-user-register-button button.active.button.primary,
.cx-organization-user-register-button cx-org-list button.active.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.active.button.primary:active:not(:disabled):not(.disabled) {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  -webkit-filter: brightness(calc(88 / 100));
  filter: brightness(calc(88 / 100));
}
.cxFeat_a11yImproveContrast .cx-organization-user-register-button .btn-primary.active, .cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list a.active.button.primary, cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button a.active.button.primary,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list button.active.button.primary,
cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button button.active.button.primary,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list button.active.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button button.active.button.primary:active:not(:disabled):not(.disabled) {
  filter: none;
}

.cx-organization-user-register-button .btn-primary.disabled,
.cx-organization-user-register-button cx-org-list a.disabled.button.primary,
cx-org-list .cx-organization-user-register-button a.disabled.button.primary,
.cx-organization-user-register-button cx-org-list a.disabled.button.primary:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.disabled.button.primary:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.disabled.button.primary,
cx-org-list .cx-organization-user-register-button button.disabled.button.primary,
.cx-organization-user-register-button cx-org-list button.disabled.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.disabled.button.primary:active:not(:disabled):not(.disabled), .cx-organization-user-register-button .btn-primary:disabled, .cx-organization-user-register-button cx-org-list a.button.primary:disabled, cx-org-list .cx-organization-user-register-button a.button.primary:disabled,
.cx-organization-user-register-button cx-org-list a.button.primary:disabled:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:disabled:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary:disabled,
cx-org-list .cx-organization-user-register-button button.button.primary:disabled,
.cx-organization-user-register-button cx-org-list button.button.primary:disabled:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:disabled:active:not(:disabled):not(.disabled) {
  background-color: var(--cx-color-border-focus);
  border: var(--cx-color-border-focus);
}
.cx-organization-user-register-button .btn-primary.disabled:hover, .cx-organization-user-register-button cx-org-list a.disabled.button.primary:hover, cx-org-list .cx-organization-user-register-button a.disabled.button.primary:hover,
.cx-organization-user-register-button cx-org-list button.disabled.button.primary:hover,
cx-org-list .cx-organization-user-register-button button.disabled.button.primary:hover,
.cx-organization-user-register-button cx-org-list button.disabled.button.primary:hover:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.disabled.button.primary:hover:active:not(:disabled):not(.disabled), .cx-organization-user-register-button .btn-primary:disabled:hover, .cx-organization-user-register-button cx-org-list a.button.primary:disabled:hover, cx-org-list .cx-organization-user-register-button a.button.primary:disabled:hover,
.cx-organization-user-register-button cx-org-list button.button.primary:disabled:hover,
cx-org-list .cx-organization-user-register-button button.button.primary:disabled:hover,
.cx-organization-user-register-button cx-org-list button.button.primary:disabled:hover:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:disabled:hover:active:not(:disabled):not(.disabled) {
  border-color: var(--cx-color-border-focus);
}
.cx-organization-user-register-button .btn-primary:focus, .cx-organization-user-register-button cx-org-list a.button.primary:focus, cx-org-list .cx-organization-user-register-button a.button.primary:focus,
.cx-organization-user-register-button cx-org-list a.button.primary:focus:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button a.button.primary:focus:not(:disabled):not(.disabled),
.cx-organization-user-register-button cx-org-list button.button.primary:focus,
cx-org-list .cx-organization-user-register-button button.button.primary:focus,
.cx-organization-user-register-button cx-org-list button.button.primary:focus:active:not(:disabled):not(.disabled),
cx-org-list .cx-organization-user-register-button button.button.primary:focus:active:not(:disabled):not(.disabled) {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-inverse);
  box-shadow: 0 0 0 0.2rem var(--cx-color-primary);
}
.cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled):active, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled):active,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled):active,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled).active, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled).active, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled).active,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled).active,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled).active {
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  -webkit-filter: brightness(calc(88 / 10));
  filter: brightness(calc(88 / 10));
}
.cxFeat_a11yImproveContrast .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled):active, .cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled):active, cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled):active,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled):active,
cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled):active, .cxFeat_a11yImproveContrast .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled).active, .cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled).active, cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled).active,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled).active,
cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled).active {
  background-color: var(--cx-color-primary-accent);
  filter: none;
}

.cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled):active:focus, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled):active:focus,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled):active:focus,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled).active:focus, .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled).active:focus, cx-org-list .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled).active:focus,
.cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled).active:focus,
cx-org-list .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--cx-color-primary), 0.5);
}
.cx-theme-high-contrast-dark .cx-organization-user-register-button .btn-primary, .cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list a.button.primary, cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button a.button.primary,
.cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled),
cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled),
.cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.button.primary,
cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.button.primary,
.cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.button.primary:active:not(:disabled):not(.disabled),
cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.button.primary:active:not(:disabled):not(.disabled) {
  color: var(--cx-color-medium);
}
.cx-theme-high-contrast-dark .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled):active, cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled):active,
.cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled):active,
cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-dark .cx-organization-user-register-button .btn-primary:not(:disabled):not(.disabled).active, .cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list a.button.primary:not(:disabled):not(.disabled).active, cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button a.button.primary:not(:disabled):not(.disabled).active,
.cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.button.primary:not(:disabled):not(.disabled).active,
cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.button.primary:not(:disabled):not(.disabled).active {
  color: var(--cx-color-medium);
}
.cx-organization-user-register-button .btn-secondary, .cx-organization-user-register-button a, .cx-organization-user-register-button cx-org-list button.button.secondary, cx-org-list .cx-organization-user-register-button button.button.secondary,
.cx-organization-user-register-button cx-org-list a.button.secondary,
cx-org-list .cx-organization-user-register-button a.button.secondary {
  color: var(--cx-color-primary);
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-primary);
  border-width: var(--cx-buttons-border-width);
}
.cx-organization-user-register-button .btn-secondary:hover, .cx-organization-user-register-button a:hover, .cx-organization-user-register-button cx-org-list button.button.secondary:hover, cx-org-list .cx-organization-user-register-button button.button.secondary:hover,
.cx-organization-user-register-button cx-org-list a.button.secondary:hover,
cx-org-list .cx-organization-user-register-button a.button.secondary:hover {
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-primary-accent);
  color: var(--cx-color-primary-accent);
}
.cx-organization-user-register-button .btn-secondary.active, .cx-organization-user-register-button a.active, .cx-organization-user-register-button cx-org-list button.active.button.secondary, cx-org-list .cx-organization-user-register-button button.active.button.secondary,
.cx-organization-user-register-button cx-org-list a.active.button.secondary,
cx-org-list .cx-organization-user-register-button a.active.button.secondary {
  background-color: var(--cx-color-secondary);
  border-color: var(--cx-color-secondary);
  -webkit-filter: brightness(calc(84 / 100));
  filter: brightness(calc(84 / 100));
}
.cx-theme-high-contrast-dark .cx-organization-user-register-button .btn-secondary.active, .cx-theme-high-contrast-dark .cx-organization-user-register-button a.active, .cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.active.button.secondary, cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.active.button.secondary {
  color: var(--cx-color-medium);
  border-color: var(--cx-color-medium);
}
.cx-organization-user-register-button .btn-secondary.disabled, .cx-organization-user-register-button a.disabled, .cx-organization-user-register-button cx-org-list button.disabled.button.secondary, cx-org-list .cx-organization-user-register-button button.disabled.button.secondary,
.cx-organization-user-register-button cx-org-list a.disabled.button.secondary,
cx-org-list .cx-organization-user-register-button a.disabled.button.secondary, .cx-organization-user-register-button .btn-secondary:disabled, .cx-organization-user-register-button a:disabled, .cx-organization-user-register-button cx-org-list button.button.secondary:disabled, cx-org-list .cx-organization-user-register-button button.button.secondary:disabled,
.cx-organization-user-register-button cx-org-list a.button.secondary:disabled,
cx-org-list .cx-organization-user-register-button a.button.secondary:disabled {
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-border-focus);
  color: var(--cx-color-border-focus);
}
.cx-organization-user-register-button .btn-secondary.disabled:hover, .cx-organization-user-register-button a.disabled:hover, .cx-organization-user-register-button cx-org-list button.disabled.button.secondary:hover, cx-org-list .cx-organization-user-register-button button.disabled.button.secondary:hover, .cx-organization-user-register-button .btn-secondary:disabled:hover, .cx-organization-user-register-button a:disabled:hover, .cx-organization-user-register-button cx-org-list button.button.secondary:disabled:hover, cx-org-list .cx-organization-user-register-button button.button.secondary:disabled:hover {
  border-color: var(--cx-color-border-focus);
}
.cx-organization-user-register-button .btn-secondary:focus, .cx-organization-user-register-button a:focus, .cx-organization-user-register-button cx-org-list button.button.secondary:focus, cx-org-list .cx-organization-user-register-button button.button.secondary:focus,
.cx-organization-user-register-button cx-org-list a.button.secondary:focus,
cx-org-list .cx-organization-user-register-button a.button.secondary:focus {
  box-shadow: 0 0 0 0.2em var(--cx-color-border-focus);
  background-color: var(--cx-color-inverse);
  color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  outline-color: var(--cx-color-visual-focus);
}
.cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button a:not(:disabled):not(.disabled):active, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled):active, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled):active, .cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled).active, .cx-organization-user-register-button a:not(:disabled):not(.disabled).active, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled).active, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled).active {
  background-color: var(--cx-color-inverse);
  border-color: var(--cx-color-primary-accent);
  color: var(--cx-color-primary-accent);
}
.cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button a:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled):active:focus, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled):active:focus, .cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled).active:focus, .cx-organization-user-register-button a:not(:disabled):not(.disabled).active:focus, .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled).active:focus, cx-org-list .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2em rgba(var(--cx-color-primary), 0.5);
}
.cx-theme-high-contrast-dark .cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-dark .cx-organization-user-register-button a:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled):active, cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled):active, .cx-theme-high-contrast-dark .cx-organization-user-register-button .btn-secondary:not(:disabled):not(.disabled).active, .cx-theme-high-contrast-dark .cx-organization-user-register-button a:not(:disabled):not(.disabled).active, .cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.button.secondary:not(:disabled):not(.disabled).active, cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.button.secondary:not(:disabled):not(.disabled).active {
  color: var(--cx-color-medium);
  border-color: var(--cx-color-medium);
}
.cx-theme-high-contrast-dark .cx-organization-user-register-button .btn-secondary, .cx-theme-high-contrast-dark .cx-organization-user-register-button a, .cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list button.button.secondary, cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button button.button.secondary,
.cx-theme-high-contrast-dark .cx-organization-user-register-button cx-org-list a.button.secondary,
cx-org-list .cx-theme-high-contrast-dark .cx-organization-user-register-button a.button.secondary {
  color: var(--cx-color-medium);
  border-color: var(--cx-color-medium);
}
.cx-organization-user-register-button .btn-link, .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cx-organization-user-register-button a.link,
.cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cx-organization-user-register-button button.link {
  font-size: 1.125rem;
  color: var(--cx-color-text);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
.cx-organization-user-register-button .btn-link:focus, .cx-organization-user-register-button cx-org-list a.link:focus, cx-org-list .cx-organization-user-register-button a.link:focus,
.cx-organization-user-register-button cx-org-list button.link:focus,
cx-org-list .cx-organization-user-register-button button.link:focus {
  border-radius: 4px;
}
.cx-organization-user-register-button .btn-link .focus, .cx-organization-user-register-button cx-org-list a.link .focus, cx-org-list .cx-organization-user-register-button a.link .focus,
.cx-organization-user-register-button cx-org-list button.link .focus,
cx-org-list .cx-organization-user-register-button button.link .focus {
  border-radius: 4px;
}
.cxFeat_a11yImproveContrast .cx-organization-user-register-button .btn-link, .cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list a.link, cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button a.link,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button cx-org-list button.link,
cx-org-list .cxFeat_a11yImproveContrast .cx-organization-user-register-button button.link {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  color: var(--cx-color-primary);
}

.cx-organization-user-register-button .btn-tertiary {
  color: var(--cx-color-primary);
  padding: 12px 16px;
  background-color: var(--cx-color-transparent);
  border-style: none;
  font-size: var(--cx-font-size, 1rem);
  width: auto;
}
.cxFeat_a11yImproveContrast .cx-organization-user-register-button .btn-tertiary {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}

.cx-organization-user-register-button .btn-tertiary:hover {
  background-color: var(--cx-color-transparent);
  color: var(--cx-color-primary-accent);
}
.cx-organization-user-register-button .btn-tertiary.active {
  color: var(--cx-color-primary);
  background-color: var(--cx-color-transparent);
}
.cx-organization-user-register-button .btn-tertiary:focus {
  box-shadow: 0 0 0 0.2em var(--cx-color-visual-focus);
  background-color: var(--cx-color-transparent);
  color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  outline-color: var(--cx-color-visual-focus);
}
.cx-organization-user-register-button .btn-tertiary.disabled {
  -webkit-filter: brightness(calc(88 / 10));
  filter: brightness(calc(88 / 10));
  color: var(--cx-color-border-focus);
}
.cx-organization-user-register-button .cx-toggle-button {
  position: absolute;
  top: 10px;
  left: 100%;
}
.cx-organization-user-register-button .cx-toggle-button *,
.cx-organization-user-register-button .cx-toggle-button *:before,
.cx-organization-user-register-button .cx-toggle-button *:after {
  box-sizing: border-box;
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox]:checked ~ label .cx-toggle-switch {
  background-color: var(--cx-color-primary);
  border: 1px solid var(--cx-color-primary);
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox]:checked ~ label .cx-toggle-switch:before {
  content: " ";
  left: 0;
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox]:checked ~ label .cx-toggle-switch:after {
  content: " ";
  border: 2px solid var(--cx-color-inverse);
  background-color: var(--cx-color-inverse);
  transform: translate3d(22px, 0, 0);
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox][disabled] ~ label,
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox][disabled] ~ label .cx-toggle-switch {
  pointer-events: none;
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox][disabled]:checked ~ label .cx-toggle-switch {
  background-color: var(--cx-color-light);
  border: 1px solid var(--cx-color-light);
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox][disabled]:checked ~ label .cx-toggle-text {
  color: var(--cx-color-light);
}
.cx-organization-user-register-button .cx-toggle-button label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.cx-organization-user-register-button .cx-toggle-button label .cx-toggle-switch {
  position: relative;
  height: 32px;
  flex: 0 0 56px;
  border-radius: 60px;
  border: 1px solid var(--cx-color-secondary);
  background-color: var(--cx-color-inverse);
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
}
.cx-organization-user-register-button .cx-toggle-button label .cx-toggle-switch:before {
  content: " ";
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
  left: 28px;
  font-size: 12px;
  line-height: 32px;
  width: 28px;
  padding: 0 12px;
}
.cx-organization-user-register-button .cx-toggle-button label .cx-toggle-switch:after {
  content: " ";
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  transform: translate3d(0, 0, 0);
  top: 3px;
  left: 3px;
  border-radius: 30px;
  width: 25px;
  line-height: 20px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  border: 2px solid var(--cx-color-secondary);
  background-color: var(--cx-color-secondary);
  z-index: 1;
}
.cx-organization-user-register-button .cx-toggle-button label .cx-toggle-text {
  flex: 1;
  padding-inline-start: 0.35rem;
  color: var(--cx-color-secondary);
}
.cx-organization-user-register-button .cx-toggle-button input[type=checkbox]:focus ~ label .cx-toggle-switch {
  box-shadow: 0 0 2px 3px var(--cx-color-visual-focus);
}
.cx-organization-user-register-button button:focus,
.cx-organization-user-register-button .btn:focus,
.cx-organization-user-register-button a:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast .cx-organization-user-register-button button:focus,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button .btn:focus,
.cxFeat_a11yImproveContrast .cx-organization-user-register-button a:focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows .cx-organization-user-register-button button:focus,
.cxFeat_a11yVisibleFocusOverflows .cx-organization-user-register-button .btn:focus,
.cxFeat_a11yVisibleFocusOverflows .cx-organization-user-register-button a:focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button button:focus,
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button .btn:focus,
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button button:focus .ng-select-container,
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button .btn:focus .ng-select-container,
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button button:focus[type=radio],
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button .btn:focus[type=radio],
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button button:focus > cx-media,
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button .btn:focus > cx-media,
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

.cx-organization-user-register-button a:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast .cx-organization-user-register-button a:focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows .cx-organization-user-register-button a:focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus[type=radio] {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus .cx-organization-user-register-button a:focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

cx-user-registration-form form {
  width: 50%;
  margin: 0 auto;
}
cx-user-registration-form form label,
cx-user-registration-form form textarea,
cx-user-registration-form form button {
  margin-bottom: 0.675rem;
}
@media (max-width: 767.98px) {
  cx-user-registration-form form {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document {
    max-width: 100%;
    padding: 0;
  }
}
cx-account-summary-document .cx-account-summary-document-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-top: 1px solid var(--cx-color-light);
  border-bottom: 1px solid var(--cx-color-light);
}
cx-account-summary-document .cx-account-summary-document-table th {
  padding: 1.5rem 0 1.125rem 0;
  text-align: start;
}
cx-account-summary-document .cx-account-summary-document-table th:last-child {
  text-align: end;
}
cx-account-summary-document .cx-account-summary-document-table td {
  padding: 1.625rem 0;
}
@media (min-width: 768px) {
  cx-account-summary-document .cx-account-summary-document-table td {
    text-align: start;
  }
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-account-summary-document .cx-account-summary-document-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-account-summary-document .cx-account-summary-document-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
cx-account-summary-document .cx-account-summary-document-header {
  padding: 40px 0 0 0;
  color: var(--cx-color-text);
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-header {
    padding-top: 40px;
    padding-inline-end: 20px;
    padding-bottom: 0;
    padding-inline-start: 20px;
  }
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-thead-mobile {
    display: none;
  }
}
cx-account-summary-document .cx-account-summary-document-code .cx-account-summary-document-value {
  text-decoration: none;
}
cx-account-summary-document .cx-account-summary-document-type .cx-account-summary-document-value {
  text-decoration: none;
}
cx-account-summary-document .cx-account-summary-document-attachment .cx-account-summary-document-value {
  text-decoration: underline;
  text-align: end;
  color: var(--cx-color-primary);
}
cx-account-summary-document .cx-account-summary-document-date {
  text-align: center;
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-date {
    text-align: start;
  }
}
cx-account-summary-document .cx-account-summary-document-status {
  text-align: center;
  text-transform: capitalize;
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-status {
    text-align: start;
  }
}
cx-account-summary-document .cx-account-summary-document-status .status-open {
  color: var(--cx-color-success);
}
cx-account-summary-document .cx-account-summary-document-status .status-closed {
  color: var(--cx-color-secondary);
}
cx-account-summary-document .cx-account-summary-document-monetary {
  text-align: end;
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-monetary {
    text-align: start;
  }
}
cx-account-summary-document .cx-account-summary-document-label {
  color: var(--cx-color-secondary);
}
@media (min-width: 992px) {
  cx-account-summary-document .cx-account-summary-document-label {
    display: none;
  }
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 200px;
    min-height: 48px;
  }
}
cx-account-summary-document .cx-account-summary-document-value {
  color: var(--cx-color-text);
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    font-weight: "normal";
  }
}
cx-account-summary-document .cx-account-summary-document-form-group {
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  width: 40%;
}
cx-account-summary-document .cx-account-summary-document-form-group span {
  margin-inline-end: 0.5rem;
  flex: none;
}
cx-account-summary-document .cx-account-summary-document-form-group cx-sorting {
  width: 100%;
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-form-group {
    padding: 1.25rem;
    width: 100%;
  }
}
cx-account-summary-document .cx-account-summary-document-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-sort.top {
    flex-direction: column;
    padding-top: 0;
  }
}
cx-account-summary-document .cx-account-summary-document-sort.bottom {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-account-summary-document .cx-account-summary-document-sort.bottom {
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-pagination {
    margin: 0 auto;
  }
}
cx-account-summary-document .cx-account-summary-document-no-document {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: "normal";
  min-height: 415px;
  padding-top: 2rem;
  border-top: 1px solid var(--cx-color-light);
  justify-content: center;
}
@media (max-width: 767.98px) {
  cx-account-summary-document .cx-account-summary-document-no-document {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  cx-account-summary-document .cx-account-summary-document-row:nth-child(odd) {
    background-color: var(--cx-color-background);
  }
}
cx-account-summary-document .cx-account-summary-document-attachment-btn {
  color: var(--cx-color-primary);
  background-color: inherit;
  border: 0;
}
cx-account-summary-document .cx-account-summary-document-attachment-icon {
  font-size: 1.4rem;
}
cx-account-summary-document .cx-account-summary-document-attachment-text {
  font-weight: var(--cx-font-weight-bold);
  padding-inline-start: 0.5rem;
}
@media (min-width: 992px) {
  cx-account-summary-document .cx-account-summary-document-attachment-text {
    display: none;
  }
}

cx-account-summary-document-filter .cx-account-summary-document-filter-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
cx-account-summary-document-filter .cx-account-summary-document-filter-form-item {
  flex-direction: column;
  flex-basis: 25%;
  padding: 0.5rem;
}
@media (max-width: 991.98px) {
  cx-account-summary-document-filter .cx-account-summary-document-filter-form-item {
    flex-basis: 50%;
    padding: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  cx-account-summary-document-filter .cx-account-summary-document-filter-form-item {
    flex-basis: 100%;
    padding: 0.5rem;
  }
}
cx-account-summary-document-filter .cx-account-summary-document-filter-form-input {
  padding: 0.5rem;
  width: 100%;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--cx-color-light);
  border-radius: 4px;
}
cx-account-summary-document-filter .cx-account-summary-document-filter-form-button-block {
  flex-basis: 100%;
  justify-content: flex-end;
  margin-inline-end: 0.5rem;
  margin-bottom: 2rem;
  align-self: center;
  display: flex;
  flex-direction: row;
}
cx-account-summary-document-filter .cx-account-summary-document-filter-form-button-block .clear-btn {
  margin-inline-end: 1rem;
  border: none;
  background-color: inherit;
  font-size: var(--cx-font-size, 1rem);
}

cx-account-summary-header .cx-account-summary {
  background-color: var(--cx-color-background);
  margin-top: 1.5rem;
}
@media (max-width: 991.98px) {
  cx-account-summary-header .cx-account-summary {
    background-color: var(--cx-color-inverse);
  }
}
cx-account-summary-header .cx-account-summary .cx-account-summary-header-cards {
  display: flex;
  flex-direction: row;
  padding: 15px 0;
}
@media (max-width: 991.98px) {
  cx-account-summary-header .cx-account-summary .cx-account-summary-header-cards {
    flex-direction: column;
    padding: 0 1.25rem;
  }
}
cx-account-summary-header .cx-account-summary .cx-account-summary-header-cards .cx-summary-card {
  padding: 0 15px;
}
@media (max-width: 991.98px) {
  cx-account-summary-header .cx-account-summary .cx-account-summary-header-cards .cx-summary-card {
    background-color: var(--cx-color-inverse);
    border-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0.625rem 0;
  }
}
cx-account-summary-header .cx-account-summary .cx-account-summary-header-cards .cx-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
@media (max-width: 991.98px) {
  cx-account-summary-header .cx-summary-card-responsive-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  cx-account-summary-header .cx-summary-card-responsive-group > * {
    flex-basis: 49%;
  }
}
@media (max-width: 767.98px) {
  cx-account-summary-header .cx-summary-card-responsive-group {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  cx-account-summary-header .cx-summary-card-group-unit-address {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  cx-account-summary-header .cx-summary-card-group-credit-balance {
    flex: 2;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
  }
  cx-account-summary-header .cx-summary-card-group-credit-balance > * {
    flex-basis: 50%;
  }
}
@media (min-width: 992px) {
  cx-account-summary-header .cx-summary-card-group-due {
    flex: 1;
  }
}
cx-account-summary-header .cx-account-summary-header-past-due-section {
  border: 1px solid var(--cx-color-text);
  padding: 1rem;
  margin-top: 1rem;
}
@media (min-width: 992px) {
  cx-account-summary-header .cx-account-summary-header-past-due-section {
    margin-inline-end: 20px;
  }
}
@media (max-width: 991.98px) {
  cx-account-summary-header .cx-account-summary-header-past-due-section {
    border: none;
  }
}
cx-account-summary-header .cx-account-summary-header-past-due-range-dates {
  border-bottom: 1px solid var(--cx-color-text);
}
cx-account-summary-header .cx-account-summary-header-past-due-range-collection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}
cx-account-summary-header .cx-account-summary-header-past-due-range-collection .cx-past-due-range-date {
  font-weight: var(--cx-font-weight-bold);
}
cx-account-summary-header .cx-account-summary-header-past-due-balance-total {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1rem;
}
cx-account-summary-header .cx-account-summary-header-past-due-balance-total .cx-past-due-balance-label {
  font-size: 1.125rem;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

cx-unit-level-order-overview .cx-order-summary {
  background-color: var(--cx-color-background);
}
@media (max-width: 767.98px) {
  cx-unit-level-order-overview .cx-order-summary {
    background-color: var(--cx-color-inverse);
  }
}
cx-unit-level-order-overview .cx-order-summary .container {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0;
}
@media (max-width: 991.98px) {
  cx-unit-level-order-overview .cx-order-summary .container {
    max-width: 100%;
    min-width: 100%;
    padding: 0 1.25rem;
  }
}
@media (max-width: 767.98px) {
  cx-unit-level-order-overview .cx-order-summary .container {
    flex-direction: column;
    padding: 1.25rem;
  }
}
cx-unit-level-order-overview .cx-order-summary .container .cx-summary-card {
  flex: 1;
  padding: 0 15px;
}
@media (min-width: 992px) {
  cx-unit-level-order-overview .cx-order-summary .container .cx-summary-card:not(:last-of-type) {
    border-inline-end: 1px solid var(--cx-color-text);
  }
}
@media (max-width: 991.98px) {
  cx-unit-level-order-overview .cx-order-summary .container .cx-summary-card {
    flex: 0 0 33%;
  }
}
@media (max-width: 767.98px) {
  cx-unit-level-order-overview .cx-order-summary .container .cx-summary-card {
    flex: 1;
    background-color: var(--cx-color-inverse);
    border-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0.625rem 0;
  }
}
cx-unit-level-order-overview .cx-order-summary .container .cx-summary-card .cx-card-title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
cx-unit-level-order-overview .cx-order-summary .container .cx-summary-card .cx-display-inline .cx-card-label-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
}

@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history {
    max-width: 100%;
    padding: 0;
  }
}
cx-unit-level-order-history div.search-icon {
  display: block;
}
cx-unit-level-order-history label.dirty div.search-icon {
  display: none;
}
cx-unit-level-order-history label:not(.dirty) button.reset {
  display: none;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-label-wrapper {
  width: 200px;
  border: 1px solid var(--cx-color-secondary);
  border-radius: 3px;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-input {
  border: none;
  height: 100%;
  margin-bottom: 0;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-input:focus {
  background: var(--cx-color-inverse);
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-reset-button {
  border: none;
  color: var(--cx-color-secondary);
  background: var(--cx-color-inverse);
  padding-inline-end: 10px;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-label {
  display: flex;
  align-content: stretch;
  margin-bottom: 0;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-div-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-filter-div-wrapper {
    display: none;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-div-wrapper span {
  font-weight: var(--cx-font-weight-semi);
}
cx-unit-level-order-history .cx-unit-level-order-history-table {
  padding: 1.5rem 0 1.125rem 0;
  margin-bottom: 0;
  border-top: 1px solid var(--cx-color-light);
  border-bottom: 1px solid var(--cx-color-light);
}
cx-unit-level-order-history .cx-unit-level-order-history-table th {
  padding: 1.5rem 0 1.125rem 0;
  text-align: start;
}
cx-unit-level-order-history .cx-unit-level-order-history-table th:last-child {
  text-align: end;
}
cx-unit-level-order-history .cx-unit-level-order-history-table td {
  width: 16.6%;
  padding: 1rem 0;
}
@media (min-width: 768px) {
  cx-unit-level-order-history .cx-unit-level-order-history-table td {
    text-align: start;
    max-width: 1px;
  }
  cx-unit-level-order-history .cx-unit-level-order-history-table td:last-child {
    text-align: end;
  }
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-table td {
    width: 100%;
    display: flex;
    border-width: 0;
    padding: 0 1.25rem;
  }
  cx-unit-level-order-history .cx-unit-level-order-history-table td:first-child {
    padding-top: 1.25rem;
  }
  cx-unit-level-order-history .cx-unit-level-order-history-table td:last-child {
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 768px) {
  cx-unit-level-order-history .cx-unit-level-order-history-table td .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-table td span {
  color: var(--cx-color-secondary);
  display: block;
}
cx-unit-level-order-history .cx-unit-level-order-history-header {
  padding: 40px 0 0 0;
  color: var(--cx-color-text);
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-header {
    padding-top: 40px;
    padding-inline-end: 20px;
    padding-bottom: 0;
    padding-inline-start: 20px;
  }
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-thead-mobile {
    display: none;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-code .cx-unit-level-order-history-value {
  text-decoration: underline;
  color: var(--cx-color-primary);
}
cx-unit-level-order-history .cx-unit-level-order-history-placed {
  text-align: center;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-placed {
    text-align: start;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-unit,
cx-unit-level-order-history .cx-unit-level-order-history-buyer,
cx-unit-level-order-history .cx-unit-level-order-history-status {
  text-align: center;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-unit,
  cx-unit-level-order-history .cx-unit-level-order-history-buyer,
  cx-unit-level-order-history .cx-unit-level-order-history-status {
    text-align: start;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-total {
  text-align: end;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-total {
    text-align: start;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-label {
  text-transform: uppercase;
  color: var(--cx-color-secondary);
}
@media (min-width: 768px) {
  cx-unit-level-order-history .cx-unit-level-order-history-label {
    display: none;
  }
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-label {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    min-width: 110px;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-value {
  color: var(--cx-color-text);
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-value {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    font-weight: "normal";
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-form-group {
  padding: 0;
  margin-bottom: 0;
  align-items: center;
  display: flex;
  width: 40%;
}
cx-unit-level-order-history .cx-unit-level-order-history-form-group span {
  margin-inline-end: 0.5rem;
  flex: none;
}
cx-unit-level-order-history .cx-unit-level-order-history-form-group cx-sorting {
  width: 100%;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-form-group {
    padding: 1.25rem;
    width: 100%;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-sort {
  align-items: center;
}
cx-unit-level-order-history .cx-unit-level-order-history-sort.top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-sort.top {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-sort.bottom {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  margin: 0;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-sort.bottom {
    flex-direction: column;
    align-items: flex-start;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-pagination {
  margin-inline-start: auto;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-pagination {
    margin: 0 auto;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-total-result {
  font-style: italic;
  color: var(--cx-color-secondary);
  padding-inline-start: 1.25rem;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-total-result {
    padding-bottom: 1rem;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-total-result cx-total {
  display: flex;
  column-gap: 0.5rem;
}
cx-unit-level-order-history .cx-unit-level-order-history-total-result cx-total div.cx-vertical-line-separator {
  border-color: var(--cx-color-light);
  border-inline-start-style: solid;
  border-inline-start-width: thin;
}
cx-unit-level-order-history .cx-unit-level-order-history-no-order {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: "normal";
  min-height: 415px;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-no-order {
    min-height: 474px;
    padding-inline-start: 1.25rem;
    padding-inline-end: 1.25rem;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-no-order .btn, cx-unit-level-order-history .cx-unit-level-order-history-no-order cx-org-list a.link, cx-org-list cx-unit-level-order-history .cx-unit-level-order-history-no-order a.link,
cx-unit-level-order-history .cx-unit-level-order-history-no-order cx-org-list button.link,
cx-org-list cx-unit-level-order-history .cx-unit-level-order-history-no-order button.link, cx-unit-level-order-history .cx-unit-level-order-history-no-order cx-org-list button.button, cx-org-list cx-unit-level-order-history .cx-unit-level-order-history-no-order button.button,
cx-unit-level-order-history .cx-unit-level-order-history-no-order cx-org-list a.button,
cx-org-list cx-unit-level-order-history .cx-unit-level-order-history-no-order a.button, cx-unit-level-order-history .cx-unit-level-order-history-no-order .cx-organization-user-register-button a, .cx-organization-user-register-button cx-unit-level-order-history .cx-unit-level-order-history-no-order a {
  margin: 1.25rem 0;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-form-mobile {
  display: none;
  flex-flow: row wrap;
  justify-content: flex-start;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-form-mobile .cx-clear-filter {
  flex-basis: 100%;
  justify-content: flex-end;
  margin-top: 2rem;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-form-mobile .cx-selected-filters {
  flex-basis: 100%;
  justify-content: flex-start;
  margin-top: 2rem;
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-filter-form-mobile {
    flex-basis: 100%;
    padding: 1rem;
    display: flex;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-filters-show-and-remove {
  display: flex;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-form-button-block {
  flex-basis: 100%;
  justify-content: flex-end;
  margin-inline-end: 0.5rem;
  margin-bottom: 2rem;
  align-self: center;
  display: flex;
  flex-direction: row;
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-form-button-block .cx-clear-btn {
  margin-inline-end: 2.125rem;
  border: none;
  background-color: inherit;
  font-size: var(--cx-font-size, 1rem);
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-filter-form-button-block {
    display: none;
  }
}
cx-unit-level-order-history .cx-unit-level-order-history-filter-form-item {
  flex-direction: column;
  flex-basis: 25%;
  padding: 0.5rem;
}
@media (max-width: 991.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-filter-form-item {
    flex-basis: 50%;
    padding: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  cx-unit-level-order-history .cx-unit-level-order-history-filter-form-item {
    flex-basis: 100%;
    padding: 0.5rem;
    display: none;
  }
}
cx-unit-level-order-history .cx-filter-list {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
cx-unit-level-order-history .cx-filter-list button[type=button] {
  background-color: transparent;
  border: none;
  font-size: var(--cx-font-size, 1.5rem);
  margin: 0;
}
cx-unit-level-order-history .cx-filter {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  background-color: var(--cx-color-background);
  overflow-x: hidden;
  top: 0;
  padding: 10px;
  display: none;
  flex-direction: column;
  font-weight: var(--cx-font-weight-semi);
}
cx-unit-level-order-history .cx-filter-list-border {
  border-bottom: 1px solid var(--cx-color-light);
}
cx-unit-level-order-history .cx-clear-btn {
  margin-inline-end: 2.125rem;
  border: none;
  background-color: inherit;
  font-size: var(--cx-font-size, 1rem);
}
cx-unit-level-order-history #cx-unit-level-order-history-filter-nav-sub-unit {
  display: none;
}
cx-unit-level-order-history #cx-unit-level-order-history-filter-nav-sub-buyer {
  display: none;
}
cx-unit-level-order-history .search-icon-filter {
  display: block;
  padding: 10px;
  padding-top: 15px;
  color: var(--cx-color-secondary);
}
cx-unit-level-order-history .cx-filter-nav {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  background-color: var(--cx-color-background);
  overflow-x: hidden;
  top: 0;
}
@media (min-width: 992px) {
  cx-unit-level-order-history .cx-filter-nav {
    display: none;
  }
}
@media (min-width: 768px) {
  cx-unit-level-order-history .cx-filter-nav {
    display: none;
  }
}
cx-unit-level-order-history .cx-filter-nav .cx-filter-nav-div {
  padding: 10px;
  box-shadow: 0 4px 2px -2px var(--cx-color-secondary);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
cx-unit-level-order-history .cx-filter-nav .cx-clear-btn-container {
  display: flex;
  justify-content: flex-end;
}
cx-unit-level-order-history .cx-filter-nav .cx-filter-back-button {
  display: flex;
  align-items: center;
}
cx-unit-level-order-history .cx-filter-nav label {
  display: flex;
  align-content: stretch;
  background: var(--cx-color-inverse);
  padding-bottom: 0;
  border: 1px solid var(--cx-color-secondary);
  margin: 0 1rem 0 1rem;
}
cx-unit-level-order-history .cx-filter-nav input {
  border: none;
  height: 100%;
  margin: 0px 11px 1px 11px;
}
cx-unit-level-order-history .cx-filter-nav input:focus {
  background-color: var(--cx-color-inverse);
}
cx-unit-level-order-history .cx-filter-nav input::placeholder {
  color: var(--cx-color-secondary);
  font-weight: "light";
  font-size: var(--cx-font-size, 1rem);
}