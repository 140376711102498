cx-page-layout.ErrorPageTemplate cx-page-slot{
    max-width: 100%;
    margin: 0;
}
cx-page-layout.ErrorPageTemplate cx-page-slot.notFound1{
    app-errorpage-content .alert{
        display: none;
    }
}
cx-page-layout.ErrorPageTemplate {
    padding-bottom: 0;
}
.ErrorPageTemplate {
    div.LO_errorText1 {
        font-family: 'Campton-Medium';
        font-size: 32px;
        line-height: 40px;
        color: #2A3237;
        text-align: center;
        margin-bottom: 30px;
    }
    div.LO_errorText2 {
        font-family: 'Campton-Medium';
        font-size: 20px;
        line-height: 28px;
        color: #5D6F7B;
        text-align: center;
    }
    div.LO_errorTextParent {
        padding: 180px;
    }
    @media only screen and (max-width: 991px) {
        div.LO_errorText1 {
            white-space: nowrap;
            font-size: 28px;
            width: 45%;
        }
        div.LO_errorText2 {
            width: 40%;
            white-space: nowrap;
        }
        div.LO_errorTextParent {
            padding: 60px 10px;
        }
    }
}
.ErrorPageTemplate {
    div.LI_errorText1 {
        font-family: 'Campton-Medium';
        font-size: 32px;
        line-height: 40px;
        color: #2A3237;
        text-align: start;
        margin-bottom: 30px;
    }
    div.LI_errorText2 {
        font-family: 'Campton-Medium';
        font-size: 20px;
        line-height: 28px;
        color: #5D6F7B;
        text-align: start;
    }
    div.LI_errorTextParent {
        padding: 120px 60px;
    }
    @media only screen and (max-width: 991px) {
        div.LI_errorTextParent {
            padding: 60px 10px;
        }
        div.LI_errorText1 {
            font-size: 28px;
        }
    }
}