.FinancialGuidanceNewPageTemplate {
    #alertlogin {
        position: static;
    }
    .container{
        padding-left: 10px;
        padding-right: 10px;
    }
    .container:before{
        content: " ";
        display: table;
    
    }
    .text--grey-110 span {
        color: #ea181b;
        font-weight: bold;
        font-family: "Campton-Bold", sans-serif;
    }
.SeqirusFinancialGuidanceFirst-Slot{
    .container a, .container span{
        display: inline;
    }
	a {
        .global_greybtn{
		    background-color: #3c9a90;
	    }
        .global_greybtn:hover{
            color: #3c9a90;
            background-color: #ffffff;
            border: 1px solid #3c9a90;
        }
    }
    .breadcrumbs-opeff{
        display: none;
    }
    .row-flex{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .hero--circlechart .hero--circlechart-content .rectangle-down {
        top:35px !important;
        left: 0px !important;
    }
    .hero--circlechart { 
        @media (max-width: 393px){
            margin-bottom: 40px !important;
        }
		.col-md-8 {
            @media(max-width:1023px) {
                height: auto !important;
            }
        }
	}
    .hero--circlechart-content{
        padding-left: 10px;
        @media (max-width: 393px){
        padding-right: 10px;
        }
        @media (max-width: 767px) {
            width: 100% !important;
            padding-left: 0px !important;
            margin-top: 0px;
            top: 0px !important;
        }
        @media (max-width: 768px){
            width: 80%;
            margin-bottom: 20px;
            top: 0px !important;
            div h1 {
                font-size: 36px;
            }
            
            .rectangle-down {
                top: 30px !important;
                width: 16px;
                height: 16px;
            }
            .content-container {
                padding-left: 15px !important;
            }
        }
    }
    @media (max-width: 990px) {
        .breadcrumbs--page-nav {
            top: 0px;
            margin-bottom: 10px;
            
        }
        .breadcrumbs--page-nav p{
            padding-top:12px;
            line-height: 24px;
            padding-left: 0px !important;
        }
        @media (max-width: 990px) {
            .hero--circlechart .hero--circlechart-mobile img {
                left: 20%;
                width: 60%;
                margin: 0 !important;
                position: relative;
            }
        }
    }
    @media (max-width: 1023px) {
        .breadcrumbs--page-nav p {
           margin-left: 15px;
       }
   }
    @media (min-width: 1024px) {
        .breadcrumbs--page-nav {
            top:2em;
        }
        .hero--circlechart .hero--circlechart-desktop img {
            bottom: 2px !important;
        }
    }
    .breadcrumbs--page-nav{
        background: transparent;
        padding-left: 0px;
        p{
            padding-left: 45px;
        }
    }
    .hero--circlechart-content{
        padding-left: 10px;
        padding-top: 3em;
        p{
            color:#5D6F7B;
            font-size: 16px;
        }
    }
    .content-container a{
       // display: none !important;
    }
    .content-container h1{ margin-top: 20px !important;}
    @media (min-width: 1025px) {
        .content-container h1{
            font-size: 40px !important;
            //margin-top: 5px !important;
        }
    }
    
}
.seqirusFinancialGuidanceSecond-Slot{
    .flu360-dashboard-header-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .container {
        width: 100%;
        max-width: 1650px;
    }
    h2{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .top-text {
        width: 73%;
    }
    .large--carousel-btn-container{
        display: inline-flex;
        flex-direction: row;
        margin-bottom: 50px;
        padding-left: 6%;
    }
    .slide--content{
        display:inline-flex !important;
        width: 100%;
    }
    .mobile-carousel-inner{
        padding: 0 15px;
    .slide--content{
        display:inline-block !important;
        width:100%;
        margin: 0 0 35px 0;
    }
    .carousel-item{
        width:100% !important;
    } 
    }
    .left-column{
        max-width: 40%;
        padding-right: 0px;
        padding-left: 60px;
        p {
            a{
                font-family: "Campton-Bold", sans-serif;
                color: #5d6f7b;
                text-decoration: underline !important;
                
            }

        }
    }
    .bottom-row{
        p a{
            font-family: "Campton-Bold", sans-serif;
                color: #5d6f7b;
                text-decoration: underline !important;
        }
    }
    
    .right-column{
        padding-left: 0;
        max-width: 60%;
        .carousel-inner {
            max-width: 77%;
        }
    }
    .carousel-inner .carousel-item{
        max-width: 1214px !important;
    }
    .flu360-dashboard-header-row {
        margin-top: 20px;
        margin-bottom: 20px;
        @media (max-width: 1023px) {
            margin-top: 0px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
        }
        @media (max-width: 393px) {
            width: 100%;
            margin-top: 0;
        }
        .text-center{
            @media only screen and (min-width: 768px) and (max-width: 1023px){
                max-width: 76%;
            }
            @media (max-width: 767px){
                max-width: 100%;
            }
            @media (min-width: 1024px) {
                right: 0.4%;
            }
        }
        h2 {
            @media (max-width: 393px) {
                margin-top: 0;
                font-size: 28px;
                line-height: 32px;
            }
        }
        p {
            width: 70%;
            font-size: 16px;
            line-height: 24px;
            margin-left: auto;
            margin-right: auto;
            @media (max-width: 400px) {
                width: 100%;
            }
        }
        @media (min-width: 1025px) {
            p {
                font-size: 18px;
                line-height: 27px;
            }
        }

    }
    #customCarousel .custom-carousel-control-next,#customCarousel .custom-carousel-control-prev{
        padding: 0 !important;
    }
    .mobile-carousel-inner .bottom-row 
    {
        p{
            width:76% !important;
        }
        p:first-child{
        font-family: "Campton-Medium", sans-serif;
        color: #2a3237;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
        margin-top: 10px;
        
        }
        .paragraph--1-book {
            font-family: "Campton-Book", sans-serif;
            font-size: 18px;
            line-height: 27px;
            color: #5d6f7b;
        }
    }
    .mobile-carousel-btn-container {
        @media (max-width: 1023px) {
            // top: -12px;
            top: -22px;
            z-index: 2;
            margin-left: 0;
            margin-right: 0;
            left: -21;
            position: relative;
            justify-content: center;
            width: 320px;
            margin: 0px auto;
            display: flex;
            .custom-carousel-control-prev{
                position: absolute;
                left: 6%;
                padding-left: 0px;
            }
            .custom-carousel-control-next{
                position: absolute;
                right: 6%;
                left: auto;
            }
        }
        .carousel-indicators{
            position: absolute;
            bottom: -30px;
            left: 70%;
            width: 70px;
            margin-left: -30%;
            padding-left: 0;
        }

    }
    @media (max-width: 393px) {
        .top-row img {
            max-width: 93% !important;
            width: 86% !important;
            margin-right: 11% !important;
            left: 0%;
        }
    }
    .top-row img{
        width: 60%;
    }
    
}
.seqirusFinancialGuidanceMultipleNeed-Slot{
    .whatif--card {
        padding: 40px 42px 30px 40px !important;
    }
    #what-if-row{
        margin: 0 !important;
    }
    @media (min-width: 1025px) {
        #multiple-needs-header {
            margin-top: 62px;
            margin-bottom: 34px;
            h2{
            font-size: 32px !important;
            line-height: 40px !important;
            position: relative;
            right: 1%;
        }
    }
        .whatif--card {
            width: 637px !important;
            height: 259px !important;
        }
        .section-spacing{
            margin-bottom: 50px !important;
        
        }
    }
    @media(min-width:768px){
        .op-center-content{
            flex: 0 0 100%;
            max-width: 100%;
       }
   }
    @media(min-width:1024px){
        .op-center-content{
            flex: 0 0 50%;
            max-width: 50%;
            padding-right: 30px;
            padding-left: 30px;
            padding-top: 1%;
       }
       .callout, .calloutteal {
            font-size: 16px;
       }
   }
    .text--teal-100{
        color: #5d6f7b !important;
        
    }
    .callout,.calloutteal{
        margin-top: 30px !important;
    }
    @media (max-width: 393px) {
        #multiple-needs-header{
                    max-width: 100%;
                    padding: 0 15px;
                    margin-top: 60px;
                    h2{
                        width: 93%;
                        
                    }
        }
    }
    @media (max-width: 1023px) {
        .center-content {
            padding-left: 0;
            padding-right: 30px;
            padding-bottom: 10%;
        }
        #multiple-needs-header h2 br{display:none;}
        
        .whatif--card { 
            padding: 0 !important;
        }
        
        #multiple-needs-header {
            max-width: 100%;
            h2 {
                width: 80%;
            }
        }
    }
    @media (max-width: 393px) {
        .whatif--card {
            width: 335px;
        }
    }
    @media (max-width: 768px) {
        .whatif--card {
            height: auto;
        }
        
        .whatif--card .whatif--card-content {
            padding: 45px 18px 50px 24px;
            width: 96%;

            .whatif--teal-card-header, .whatif--card-header {
                font-size: 20px;
                line-height: 26px;
            }
            .content {
                font-size: 18px;
                line-height: 27px;
            }
        }
    }
    @media (max-width: 370px) {
        .whatif--card .whatif--card-content {
            padding: 45px 27px 50px 27px;
        }
    }
    .whatif--card-header{
        font-size: 24px;
        line-height: 31px;
        min-height: 30px;
    }
    .whatif--card .content{
        font-size: 16px;
        line-height: 24px;
        @media (max-width: 1200px) {
            width:106%;
        }
    }
}
.SeqirusFinancialGuidanceFourth-Slot{
#join360-row{
    background-color: #8bd2cf !important;
}
.col-12{
    padding: 0px;
}   
.join360-row-bg1{
    background-color: #79b7b6 !important;
}
.join360-row-bg2{
    background-color: #3c9a90 !important;
}
.header {
    color: #3c9a90 !important;
    margin-bottom: 10px;
    width: 300px;
}
@media (min-width: 1024px) {
    .header{
        font-size: 42px !important;
    }
}

    .paragraph {
        width: 306px;
    }
    .button-container{
        a {
            .global_redbtn {
                border: 1.5px solid #EA181b;
            }
            .global_greybtn {
                border: 1px solid #5d6f7b;
                color: #2a3237;
                &:hover{
                    background-color: #2a3237;
                    color: #ffffff;
                }
            }
        }
    }
    .join360--hero-container--content{
        margin-left: 2% !important;
    }
    @media (max-width: 393px) {
        .join360--hero-container--content {
           // margin-left: 8% !important;
            padding-left: 0 !important;
        }
                div .global_dashheader {
            font-size: 20px;
        }
    }
    @media (max-width: 1023px) {
        .join360--hero-container--content {
            margin-left: 11% !important;
            padding-top: 30px !important;
            
        }
    }
    @media screen and (max-width: 768px) {
        .join360--hero-container--content .content{
            color: #3c9a90 !important;
        }
    }
    @media (max-width: 1023px) {
        #join360-footer #join360-row {
            height: 352px !important;
        }
    }
    @media (max-width: 1023px) {
        #join360-footer #join360-row .join360--hero-container{
            height: 331px !important;
        }
    }
}
.container{
    max-width: 1650px !important;
    width: 100%;
}
.SeqirusFinancialGuidanceThird-Slot{
    .global_headerwithdash{
        margin-top: 4em !important;
        margin-bottom: 30px;
        padding:0 22px;
    }
    .global_dashheader{
        font-size: 24px;
    }
    // .home_resources{
        // padding:0 15px;
        // margin: 0 !important;
    // }
    .home_resources:nth-of-type(2) {
        margin-left: 2% !important;
        margin-right: 2% !important;
      }
      .home_desktopresourcessection{
        padding-left: 15px;
          padding-right: 15px;
      }
    .home_resourceimg{
        margin-bottom: 21px !important;
    }
	@media (min-width: 1024px) {
	#home_resourcessection {
        height: 100%;
        padding-top: 21px;
        margin-top: 2.5%;

    .global_line{
		height: 2px;
        background: #aeb7bd;
        opacity: 0.5;
	    margin-top: 14px;
	    margin-left: 1.5%;
	}
    }
    }
    @media (max-width: 1023px) {
        .home_resourcescontent {
            .home_resourcelink {
                a .global_blackarrow {
                    left: 23%;
                    top: 23%;
                }
            }
        }
    }
    @media (min-width: 393px) and (max-width: 450px) {
        div .global_dashheader {
            font-size: 32px;
        }
    }
	#home_resourcessection{
		padding: 50px 1%;
		padding-bottom:0 !important;
	}
    .home_desktopresourcessection{
        margin-bottom: 3em;
    }
    .home_resourcescontent{
        width: 100%;
		.home_resourceheader{
			font-size: 14px;
		}
        .home_resourcelink{
            a:hover{
                text-decoration: underline !important;
            }
            border-top: 1px solid #eef0f1;
            height: 60px;
            .global_blackarrow{
                right: 20px;
                width: 28px;
                height: 28px;
                margin-right: 31px;
                top: 24%;
            }
        }
        .global_headerwithdash{
            margin-bottom: 30px;
        }
		.home_resourcesubheader{
			font-size: 24px;
			line-height: 31px;
		}
		.home_resourcetext{
			font-size: 16px;
			line-height: 24px;
            
		}
		@media (min-width: 1025px) {
			.home_resourceheader{
				line-height: 27px;
			}
		}
	}
    .global_greybtncontainer{
        padding-right: 46px;
        margin-bottom: 10px !important;
        .global_greybtn{
            font-family: "Campton-SemiBold", sans-serif;
            border: 1px solid #5d6f7b;
            padding: 17px 29px 17px 29px;
            outline: none;
        }
    }
}

}
.FinancialGuidanceNewPageTemplate{
    letter-spacing: normal !important;
}
