:root{
--camptonBook: "Campton-Book";
--camptonmedium:"Campton-Medium";
--camptonsemibold:"Campton-SemiBold";
--camptonbold:"Campton-Bold";
--camptonBookitalic:"Campton-BookItalic";
--camptonextrabold:"Campton-ExtraBold";
--camptonextrabolditalic:"Campton-ExtraBoldItalic";
--camptonextralight:"Campton-ExtraLight";
--camptonmediumitalic:"Campton-MediumItalic";
--camptonSemiBoldItalic:"Campton-SemiBoldItalic";

}

