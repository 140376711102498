.InfleunzaNewPageTemplate {
    #influenza-burden-hero {
        .line {
            padding-left: 15px;
        }

        a {
            padding-left: 15px;
        }

        margin-bottom: 0px !important;
    }

    .cell-image {
        display: block !important;
        margin-left: -9%;

        img {
            margin-top: 4%;
        }
    }


    .influenza-callout-desktop {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .small-callout strong {
            font-family: "Campton-Bold", sans-serif;
            font-weight: bold;
        }
    }

    @media(min-width: 1024px) {
        #influenza-hero .influenza-hero-container {
            margin-top: 60px !important;
            // max-width: 568px !important;
            // width: 568px;
            // width: 100% !important;
            width: 91% !important;
            max-width: 100% !important;
            // margin-right: auto;
            // margin-left: auto;
        }

        .influenza-breadcrumbs {
            // position: absolute !important;
            z-index: 100;
        }

        .show-br-sm-mobile {
            display: none;
        }
        .influenza-breadcrumbs .container p{
              letter-spacing: 0 !important;
        }
    }
    
    .influenza-hero-container {
        .callout {
            font-family: 'Campton-Medium', sans-serif;
            padding-left: 30px;
            letter-spacing: 0;
        }

        .small-callout {
            margin-left: 30px;
            letter-spacing: 0;
        }
    }

    #influenza-hero .influenza-hero-container {
        .rectangle-down {
            top: 10px;
            left: 9px;
        }

        .rectangle-up {
            left: 286px !important;
            top: -29px !important;
        }
    }

    #influenza-burden {
        .influenza-hero-container .small-callout {
            margin-left: 30px;
            letter-spacing: 0;
        }

        .two-row--card .two-row--card-top {
            padding-top: 30px;
            text-align: center;
            letter-spacing: 0;

            p {
                font-family: "Campton-SemiBold", sans-serif;
                font-size: 24px;
                line-height: 27px;
                color: #5d6f7b;
                letter-spacing: 0;
                margin: 0 0 10px;
            }

            p.card-callout {
                color: #ea181b;
                font-size: 32px;
                line-height: 38px;
                letter-spacing: 0;
            }
        }

        .two-row--card .two-row--card-bottom {
            padding-top: 10px;
            padding-bottom: 20px;
            text-align: center;
            letter-spacing: 0;

            p {
                font-family: "Campton-SemiBold", sans-serif;
                font-size: 24px;
                line-height: 27px;
                color: #5d6f7b;
                text-align: center;
            }

            p.card-callout {
                color: #ea181b;
                font-size: 32px;
                line-height: 38px;
                margin: 0 0 10px;
            }
        }
    }

    #eachyearcomponent {
        .facts-card .card-content p span {
            color: #ea181b;
        }

        sup {
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
            top: -0.5em;
        }
    }

    .influenza-65breadcrumbs {
        display: none;
    }

    .influenza-breadcrumbs {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        // position: relative;
        // position: absolute;
        top: 0px;
        overflow: hidden;
        max-width: 100% !important;
        width: 99%;

        .breadcrumbs--page-nav {
            z-index: 100;
        }

        .breadcrumbs--page-nav p {
            margin-left: 25px;
            font-size: 14px;
            color: #5d6f7b;
            font-family: "Campton-Book", sans-serif;
            letter-spacing: 0;

            strong {
                font-family: "Campton-SemiBold", sans-serif;
                font-weight: bold;
            }
        }
    }

    @media (min-width: 1025px) {
        .breadcrumbs--page-nav p {
            padding-top: 0;
            line-height: 27px;
        }
    }

    @media (min-width: 1200px) {
        #influenza-burden-65plus .container {
            margin-left: auto !important;
            margin-right: auto !important;
            width: 99% !important;
            max-width: 100%;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    @media (max-width: 1300px) {
        #influenza-burden-65plus .hero--overview .hero--overview-content {
            width: 100% !important;
            // padding-bottom: 30px !important;
            padding-top: 8em !important;

            p {
                width: 60% !important;
            }
        }
    }

    #influenza-burden-65plus {
        .hero--overview {
            padding-right: 0px !important;

            .hero--overview-content {
                // padding-top: 8em !important;
                padding-right: 10px;

                img {
                    top: 20px !important;
                }
            }

            .text {
                padding-left: 20px !important;
                letter-spacing: 0;
            }
        }

        .text-black {
            padding-left: 20px;
            margin-left: 0px;
            margin-top: 20px;
        }
    }

    @media (min-width: 1024px) {
        .hero--overview {
            background-position: center !important;
            background-size: cover;
            height: 426px !important;
            // top: -47px !important;
            top: -71px !important;
            clip-path: inset(24px 0 0 0) !important;
        }
        .influenza-breadcrumbs{
            top: 24px !important;
        }
    }

    #influenza-burden .influenza-breadcrumbs {
        top: 0px !important
    }

    .page-references {
        .references-h2content {
            display: none;
        }

        .about-ref {
            margin-bottom: 60px;
            margin-top: 50px;
            margin-right: 20px !important;
            // margin-left: auto !important;
            // margin-right: 20px !important;
            font-size: 16px !important;
            letter-spacing: 0;
            width: 100%;
            max-width: 100%;

            strong {
                font-family: "Campton-Bold", sans-serif;
                font-weight: bold;
            }
        }
    }

    @media (max-width: 1023px) {
        .hero--overview {
            width: 100%;
            background-size: cover;
            background-position-x: 100%;
            // background-position: 30% center;
        }
    }

    .hero--overview-content-mobile .new_header {
        font-weight: 600 !important;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    // @media (max-width: 1023px){
    //     #influenza-hero .influenza-hero-image{
    //         display: none;
    //     }
    // }
    @media(max-width: 1023px) {
        .influenza-callout-desktop .influenza-hero-container {
            width: 100% !important;
            max-width: 100% !important;
        }

        .cell-image img {
            display: none;
        }

        .about-ref {
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    @media(max-width: 600px) {
        #influenza-hero .influenza-hero-container {
            width: 86% !important;
        }
    }

    @media(max-width: 767px) {
        #influenza-hero .influenza-hero-container {
            .rectangle-down {
                left: 12px !important;
                padding-left: 0% !important;
            }

            .rectangle-up {
                left: 262px !important;
            }
        }

        #influenza-hero .influenza-hero-container {
            .callout {
                width: 100% !important;
                max-width: 100% !important;
                // margin-left: 28% !important;
                font-size: 28px !important;
                line-height: 32px !important;
                margin-left: auto !important;
                margin-right: auto !important;
            }

            .small-callout {
                width: 100% !important;
                margin-left: 10% !important;
                // padding-left: 20px !important;
                max-width: 100% !important;
                font-size: 16px !important;
                line-height: 24px !important;
                // margin-left: auto !important;
                margin-right: auto !important;
                // padding-left: 0 !important;
            }
        }

        .pl-sm-mobile {
            margin-bottom: 10%;
        }
    }

    @media(min-width: 768px) and (max-width: 1023px) {
        #influenza-hero .influenza-hero-container {
            .callout {
                margin-left: auto !important;
                margin-right: auto !important;
                padding-left: 0px !important;
            }

            .small-callout {
                margin-left: auto !important;
                margin-right: auto !important;
            }

            .rectangle-down {
                margin-left: 18%;
                padding-left: 0px !important;
            }

            .rectangle-up {
                margin-left: 18%;
            }

        }

        .hero--overview-content-mobile .rectangle-down {
            left: 10% !important;
        }
    }

    @media (max-width: 1023px) {
        #influenza-hero .influenza-hero-image {
            width: 100% !important;
        }
    }

    @media (max-width: 1023px) {
        h3.line {
            width: 100% !important;
            font-size: 28px !important;
            line-height: 32px !important;
            margin-top: -19px !important;
        }
    }

    #mobileeachyearcomponent .facts-card .card-content p span {
        color: #ea181b;
    }

    @media(min-width:1023px) {
        .hide-desktop {
            display: none;
        }
    }

    #influenza-hero .image-col {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    #influenza-burden-hero .line {
        padding-left: 15px !important;
        letter-spacing: 0 !important;
    }

    #influenza-burden-hero a {
        padding-left: 15px !important;
        letter-spacing: 0 !important;
    }

    #influenza-burden-hero a {
        text-transform: capitalize;
    }
    .alertbox{
   position:  relative !important;
    
}
.page-references{
    margin-top: 50px !important;
    margin-bottom: 60px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
@media (min-width: 1024px) {
    .page-references {
        padding-left: 20px !important; 
        padding-right: 20px !important;
    }       
    .hero--overview .hero--overview-content{
        padding-left: 1.5% !important;
    }
}
.page-references .container{
    width: 100%;
    max-width: 100%;
}
@media (min-width: 1200px) {
    .page-references .container {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 99% !important;
    }
}
.page-references strong {
    font-family: "Campton-Bold", sans-serif !important;
}
.page-references .reference{
    padding-left: 10px !important;
    letter-spacing: 0 !important;
    font-size: 16px !important;
}
// #eachyearcomponent{
//     #influenza-results-header h2{
//       margin-top: 0 !important;
//     }
// }
@media (min-width: 1024px) {
.influenza-results-container{
    position: relative;
    top: -72px !important;
}
#influenza-burden #influnenza-season-header h2{
    margin-top: -1.5% !important;
}
}
}

.influenza-first-component {
    .mobile-text1 span {
        color: #ea181b;
        font-family: "Campton-Bold";
    }
    strong{
        font-family: "Campton-SemiBold", sans-serif;

    }
}
