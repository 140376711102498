.SeqirusAboutPageUKFirst-Slot {

    // .rectangle-down {
    //     top: 100px !important;
    //     right: 32px !important;
    // }

    app-about-section1 {

      .about-us {
        width: 121% !important;
      }
      .hero--overview-content {
        // @media (min-width: 1024px){
        //   padding-left: 3%;
        // }
  
        @media (max-width: 1300px){
          width: 40% !important;
        }
  
        @media (min-width: 1300px){
          width: 47% !important;
        }
      }
  
      .hero--overview {
        // @media (min-width: 1024px){
        //   height: 650px !important;
        // }
  
        @media (min-width: 1300px){
          height: 596px !important;
        }
      }
    }
}

.SeqirusAboutPageUKThird-Slot {
  // app-about-vaccine {
  //   .carousel-inner {
  //     p.carousel-content {
  //       padding-top: 21% !important;
  //     }
  //   }
  // }

  .active-bar {
    bottom: -5px !important
  }
  // .carousel-bar-line {
  //   top: 255px !important;
  // }

  .influenza-carousel-desktop-btn-container {
    @media (min-width: 1024px) and (max-width: 1150px ){
      margin-top: 24% !important;
    }
    @media (min-width: 1150px) and (max-width: 1250px ){
      margin-top: 22% !important;
    }
    @media (min-width: 1250px) and (max-width: 1400px ){
      margin-top: 20% !important;
    }
    @media (min-width: 1400px){
      margin-top: 16% !important;
    }

    @media (min-width: 1600px){
      margin-top: 12% !important;
    }
  }
}

.SeqirusAboutPageUKFourth-Slot {
  .large--carousel-btn-container {
    margin-top: 40px;
  }

  .carousel-inner .left-column p {
    // width: 33%;
    font-size: 18px;
    line-height: 27px;
  }

  // .page-references .container {
  //   margin-left: 38px;
  // }

  .referencesContainerHomeUK {
    color: #5d6f7b;
  }

  // app-first-comp {
    // img.d-block.w-100 {
    //    @media (min-width: 1024px) and (max-width: 1920px){
    //     margin-top: -160px !important;
    //    }
    // }

    // .downloadLink {
    //   display: block;
    // }
  // }
}

// .SeqirusAboutPageUKFifth-Slot {
//   .page-references .container {
//     margin-left: 38px;
//   }
// }

.SeqirusAboutPageUKSixth-Slot {
  .referencesContainerHomeUK {
    color: #5d6f7b !important;
  }
}

@media (min-width: 1200px) {
  .AboutSeqirusPageTemplate .SeqirusAboutPageUKFirst-Slot {
    .hero--overview {
      // margin-top: -3% !important;
      height: 572px !important;
    }

    /* .hero--overview-content h1.content {
        margin-top: 130px !important;
        margin-left: 20px !important;
    } */
  }
}


.seqirusb2b-uk .AboutSeqirusPageTemplate {
  app-first-comp {
   /*  a {
      margin-left: 4%;
    } */

    // .right-column {
    //   margin-top: -69px;
    // }

    .left-column {
      z-index: 99;
      top: 20px;
    }
  }
  
  app-about-section1 {
    .hero--overview-content {
      // h1.content {
      //   margin-left: -40px !important;
      //   margin-top: 110px !important;
      // }

      .ready-ref {
        padding-left: 15px;
      }

      .about-us {
        margin-bottom: 0px !important;
      }
    }

    .hero--overview {
      .button--hero-solid-red {
        margin-top: 20px !important;
      }

      @media (min-width: 1024px) {
        position:relative;
        background-attachment: local;
        top: 0px;
      }
    }
  }

  app-about-vaccine {
    .carousel-inner {
      .carousel-item {
        .order_class .carousel-content{
          margin-left: 14%;
          width: 72%;
        }
      }
    }

    .link-container {
      a :hover {
        text-decoration: underline !important;
      }
    }
  }
}
.seqirusb2b-uk {
.influenza-carousel-desktop-btn-container{
  padding-top: 10% !important;
}
#influenza-carousel-desktop{
  .row-flex{
    height: 662px !important;
  }
}
#custom-Carousel{
  .large--carousel-btn-container{
    margin-bottom: 0px !important;
  }
}
// .slide--content {
//   padding-bottom: 73px !important; 
// }
// .page-references{
//   margin-top: 8% !important;
// }
.downloadLink{
  display: block !important;
  margin-top: 30px;
}
.supply_chain_carousel_container .supply_chain_carousel{
  min-height: 25rem;
}
//for refernces
.AboutSeqirusPageTemplate {
  .page-references {
    .container {
      max-width: 100% !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .supply_chain_destop_carousel .carousel-inner .right-column img {
    max-width: 77% !important;
    margin-left: 85px !important;
  }

  .page-references {
    margin-top: 5% !important;

    p {
      font-size: 16px;
      line-height: 24px;
    }

    .reference {
      margin-bottom: 30px;
    }
  }
  .referencesContainerHomeUK{
    margin-bottom: 30px !important;
    padding: 0% 20px 0% 20px !important;
    max-width: 100% !important;
    width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
  .PibannerUk{
    position: relative;
  }
}
}
.AboutSeqirusPageTemplate .breadcrumbs--page-nav {
  position: absolute;
  right: 10px !important;
}
.AboutSeqirusPageTemplate {
 p.about-us {
  padding: 15px !important;
  position: relative !important;
  // right: 35px !important;
  // width: 123% !important;
}
.supply_chain_carousel_container{
  max-width: 100% !important;
}
}