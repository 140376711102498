.GigyaEmailVerificationPageTemplate {
    .LeftContentSlot {
        width: 33%;
        float: left;
        min-height: 450px;
        background-color: #F9FAFA;
    }
    .verify_header{
    font-family: campton-medium;
    font-size: 24px !important;
    font-weight: 500 !important;
    letter-spacing: 0;
    color: #4e515e;
    /* line-height: 31px; */
    margin-top: 110px !important;
    text-align: left !important;
    }
    #gigya-verification-sent-screen{
        margin: 35px 50px 90px !important;
    }
    // .headerbar {
    //     border-bottom: 1px solid #ececec;
    // }
    .header{
        border-bottom: 1px solid #ececec;
    }
    .createprofile_helptext{
        .black_arrow_class{
            display: none;
        }
    }
    app-custom-gigya-raas{
        grid-column: 1 / span 2;
    }
    .BodyContent{
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .createprofile_helptext{
        margin-bottom: 0px !important;

    }
    // .BodyContent{
    //     margin-top: 20px;
    // }
    app-custom-user-guide{
        margin-top: 20px;
    }
    .moveupmargin{
        margin-top: 30px;
    }
}