.AccountPageTemplate,.LandingPage5Template {
    .contactyellowlink {
        font-family: "Campton-Book" !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #5D6F7B !important;
        letter-spacing: 0;
        text-decoration: underline !important;
    }

    .contactyellowlink:hover {
        font-family: "Campton-Book" !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #5D6F7B !important;
        letter-spacing: 0;
        text-decoration: none !important;
    }

    .contactyellowlink:active {
        font-family: 'Campton-SemiBold' !important;
    }
}

.uk-Lo-contact-us,.us-Lo-contact-us {

    //for the top-navigation Lo-vesion
    .container-fluid,
    .container-lg {
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    .nav_breadCrumb {
        width: 100%;
    }

    .nav_breadCrumb .breadcrumb {
        padding: 30px 0px !important;
        margin-bottom: 0px !important;
        list-style: none !important;
        border-radius: 0px !important;
        background-color: #fff !important;
    }

    .breadcrumb>li {
        display: inline-block !important;
    }

    .margin-T30 {
        margin-top: 30px !important;
    }

    .global_Lshape {
        position: absolute;
        margin: -10px -15px;
    }

    .global_horizontalL {
        width: 20px;
        height: 4px;
        margin: 0px 0px;
        background-color: #AEB7BD;
    }

    .global_verticalL {
        width: 4px;
        height: 16px;
        margin: 0px 0px;
        background-color: #AEB7BD;
    }

    .Support_contentbox {
        margin-bottom: 18px;
    }

    .Support_header {
        margin-top: 0px;
        font-family: "Campton-Medium";
        margin-bottom: 20px;
        font-size: 40px;
        color: #2a3237;
    }

    @media (min-width: 1025px) {
        h1 {
            // font-size: 40px;
            line-height: 51px;
        }
    }

    .Support_text {
        color: #5d6f7b;
        font-family: "Campton-Book";
        font-size: 24px;
        line-height: 32px;
    }

    .Support_faqButton {
        float: left;
    }

    a,
    a:hover,
    a:focus,
    a:active {
        text-decoration: none;
        color: inherit !important;
    }

    .nav_breadCrumb .breadcrumb>li+li:before {
        content: ">";
        padding: 0px 5px 0px 3px;
        color: #5d6f7b;
    }

    .uk-Lo-contact-us a,
    .uk-Lo-contact-us a:hover,
    .uk-Lo-contact-us a:focus,
    .uk-Lo-contact-us a:active {
        color: #2A3237 !important;
    }

    .nav_breadCrumb li .active {
        font-family: 'Campton-semibold' !important;
    }

    .Support_contentbox .redborder {
        padding: 10px 15px !important;
        margin: 30px 0px !important;
        color: #ea181b !important;
        font-size: 14px !important;
        font-family: "Campton-semibold";
        text-decoration: none;
        background-color: #F9FAFA
    }
    .Support_contentbox .redborder:hover {
        color: #F9FAFA !important;
        background-color: #ea181b !important;
    }

}

.Support_lowerboxsUK {
    .Support_email a,
    .Support_call a {
        text-decoration: underline !important;
    }
}

.contact_midsection {
    .contact_supporttext .global_singleline {
        white-space: nowrap !important;
        font-size: inherit !important;
    }

    .contact_supporttext_spacing {
        letter-spacing: 0;
    }
}

body.loggedin:has(app-general-support) .AccountPageTemplate {
    .grbnumber {
        font-size: 12px !important;
        padding: 30px 60px !important;
        color: #5d6f7b !important;
        font-family: "Campton-Book" !important;
        padding-bottom: 24px !important;
        margin-bottom: 0 !important;
    }
}
body.loggedin:has(app-general-support) .LandingPage5Template {
    .grbnumber {
        font-size: 12px !important;
        padding: 30px 60px !important;
        color: #5d6f7b !important;
        font-family: "Campton-Book" !important;
        padding-bottom: 24px !important;
        margin-bottom: 0 !important;
    }
}

body:not(.loggedin):has(app-general-support) .AccountPageTemplate {
    .grbnumber {
        margin-bottom: 30px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 12px !important;
        color: #5d6f7b !important;
        font-family: "Campton-Book" !important;
        padding-bottom: 24px !important;
    }
}
body:not(.loggedin):has(app-general-support) .LandingPage5Template {
    .grbnumber {
        margin-bottom: 30px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 12px !important;
        color: #5d6f7b !important;
        font-family: "Campton-Book" !important;
        padding-bottom: 24px !important;
    }
}

body:not(.loggedin):has(app-general-support) .AccountPageTemplate .BodyContent {
    width: 100% !important;
}
.us-Lo-contact-us{
    .Support_contentbox {
        margin-bottom: 65px;
        padding: 0 10px;
    }
    .row{
        display: block;
        float: left;
        margin: 0 -10px;
    }
    .Support_contentbox .redborder:hover, .Support_contentbox .redborder:focus {
        background-color: #ea181b;
        text-decoration: none;
        color: #fff !important;
    }
    .breadcrumb .breadcrumb-item{
        padding-left: 0;
    }
    .nav_breadCrumb .breadcrumb>li+li:before {
        padding: 0px 7px 0px 5px;
    }
}
  //for us contact-us styling
body:has(app-contact-us-topbanner) .AccountPageTemplate {
    .alertbox{
       position: relative;
    }
}
body:has(app-contact-us-topbanner) .LandingPage5Template {
    .alertbox{
       position: relative;
    }
    margin-bottom: 40px;
}
   