.fc-day-grid-container {
    height: 10% !important;
    overflow: hidden !important;
  }
  .fc .fc-scroller {
    //height: 10% !important;
    overflow: hidden !important;
  }
  // .fc-button-primary{
  //   display:none !important
  // }

  .fc .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section > td {
    height: 1px;
    //width: auto !important;
    // width: 150px !important;
    margin-bottom: 5px;
    border-bottom:1px solid #5D6F7B;
    border-color: #5D6F7B;
   // margin-left: 10px;
  }
  .fc-theme-standard td, .fc-theme-standard th {
    border: none !important;
  }
  .fc .fc-view-harness{
    height: 219px !important;
  }
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 0 !important;
    /* margin-right: 5px; */
    // position: relative;
  }
  .fc .fc-daygrid-day-top {
   //
    display: flex;
    margin-left: 9px !important;
    flex-direction: row !important;
  }
  .fc .fc-scrollgrid-liquid {
    //height: 100%;
    border: none !important;
  }
  .calendar0 .fc-myCustomPreviousButton1-button {
    box-shadow: none !important;
    background: none !important;
    color: black !important;
    border: none !important;
   // margin-top: -75px !important;
   bottom: 50px;
    color: #2A3237;
      font-family: Campton-semibold;
      font-size: 14px;
      line-height: 21px;
      left: -24px;
      position: relative;
   }
   .calendar0 .fc-myCustomNextButton1-button{
    display: none !important;
   }
   .calendar1 .fc-myCustomPreviousButton1-button{
    display: none !important;
   }
   .calendar1 .fc-myCustomNextButton1-button{
    display: none !important;
   }
   .calendar2 .fc-myCustomPreviousButton-button{
    display: none !important;
   }
   .calendar2 .fc-myCustomNextButton-button {
    box-shadow: none !important;
    background: none !important;
    color: black !important;
    border: none !important;
    margin-top: -104px;
      margin-left: 10px;
      color: #2A3237;
      font-family: Campton-semibold;
      font-size: 14px;
      line-height: 21px;
      right: 46px;
      position: relative;
    //margin-top: -185px !important;
   }
   .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
    width: 22px !important;
  }
  .fc .fc-daygrid-event {
    margin-top: -20px !important;
    margin-right: -6px !important;
    padding-bottom: 6px !important;
    /* padding: 10px; */
   
  }
  .fc .window {
    // background: none !important;
    // border: 2px solid #5D6F7B !important;
    // border-radius: 9.5px !important;
    // opacity: 1 !important;
    // margin-bottom: 0.6rem;
    // margin-top: -0.4rem;
    // margin-left: 6px;
    // margin-right: -7px;
    background: none !important;
    border: 2px solid #5D6F7B !important;
    border-radius: 15px;
    opacity: 1 !important;
    margin-bottom: 0.6rem;
    margin-top: -0.4rem;
    margin-left: 6px;
    margin-right: -7px;
    height: 29px;
   
  }
  .seqirusb2b-uk .fc-scrollgrid-sync-table tr{
     .fc-daygrid-bg-harness {
      top: 3px !important;
     }
    }
     .seqirusb2b-uk  .fc .window {
      margin-bottom: -0.4rem;
    margin-top: -0.15rem;
    margin-left: 3px;
    margin-right: -7px;
    height: 24px;
     }

  


  .fc .schedule {
    margin-left: 2px;
    margin-right: -8px;
    background: none !important;
    border: 2px solid red !important;
    // border-radius: 9.5px !important;
    opacity: 1 !important;
    margin-bottom: 0.6rem;
    margin-top: -0.3rem;
  }
  .fc .delivered {
    margin: 0px 0px 0px 0px;
    background: red !important;
    padding: 0px 5px;
    border-radius: 50%;
    margin-bottom: 0.9rem;
    opacity: 1 !important;
    padding-right: 20px;
    margin-left: 5px;
    margin-bottom: 12px;
  }
  .fc .fc-toolbar-title {
    color: #2A3237;
      font-family: Campton-semiBold;
      font-size: 16px;
      font-weight: 600;
    white-space: nowrap !important;
  }
  .calendar0 .fc .fc-toolbar h2:first-child  {
    margin-left:-64px !important
  }
  .calendar1 .fc .fc-toolbar h2:first-child  {
    margin-left: 43px !important
  }
  .calendar2 .fc .fc-toolbar h2:first-child  {
    margin-left: 43px !important
  }
  .fc-day-header {
    color: #2A3237 !important;
    font-family: Campton-medium !important;
    /* font-size: 12px; */
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  // .fc .fc-toolbar h2:first-child  {
  //   margin-left:-10px !important
  // }
  // .fc .fc-toolbar > * >:first-child {
  //   margin-left: 20px !important;
  // }
  // .fc .fc-toolbar > * >:first-child {
  //   margin-left: 20px !important;
  // }
  .fc .fc-daygrid-day-number {
    padding: 0px !important;
    position: relative !important;
    z-index: 4 !important;
    font-family: Campton-medium !important;
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  .fc-col-header-cell{
    color: #2A3237 !important;
    font-family: Campton-medium;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .fc-next-button{
    background: none !important;
      border: none !important;
      color: black !important;
  }
  .fc-prev-button {
    background: none !important;
      border: none !important;
      color: black !important;
  }
  .fc-icon-chevron-right::before {
    pointer-events: none;
    content: "\0203A";
    font-weight: 700;
    font-size: 40px;
    position: absolute;
    left: 31px;
      bottom: 50px;
   
  }
  .fc-icon-chevron-left::before {
    content: "\02039";
    font-weight: 700;
    font-size: 40px;
    position: absolute;
    bottom: 53px;
    left: -10px;
    pointer-events: none;
  }
  .fc-daygrid-day-frame {
    padding-top: 3px;
    padding-left: 1px;
    background-color: white !important;
  }
  .fc-daygrid-day-frame:has(.fc-daygrid-day-bg):has(.fc-daygrid-bg-harness):has(.delivered) {
    color: white;
  }
  .fc-scrollgrid-sync-table tr {
    height: 35px;
  }
  //.mxCalendar3.fc-toolbar .fc-toolbar-chunk
  // .fc-button-primary{
  //   display: none !important; 
  // }
  // .fc-icon-chevron-right::before {
  //   content: none;
  // }
  .order-details-table tbody .mdc-data-table__row:first-child td{
    border-top: 0px !important;
}
.fc-button{
  font-size: 14px;
  font-family: "Campton-Semibold";
  color: #2A3237;
  line-height: 21px;
}
.fc-button-primary:disabled + .fc-button{
  color: #5D6F7B;
}