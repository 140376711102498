.CookiePolicyPageTemplate div#cookie-policy-description {
  font-size: 16px !important;
  width: 98% !important;
  line-height: 18px !important;
}

.CookiePolicyPageTemplate h4.ot-sdk-cookie-policy-group {
  font-size: 18px !important;
  font-family: 'Campton-Medium' !important;
  // margin-bottom: 1em;
  font-weight: 600 !important;
}

.CookiePolicyPageTemplate p.ot-sdk-cookie-policy-group-desc {
  font-size: 16px !important;
  width: 98% !important;
  line-height: 18px !important;
}

.CookiePolicyPageTemplate td.ot-host-td,
span.ot-cookies-type-td-content,
span.ot-life-span-td-content,
td.ot-cookies-td {
  font-size: 16px !important;
}

.CookiePolicyPageTemplate span.ot-cookies-td-content {
  // text-decoration: underline !important;
  font-size: 16px !important;
}

// .CookiePolicyPageTemplate span.ot-cookies-td-content {
//   text-decoration: underline;
// }
.CookiePolicyPageTemplate #onetrust-banner-sdk{
  display: block !important;
}
.CookiePolicyPageTemplate button#ot-sdk-btn {
  outline: none;
  background-color: #e2dfda !important;
  color: #e7040b !important;
  line-height: 1.7em !important;
  text-decoration: none;
  display: inline-block;
  font-size: 15px !important;
  padding: 15px 25px !important;
  font-weight: 300;
  border-radius: 30px;
  outline: none;
  border: 0 !important;
  text-align: center;
  background-color: #e7040b !important;
  color: #fffffd !important;
  position: relative;
  will-change: color;
  // -webkit-transition: color 250ms, background-color 250ms !important;
  transition: color 250ms, background-color 250ms !important;
}

.CookiePolicyPageTemplate #ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.ot-sdk-show-settings:focus {
  outline: none;
  background-color: #e2dfda !important;
  color: #e7040b !important;
}

.CookiePolicyPageTemplate h1.cookiePolicyLogout_left_header.new_header_cookie {
  font-size: 40px;
  padding: 0 0 60px 0;
  line-height: 1.7em;
  color: #3c3c3c;
  font-family: Campton-Book;
  margin: 0px;
}

.CookiePolicyPageTemplate div#ot-sdk-cookie-policy-v2 {
  margin-top: 2%
}

.ContentPage1Template {
  display: contents !important;
}
.CookiePolicyPageTemplate .breadcrumb {
  background-color: #fffffd !important;
  padding-left: 40px;
  .breadcrumb-item+.breadcrumb-item {
    &::before {
      content: ">";
    }
  }
}
.CookiePolicyPageTemplate .breadcrumb > li > a {
  color: rgb(42, 50, 55) !important;
}
.CookiePolicyPageTemplate #onetrust-banner-sdk {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays on top of other elements */
}
.CookiePolicyPageTemplate #onetrust-banner-sdk .ot-sdk-row{
  margin: 0;
  max-width: none;
  display: block;

}
.CookiePolicyPageTemplate #onetrust-banner-sdk{
  display: block !important;
  opacity:1 !important;
  visibility: visible !important;
}
.CookiePolicyPageTemplate {
  #onetrust-consent-sdk #onetrust-banner-sdk {
    background-color: #F1EFEA !important;
}

#onetrust-banner-sdk.otRelFont {
    font-size: 1rem;
}
#onetrust-banner-sdk.otFlat {
    position: fixed;
    z-index: 2147483645 !important;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #F1EFEA !important;
    max-height: 90% !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #onetrust-banner-sdk .ot-sdk-container {
    width: 100%;
    padding: 0;
  }

  #onetrust-banner-sdk .ot-sdk-row {
    margin: 0;
    max-width: none;
    display: block;
}
}
.CookiePolicyPageTemplate
{ 
  @media only screen and (min-width: 1280px) {
  #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-group-container {
      width: 55% !important;
  }
}
}
.CookiePolicyPageTemplate #onetrust-banner-sdk .ot-sdk-columns:first-child{
  margin-left: 0 !important;
}
.CookiePolicyPageTemplate button#onetrust-pc-btn-handler{
  color: #FC1921 !important;
  border-color: #FC1921 !important;
  background-color: #F1EFEA !important;
}
.CookiePolicyPageTemplate button#onetrust-accept-btn-handler
{
  color: #FC1921 !important;
  border-color: #FC1921 !important;
  background-color: #F1EFEA !important; 
}
.CookiePolicyPageTemplate div#onetrust-policy-text{
color: black !important;
}
.CookiePolicyPageTemplate #onetrust-banner-sdk #onetrust-pc-btn-handler {
  outline-offset: 1px;
}
.CookiePolicyPageTemplate{
  header{
    border-bottom: 1px solid #ececec !important;
  }
}
.ContentPage1Template{
  header{
    border-bottom: 1px solid #ececec !important;
  }
  app-first-header {
    section {
      table {
        tbody {
          tr {
            td .ot-host-td {
              a {
                text-decoration: underline !important;
              }
            }
          }
        }
      }
    }
    .cookiePolicyLogout {
      .cookiePolicyLogout_tableLayout {
        #ot-sdk-cookie-policy {
          #ot-sdk-cookie-policy-v2 {
            section {
              table {
                thead {
                  tr .ot-table-header {
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#onetrust-consent-sdk {
  #onetrust-pc-sdk {
    #ot-pc-content {
      #ot-pc-desc {
        font-size: 16px;
        line-height: 18px;
      }
      #accept-recommended-btn-handler {
        font-size: 16px;
      }
    }
    .ot-pc-footer {
      .ot-btn-container {
        .ot-pc-refuse-all-handler,.save-preference-btn-handler {
          font-size: 16px;
        }
      }
    }
  }
}
#onetrust-banner-sdk {
  .ot-sdk-container {
    #onetrust-group-container {
      #onetrust-policy {
        #onetrust-policy-text {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
    .ot-sdk-row {
      #onetrust-button-group-parent {
        #onetrust-button-group {
          #onetrust-pc-btn-handler, #onetrust-accept-btn-handler {
            font-size: 16px;
          }
          #onetrust-accept-btn-handler {
            background-color: #FC1921 !important;
            color: #FFFFFF !important;
          }
        }
      }
    }
  }
}



.CookiePolicyPageTemplate {
  td.ot-cookies-td{
    span.ot-cookies-td-content{
      a{
        text-decoration: underline !important;
      }
    }
  }
}
// .CookiePolicyPageTemplate{
//   .ot-sdk-cookie-policy-group{
//   caption.ot-scrn-rdr{
//     td.ot-host-td{
//       #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table 
//       tr:nth-child(2) td:first-child {
//         text-decoration: underline;
//     }
//   }
// }
// }
// }
.CookiePolicyPageTemplate{
  .ot-host-td a{

    text-decoration: underline !important;
  }
}
// .CookiePolicyPageTemplate{
  .ot-sdk-cookie-policy-group{
    td.ot-host-td{
      a{
        text-decoration: underline !important;
      }
    }
  }
// }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a:hover{
    color: #000000 !important; 
  }
