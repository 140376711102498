.PublicErrorPageTemplate {
  .headerbar {
    display: none !important;
  }
  .foruk_header .col-sm-8{
    display: none;
  }
  .header .SiteLogo {
    padding-left: 3% !important;
    padding-bottom: 0% !important;
    margin-top: 10px;
    flex: 0 1 auto !important;
  }

  #publicpage .mhralink {
    font-size: 24px !important;
    letter-spacing: 0 !important;
    line-height: 31px !important;
    font-family: "Campton-Medium" !important;
    color: #2A3237 !important;
  }

  #publicpage a {
    cursor: pointer;
  }

  .grbnumber {
    font-size: 14px !important;
    padding: 0px !important;
    color: #2A3237 !important;
    font-family: 'Campton-Book' !important;
    margin: 0px 32px 30px 30px !important;
  }
}

#publicpage .blacktriangle {
  padding: 0px 10px;
}

.commonleave_popContBtn:hover {
  background-color: #EA181B !important;
  color: #fff !important;
}

.commonleaving_abandonedPop .commonleave_popContBtn {
  color: #EA181B;
  background-color: white;
  border: 2px solid #EA181B;
  margin-right: 10px;
}

#publicpage-footer {
  .footer_container .footer_menu_listuk li a {
    font-family: Campton-book;
    color: #fff !important;
    font-size: 16px;
    line-height: 20px;
  }

  .footeritemuk {
    font-size: 12px !important;
    font-family: Campton-book !important;
    color: #fff !important;
  }
}

.publiccontainer1 .publicsubtext p {
  margin: 0 !important;
}

// .PublicErrorPageTemplate .BottomHeaderSlot{
//   display: none !important;
// }