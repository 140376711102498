.FaqsPageTemplate {
    p.text--grey-110 {
        color: #5D6F7B;
        font-size: 22px !important;
        font-weight: 300 !important;
        letter-spacing: 0;
        line-height: 32px !important;
        margin: 20px 0px 0px 0px;
        width: 666px;
    }

    .hero--circlechart .hero--circlechart-content .rectangle-down {
        position: relative;
        // top: 35px !important;
        top: 16px !important;
        left: 5px !important;
        width: 20px;
        height: 21px;
    }

    .opeffciency-circlechart .content-container .container,
    .opeffciency-circlechart .content-container {
        // padding-left: 10px !important; 
        padding-left: 20px !important;
    }

    .for-loggedout-pibanner {
        margin-top: 50px !important;
    }

    .opeffciency-circlechart .pl-hero-100 {
        padding-left: 5 !important;
    }

    .content-container .text--grey-110 span {
        color: #EA181B !important;
        font-family: campton-bold !important;
    }

    .hero--circlechart .hero--circlechart-content {
        padding-top: 0em !important;
    }

    .hero--circlechart-content h1 {
        margin-top: 0px !important;
    }

    .help_LIsubheader sup {
        font-size: 20px;
        top: -0.3em;
        font-family: Campton-Book;
    }

    @media(min-width: 1024px) {
        .global_breadcrumbs_helpnfaq {
            display: block !important;
            text-align: start;
            margin-left: 25PX;
        }
    }
    @media(max-width: 1023px){
        .global_breadcrumbs_helpnfaq_mobile{
            display: block !important;
            text-align: start;
            a{
                display: inline;
            }
        }
    }

    .global_breadcrumbactive {
        font-family: campton-medium;
        font-weight: 600;
        margin-left: 5px;
    }

    .global_breadcrumblink {
        margin-right: 5px;
    }

    .text--grey-110 sup {
        font-family: campton-bold;
        font-size: 22px;
        top: -0.3em;
    }

    .pl-hero-100 {
        padding-left: 0px;
    }

    #help_contactsection .help_contactcontent .help_smallcaps {
        font-size: 80%;
    }

    .help_LIheadersection {
        background-color: #5D6F7B;
        color: #fff;
        // padding: 60px 30px;
        padding: 55px 30px;
    }

    .help_LIheadersection .new_header {
        color: #fff;
        padding: 0px 10px;
        margin: 0px;
    }

    .help_LIheader {
        font-family: campton-medium;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 40px;
    }

    @media only screen and (min-width: 767px) {
        .help_LIsubheader {
            width: 100%;
        }
    }

    .help_LIsubheader {
        font-family: campton-book;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 27px;
        margin-top: 20px;
        // width: 65%;
        padding-left: 10px;
    }

    .help-and-faq-LI {
        display: block !important;
    }

    .opeff-LI-version {
        display: none !important;
    }

    .Uk-Lo-breadcrumbs {
        padding: 30px 0 0;
        overflow: hidden;
        float: none;
    }

    .Uk-Lo-breadcrumbs .global_breadcrumbs_helpnfaq {
        margin-left: 50px;
        padding-left: 0px;
    }

    .Uk-Lo-breadcrumbs .global_breadcrumbactive {
        font-family: "Campton-SemiBold", sans-serif;
        font-weight: bold;
        color: #5D6F7B;
    }

    #help_contactbtnsection a #help_contactbtn:hover {
        color: #fff !important;
        background-color: #EA181B !important;
    }

    //for user guide parent
    .userguideparent {
        padding: 0px 60px 5px 40px;
        left: 0 !important;
    }
   .createprofile_helptext{
    padding-left: 0 !important;
    padding-right: 0 !important;
   }
    //to hide overview breadcrumbs in helpfaq page
    .breadcrumbs--page-nav {
        display: none !important;
    }

    //to remove the grey btn in faq page
    .hero--circlechart-content .global_greybtn {
        display: none !important;
    }

    //to give margin top to pi banner in help faq
    .op-ef-Lo-version .pl-hero-100 {
        margin-bottom: 40px !important;
    }
    //border bottom
    .header{
        border-bottom: 1px solid #ececec;
    }
    #alertlogin{
        position: relative !important;
    }
}

.help_contactcontent a {
    font-family: campton-medium;
    font-weight: 600;
    cursor: pointer;
    // text-decoration: none !important;
}

.helpnfaqcontainer a,
.helpnfaqcontainer .help_phonelink {
    color: #5D6F7B !important;
    font-size: 16px !important;
    font-family: campton-medium !important;
    font-weight: 600 !important;
    letter-spacing: 0;
    line-height: 24px !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.helpnfaqcontainer .help_noresultcontent .help_flu360link {
    text-decoration: none !important;
}

.helpnfaqcontainer a:hover {
    color: #5D6F7B !important;
    font-size: 16px !important;
    font-family: campton-medium !important;
    font-weight: 600 !important;
    letter-spacing: 0;
    line-height: 24px !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.help_container a:hover,
.help_container a:focus {
    color: #5D6F7B !important;
}

.help_container a:active {
    outline: 2px #5D6F7B !important;
}

.dropdown-item:hover {
    background-color: #fff !important;
}

.dropdown-item:active {
    background-color: #fff !important;
}

@media only screen and (max-width: 767px) {
    .FaqsPageTemplate {
        .pl-hero-100 {
            height: 280px !important;
        }

        .hero--circlechart-content {
            margin-left: 38px !important;
        }

        p.text--grey-110 {
            width: 404px;
            max-width: 700px;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width:920px) {
    .FaqsPageTemplate {
        .pl-hero-100 {
            height: 280px !important;
        }

        .op-ef-firstcontainer {
            margin-right: 197px !important;
        }
    }
}

.help_contactcontent strong {
    font-family: campton-medium;
    font-weight: 600;
    cursor: pointer;
}

.dropdown-body ul,
.search-accordion-content ul {
    margin: 0px 5px;
    list-style: none;
    padding-left: 0;
}

.dropdown-body li,
.search-accordion-content li {
    text-indent: -5px;
    margin-bottom: 10px;
    font-size: 16px;
}

.dropdown-body li:before,
.search-accordion-content li:before {
    content: "-";
    text-indent: -5px;
    margin-right: 10px;
}

.dropdown-item .simple-dropdown-button {
    padding: 0 !important
}

.dropdown-outercontainer .help_smallcaps {
    font-size: 80%;
}

#help_contactsection .help_flu360linkUK {
    font-family: campton-semibold;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline !important;
    color: #2A3237 !important;
}

//for the contine link present inside the popup
.helpnfaqcontainer .knowledgeHub_abandonedPopContent .knowledgeHub_abandonedPopBtn .commonleave_popContBtn a {
    color: #EA181B !important;
    text-decoration: none !important;
}

.helpnfaqcontainer .knowledgeHub_abandonedPopContent .knowledgeHub_abandonedPopBtn .commonleave_popContBtn:hover {
    color: #fff !important;
    text-decoration: none !important;
}

body.loggedin:has(app-help-faq-second) .FaqsPageTemplate {
    .help_searchheader {
        margin-top: 20px;
    }
    .referencesContainerHomeUK{
        font-size: 12px;
        color: #5d6f7b;
        padding-left: 0px;
    }
}
body:not(.loggedin):has(app-help-faq-second) .FaqsPageTemplate  {
    .userguideparent {
        padding: 0px 55px 5px 40px !important;
    }
}
.helpsectionbtn:focus {
    outline: 2px solid #EA181B !important;
 }
 body:not(.loggedin) .FaqsPageTemplate  {
    #help_contactsection {
        bottom: 20px;
    }
}
body:not(.loggedin):has(app-homepage-uk-pislot) .FaqsPageTemplate  {
     .help_accordion{
        margin-bottom: 6% !important;
     }
     .grbnumber {
        font-size: 12px !important;
        padding: 10px 0px !important;
        color: #5d6f7b !important;
        font-family: "Campton-Book" !important;
        padding-left: 20px !important;
        margin-bottom: 10px !important;
    }

}
.seqirusb2b-uk .FaqsPageTemplate .BodyContent:has(app-homepage-uk-pislot) .help_noresultsection{
    .help_boldtext{
        font-family: campton-medium;
        font-weight: 600;
    }
}