.Cart_select_txt {
  font-size: 14px;
  padding: 15px 19px 10px 19px;
  display: block;
  overflow: hidden;
  color: #2A3237;
  font-family: 'Campton-Book';
}
.Cart_select_txt_nxt {
  font-size: 14px;
  padding: 0px 19px 10px 19px;
  display: block;
  overflow: hidden;
  color: #5D6F7B;
  font-family: 'Campton-Book';
}
.Cart_select_link {
  color: #2A3237;
  font-family: campton-medium;
  font-size: 14px;
  line-height: 22px;
  padding: 0px 19px 10px 19px;
}
.Cart_select_link a {
  color: #2A3237;
  font-family: campton-medium;
  font-size: 14px;
  text-decoration: underline !important;
}
.timing {
  padding: 0px 19px 10px 19px;
  color: #5D6F7B;
}
 .order_inactive .cart_neworderbtnheader{
	color:#5D6F7B;
	font-size: 16px;
}
 .order_inactive .cart_neworderbtndate{
	color:#8E9AA3;
	
}
.order_inactive .cart_neworderbtn{pointer-events: none;
	background: #F9FAFA;
	opacity: 0.9;
}
#OM_exitPopup .OMexit_popSubHeader {
  color: #5D6F7B;
  font-family: campton-book;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 27px;
  padding: 10px 10% 0px 10px;
}
.CreateOrderPageTemplate .modal-content{
  border-radius: 0px;
  border: none;
}