.BodyContent app-summarybanner{
grid-column: 1 / span 2;
}
#orderslabelname {
    width: 380px;
    font-family: Campton-semibold;
    font-size: 15px;
    font-weight: 500;
    color: #2A3237;
    display: inline-block;
    float: left;
    margin-top: -4px;
    margin-left: 5%;
}
#orderpercnetage {
    margin: 0 70%;
    font-family: Campton-semibold;
    font-size: 15px;
    font-weight: 500;
    color: #2A3237;
    position: absolute;
    margin-top: -4px;
}
#deliveryTable thead tr th{
    color: #5C6E7C !important;
    font-size: 15px !important;
    font-family: Campton-Medium;
    text-transform: none !important;
    border: none !important;
   
    padding-bottom: 10px;
    padding-top: 10px;
    outline: none !important;
    }
.latest_shipment_uk #deliveryTable thead tr th {
    background: #EEF0F1 !important;
    padding: 20px 10px;
    vertical-align: middle;
}
.latest_shipment_uk #deliveryTable tbody tr td{
    padding: 20px 10px;
    vertical-align: middle;
}

.latest_shipment_uk #deliveryTable tbody tr td:last-child{
    @media (min-width: 1024px) and (max-width: 1450px){
    padding: 0px;
    }
}
#deliveryTable  .orders_viewDetails .global_blackarrow{
    @media (min-width: 1024px) and (max-width: 1450px){
    margin-left: 5px;
    }
}

.latest_shipment_uk #deliveryTable thead tr th:first-child{
width:20%;
}
.latest_shipment_uk #deliveryTable thead tr th:nth-child(2){
width:17%;
}

.latest_shipment_uk #deliveryTable thead tr th:nth-child(3){
width:30% !important;
}


.latest_shipment_uk #deliveryTable tbody tr:nth-child(even) {background: #EEF0F1 !important;}
.latest_shipment_uk #deliveryTable tbody tr:nth-child(odd) {background: #fff !important;}
.latest_shipment_uk #deliveryTable tbody tr td{vertical-align:middle !important;
    color: #6a6a6a;
    font-size: 13px;
    line-height: normal;
}
.latest_shipment_uk #deliveryTable tbody tr td{
    font-family: Campton-Book;
}
.latest_shipment_uk .mat-mdc-no-data-row .no-data{
    padding: 10px 10px !important;
}
.latest_shipment_uk #deliveryTable tbody tr td:last-child a {
   font-size:14px !important;
   font-family: "campton-semibold";
   font-weight: 600;
}
#deliveryTable{border-bottom:none !important;}
.latest_shipment_uk table thead th {
    text-align: left !important;
    font-weight: 500;
}
#deliveryTable{
    box-shadow: none !important;

.no-data{
    font-size: 13px;
   
     color: #6a6a6a;
}
}

.filterTable_upcomingOrder tbody tr {
    border-top: 1px solid #cfd8e1 !important;
    border-bottom: 1px solid #cfd8e1 !important;
    border-left: none !important;
    border-right: none !important;
}
.filterTable_upcomingOrder tbody tr {
    background-color: #ffffff;
}
.filterTable_upcomingOrder{
    box-shadow: none;
    thead{
        display: none;
    }
.tablecolum_order1{
    width: 70%;
    padding: 8px 10px;
    float: left;
}

.order_season{
color: #2A3237;
font-family: Campton-medium;
font-size: 18px;
font-weight: 500;
letter-spacing: 0;
line-height: 27px;
margin-bottom: 10px;
}
.order_id{
display: block;
    overflow: hidden;
    margin-bottom: 10px;
}
.order_id span{
    width: 47%;
    float: left;
    font-size: 13px;
    font-family: campton-medium;
    color: #5D6F7B;
    line-height: 20px;
}

.order_id span:first-child {
    color: #5D6F7B;
    font-family: Campton-semibold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
}
.tablecolum_order2{
    padding: 8px 10px;
    float: right;
    width: 30%;
    line-height: 14px;
}
.order_status {
    font-family: Campton-semibold;
    font-size: 12px;
    color: #5D6F7B;
    text-transform: uppercase;
    border: 1px solid #DBE0E2;
    border-radius: 5px;
    padding: 3px 5px;
    float: right;
}
.mat-column-combined{
    padding-left: 0px;
    padding-right: 0px;
}
}
.orders_rowsectionright_uk{
  
    float: left;
}

.leftbackgradient .highcharts-legend .highcharts-legend-item rect{
    stroke-width: 1px;
    stroke: #979797;
}

.BodyContent:has(app-summarybanner) app-jobbagcode-uk{
    margin-left: -39%;
}
.BodyContent:has(app-summarybanner) app-jobbagcode-uk .referencesContainerHomeUK{
    padding-top: 20px;
    padding-left: 10px;
}

.modal.in {
    z-index: 1200;
}
#ordersummary_OM_seasonConfirmPopup .modal-dialog {
    width: 63%;
    padding: 15px 15px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    max-width: 100%;
}
#ordersummary_OM_seasonConfirmPopup .modal-body {
    padding: 40px 30px;
}
#ordersummary_OM_seasonConfirmPopup .OMconfirm_popHeader {
    color: #2A3237;
    font-family: campton-medium;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
}
#ordersummary_OM_seasonConfirmPopup .OMconfirm_popClose {
    font-size: 40px;
    float: right;
    margin-top: -15px;
    cursor: pointer;
}
#ordersummary_OM_seasonConfirmPopup .OMconfirm_popSubHeader {
    color: #5D6F7B;
    font-family: campton-book;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 27px;
    padding: 5px 10% 0px 10px;
}
#ordersummary_OM_seasonConfirmPopup .OMconfirm_PopBtnParent {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
#ordersummary_OM_seasonConfirmPopup .OM_popBackBtn {
    font-family: 'Campton-SemiBold';
    outline: none !important;
    font-size: 14px;
    background-color: transparent;
    color: #5D6F7B;
    cursor: pointer;
    line-height: 21px;
    border: none;
    text-decoration: underline;
    margin-right: 30px;
    letter-spacing: 1.17px;
}
#ordersummary_OM_seasonConfirmPopup .OM_popContBtn {
    font-family: 'Campton-SemiBold';
    outline: none !important;
    font-size: 14px;
    background-color: transparent;
    color: #5D6F7B;
    cursor: pointer;
    line-height: 21px;
    border: 2px solid #5D6F7B;
    padding: 10px 60px;
}
#ordersummary_OM_seasonConfirmPopup .modal,#OM_exitPopup .modal ,#OM_seasonConfirmPopup .modal{
    background: none;
 
        background-color: rgba(0, 0, 0, 0.4);
}
.USordersummary cx-page-layout.AccountPageTemplate{
    padding-bottom: 0px;
}
#ordersummary_OM_seasonConfirmPopup .modal-content,#OM_exitPopup .modal-content ,#OM_seasonConfirmPopup .modal-content{
   
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
body.modal-open:has(app-summarybanner){
    overflow-y: scroll;
    padding-right: 0px !important;
}

body.seqirusb2b-flu360.loggedin{
    .AccountPageTemplate {
        .orderSummary_right {
            .row{
                margin-left: 0px !important;
                margin-right: 0px !important;
            }
            .orders_righttitle {
                text-align: right;
            }
        }

        app-my-orders-left-section {
            .productdetailTable thead tr th:nth-child(5) {
                width: 21% !important;
            }
        }
    }
}