.AccountPageTemplate .userguideparent {
    float: right;
    position: relative;
    left: 3% !important;
}
.InvoicePageTemplate .userguideparent{
    left: 0 !important;  
}
app-help-section-dashboard{
    float: left;
}