app-customtab,app-user-section,app-accountsection,app-nonadmin,app-permissionadmin{
    grid-column: 1 / 2 span;
}
.BodyContent:has(app-jobbagcode-uk) app-custom-gigya-raas{
    grid-column: 1 / 2 span;
    margin: 20px 50px;
    background-color: #F9FAFA;
   
    width: calc(100% - 100px);
}
.accounttabactive app-custom-gigya-raas,.permissionadminactive app-custom-gigya-raas{
    display:none;
}
.accounttabactive .myprofile_tabContent{display: block !important;}
.permissionadminactive .permissionsection{
  display: block !important;
}
.useratabactive .myprofile_consentsection{
  display: block !important;
}
.ukmanagelocation{
    float: left;
    .myprofile_content_location{
    margin: 0px 0px 20px 0px !important;
    }   
}
//manage location uk class add css inside it

.account-table .ng-trigger,.ukmanageLocation-table .ng-trigger{
    display: none !important;
}

.account-table .mat-sort-header-container,.ukmanageLocation-table .mat-sort-header-container{
    display: block;
    float: left;
    margin-right:5px ;
  }
  .myprofileuklocation table thead th, .myprofileuklocation table tbody td ,.ukmanageLocation-table .mat-sort-header,.ukmanageLocation-table tbody td{
    font-family: "Campton-medium" !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #212121 !important;
    padding: 10px 25px 10px 10px;
  
  }
  .myprofileuklocation table thead th,.ukmanageLocation-table .mat-sort-header{
  background-color: #F9FAFA !important;
  border-bottom:0px ;
  }
  .myprofile_content_account tbody tr td:nth-child(1),.myprofile_content_account tr th:nth-child(1) {
    width: 21% !important;
  }
  .myprofile_content_account tbody tr td:nth-child(2),.myprofile_content_account tr th:nth-child(2) {
    width: 25% !important;
  }
  .myprofile_content_account tbody tr td:nth-child(3),.myprofile_content_account tr th:nth-child(3) {
    width: 44% !important;
  }
  #myprofilelocation tbody tr td:nth-child(4),.myprofile_content_account tr th:nth-child(4) {
    width: 10% !important;
  }
  .myprofilelocation table > tbody > tr > td {
    vertical-align: top !important;
   
  }

  .ukmanageLocation-table thead{
    width: 100%;
    display: block;
    position: relative;
  }
  .ukmanageLocation-table thead tr,.ukmanageLocation-table tbody tr,.myprofile_content_account thead tr,.myprofile_content_account tbody tr{
    width: 100%;
    max-width: 100%;
    position: relative;
    display: inline-flex;
  }
  .ukmanageLocation-table tbody{
  display: block;
  width: 100%;
  max-height: 275px;
  overflow: auto;
  }
  .ukmanageLocation-table thead tr th:nth-child(1),  .ukmanageLocation-table tbody tr td:nth-child(1){
width: 24%;
  }
  .ukmanageLocation-table thead tr th:nth-child(2),  .ukmanageLocation-table tbody tr td:nth-child(2){
    width: 38%;
      }
      .ukmanageLocation-table thead tr th:nth-child(3),  .ukmanageLocation-table tbody tr td:nth-child(3){
        width: 12%;
          }
          .ukmanageLocation-table thead tr th:nth-child(4),  .ukmanageLocation-table tbody tr td:nth-child(4){
            width: 27%;
              }
              .ukmanagelocationtable{
                overflow: hidden  !important;
              }
              .ukmanageLocation-table .mdc-data-table__content::-webkit-scrollbar-track,.myprofile_content_account .mdc-data-table__content::-webkit-scrollbar-track{
                background-color: #EEF0F1;
                border-radius: 10px;
            }
            .ukmanageLocation-table .mdc-data-table__content::-webkit-scrollbar-thumb,.myprofile_content_account .mdc-data-table__content::-webkit-scrollbar-thumb{
              background-color: #AEB7BD;
              border-radius: 10px;
          }    
              .ukmanageLocation-table .mdc-data-table__content::-webkit-scrollbar,.myprofile_content_account .mdc-data-table__content::-webkit-scrollbar{
                width: 6px;
                background-color: #AEB7BD;
                border-radius: 10px;
            }

            .account-table tbody tr td{
              border-bottom: none;
          }
          .account-table .Clickable {
            color: #5D6F7B;
            font-family: 'Campton-Medium';
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 21px;
            text-decoration: underline;
            cursor: pointer;
          }
          .account-table .Clickable:hover {
            text-decoration: none;
          }
          .account-table .nonClickable {
            color: #5D6F7B;
            font-family: 'Campton-Book';
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 21px;
            cursor: none;
          }             
            
          .myprofile_tabContentHeader {
            color: #EA181B;
            font-family: 'Campton-Medium';
            font-size: 24px;
            letter-spacing: 0;
            line-height: 26px;
        }
        .permissiontable1 {
            border: 1px solid #EEEEEE;
            padding: 15px 30px;
            margin-top: 40px;
        }
        .permission_text {
          font-family: 'Campton-Medium';
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0px;
          color: #212121;
      }
      
    .permissiontable1{
      border: 1px solid #EEEEEE;
      padding: 15px 30px;
      margin-top: 40px;
      }
      .permission_text{
        font-family: 'Campton-Medium';
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        color: #212121;
        
      }
      .permissiontable2,.permissiontable3{
        border: 1px solid #EEEEEE;
        border-top: 0px;
        padding: 30px 0px 10px;
      }
      .accountnonadmin thead th,.useraccounttable thead th
      
      {
        width:27%;
        text-align: left;
        background-color: #EEF0F1;
        font-family: "Campton-semibold";
        font-size: 16px;
        @media (min-width: 1023px) and (max-width: 1125px){
          font-size: 14px;
        }
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        color: #2A3237;
        text-transform: none;
        padding: 10px 10px 10px 10px;
       
       vertical-align: middle;
        svg{
          vertical-align: middle;
        }
      }
      .accountnonadmin tbody td,.useraccounttable tbody td{
        width:27%;
      font-family: "Campton-medium";
      font-size: 16px;
      @media (min-width: 1023px) and (max-width: 1125px){
          font-size: 14px !important;
        }
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #212121;
      padding: 20px 10px 20px 10px;
      
      
      }
      .accountnonadmin thead th:first-child,.accountnonadmin tbody td:first-child{
        padding-left: 20px;
        width: 46%;
      }
      
      .useraccounttable tbody td{
        width: 30%;
        padding: 20px 10px 20px 10px;
        @media (min-width: 1023px) and (max-width: 1200px){
          padding: 20px 5px;
        }
      
      }
      .useraccounttable thead th{
        width: 30%;
        padding: 10px 10px 10px 10px;
        @media (min-width: 1023px) and (max-width: 1200px){
          padding: 10px 5px;
        }
      }
      .useraccounttable tbody td:first-child{
        width: 40%;
        @media (min-width: 1023px) and (max-width: 1260px){
          width: 31%;
      }
        padding: 20px 10px 20px 20px;
        @media (min-width: 1023px) and (max-width: 1200px){
          padding: 20px 5px 20px 10px;
        }
      }
      .useraccounttable thead th:first-child{
        width: 40%;
        @media (min-width: 1023px) and (max-width: 1260px){
          width: 31%;
       }
        padding: 10px 10px 10px 20px;
        @media (min-width: 1023px) and (max-width: 1200px){
          padding: 10px 5px 10px 10px;
        }
      }
      
      
      .permissiontable3 table thead th,.permissiontable3 table tbody td{
        background-color: #F7F7F7;
        font-family: "Campton-Medium";
        text-transform: none;
        padding: 10px 10px;
        vertical-align: middle;
        font-size: 16px;
        @media (min-width: 1023px) and (max-width: 1125px){
          font-size: 14px !important;
        }
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #212121;
      svg{
          vertical-align: middle;
        }
        }
        .permissiontable3 table thead th:first-child{
          padding: 10px 15px;
          @media (min-width: 1023px) and (max-width: 1125px){
              padding: 10px 18px 10px 20px;
           }
        }
     
      #locationnonadmin th:nth-child(3){
          @media(min-width:1023px) and (max-width: 1125px){
          padding: 10px 10px 10px 1px;
        }
      }
     
      
      
      .permissiontable3 table tbody td{
        background-color: #ffffff;
       padding: 20px 10px ;
      }
      .permissiontable3 table tbody td:first-child{
        padding: 20px 10px 20px 15px;
        line-height: 24px;
      }
     
      .permissiontable4{
        background-color: #EEF0F1;
        padding: 20px 15px !important;
        margin-top: 30px;
        border-radius: 5px;
      }
      .permissiontable4_text{
        width: fit-content;
      }
      .permissiontable4_text div{
      font-family: "Campton-Medium";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      }
      .permissionmail{
        font-family: "Campton-Semibold";
        font-weight: 600;
        a{
          text-decoration: underline;
          cursor: pointer;
        }
        a:hover{
          text-decoration: none;
        }
      }
      .centersubtext{
        padding-left: 50px !important;
      }
      #myprofile_tabContent3{
        padding-bottom: 0px;
      }
      
      .viewtoggle{
        display: flex;
        align-items: center;
      }
      .viewtext{
        margin-right: 20px;
        @media (min-width:1023px) and (max-width:1100px){
          margin-right: 8px;
          }
        font-family: "Campton-medium";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      
      }
      .radioholder, .settingradioholder, .userradioholder{
        border: 1px solid #979797;
        
      
      }
      .permission_radiobtn, .permission_radiobtn_user,.permission_radiobtn_userAdmin, .initialpermission_radiobtn, .orderingpermission_radiobtn, .financialpermission_radiobtn{
        display: none;
      }
      .permissionlabel{
      font-family: "Campton-medium";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      color: #2A3237;
      cursor: pointer;
      text-transform: none;
      margin-bottom: 0px;
      
      }
      .btnselected {
        background-color: #6D7E88;
        .permissionlabel{
        color: #ffffff;
        }
      }
        .permission_droptext{
        font-family: "Campton-Semibold";
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      color: #2A3237;
      align-items: center;
        }
        .Permission_dropdown{
          height: 40px;
          width: 240px;
          padding: 8px 30px 8px 12px;
          border: 1px solid #979D9D;
          margin-left: 20px;
          font-family: "Campton-medium";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #727676;
        -moz-appearance: none;
        appearance: none;
        @media(min-width:1450px) and (max-width: 1920px){
          width: 300px;
          }
      
      
        }
        .permission_drop_box{
          position: relative;
        }
        .permission_drop_box:after {
          content: '\f078';
          font: normal normal normal 12px/1 FontAwesome;
          right: 15px;
          top: 15px;
          height: 20px;
          position: absolute;
          pointer-events: none;
          color: #727676;
      }
        .per_locationt1,.per_locationt2,.per_locationt3{
          border: 1px solid #EEEEEE;
          margin-top: 32px;
          padding:20px 16.5px !important;
          @media (min-width:1023px) and (max-width: 1200px){
            padding:20px 7.5px !important;
          }
        }
        .per_locationt2{
          margin-top: 12px;
          padding: 0px 0px 5px 0px  !important;
        }
        .per_locationt3{
          margin-top: 16px;
          margin-bottom: 64px;
        }
        .perlocationcont{
          display:flex;
          align-items: center;
          margin-bottom: 16px;
        }
        .per_locationdropdow{
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .viewselection{
          align-items: center;
          justify-content: end;
          padding-right: 16px;
          @media (min-width:1023px) and (max-width: 1100px){
          padding-left:0px;
          padding-right: 0px;
          }
        }
        #locationadmin_filter{
          display: none;
        }
     
       .permissionradioparent{
        display:flex;
       }
 
    
        .settingtext{
          font-family: "Campton-Semibold";
          font-size: 24px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0px;
          color: #212121;
          margin-bottom: 16px;
        }
        .intialview{
          font-family: "Campton-Medium";
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0px;
          margin-bottom: 16px;
          color: #212121;
          svg{
            vertical-align: middle;
          }
        }
        .newuserview{
          margin-top: 16px;
        }
        .newpermission{
          display: flex;
         margin-bottom:16px ;
         align-items: center;
        }
        .newpermissionText{
          font-family: "Campton-Bold";
          font-size: 24px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0px;
          margin-right: 10px;
        }
        .Financial_permission{
          justify-content: end;
        }
        .adminLocationView,.adminUserView, .adminAccountView {
            display: none;
        }
      
      .tablecustomheader{
          display: flex;
          background-color: #f9fafa;
          .customheaderdiv{
            width: 33%;
            vertical-align: middle;
          font-family: "Campton-semibold";
          font-size: 16px !important;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0px;
          color: #212121;
          background-color: #F9FAFA;
          padding: 10px 10px;
          @media(min-width:1023px) and (max-width: 1200px){
            padding: 10px 5px;
          }
          align-items: center;
          display: flex;
          }
        }
      
        .tablecustomheader .customheaderfirst{
          width: 33.79%;
          padding: 10px 10px 10px 20px;
        }    
      #locationnonadmin thead{
          padding : 0px 9px 0px 0px;
          background-color: #F7F7F7;
      }
      
       .usercustomheader:first-child {
          width : 25%;
        @media(min-width:1023px) and (max-width: 1260px){
          width : 22%;
        }
      }
      
     .usercustomheader:nth-child(2) {
          width : 15%;
        @media(min-width:1023px) and (max-width: 1260px){
          width : 13%;
        }
      }
      
      
       .usercustomheader:nth-child(3),  .usertable:nth-child(4) {
          width : 30%;
        @media(min-width:1023px) and (max-width: 1260px){
          width : 34%;
        }
      }
      
       .usercustomheader:nth-child(3),.usertable tbody td:nth-child(2), .usertable thead th:nth-child(2){
        @media(min-width:1200px) and (max-width: 1600px){
          padding-left: 5px;
        }
        @media(min-width:1601px) and (max-width: 1920px){
          padding-left: 0px;
        }
      
      }
      
      
       .usercustomheader:nth-child(2), .usercustomheader:nth-child(4),.usertable tbody td:nth-child(3), .usertable thead th:nth-child(3){
        @media(min-width:1200px) and (max-width: 1920px){
          padding-left: 5px;
        }
      }
     
      .adminuserheader{
        width: 100%;
        margin-right: 0.5%;
        background-color: #F9FAFA;
        .usercustomheader:first-child{
          padding: 10px 10px 10px 20px;
          @media(min-width:1023px) and (max-width: 1200px){
            padding: 10px 5px 10px 10px;
          }
      
        }
      }
      .myprofileUK .uktooltip{
        position: relative;
        margin-left: 10px;
        @media (min-width: 1023px) and (max-width: 1125px){
          margin-left: 6px;
        }
        display: inline-block;
      }
      
          .myprofileUK .uktooltip span {
            background-color: #3F4242 !important;
            border-radius: 0px;
            font-family: "Campton-medium";
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
            padding: 15px;
            color: #FFFFFF;
            width: 320px;
            top: 3em;  /*-- this is the original position of the tooltip when it's hidden */
            left: -17em;
          display: none;
      
            /*-- set opacity to 0 otherwise our animations won't work */
            opacity: 0;
            filter: alpha(opacity=0);  
            position: absolute;
          }
      
       .myprofileUK .uktooltip:hover span {
        opacity: 1;
          filter: alpha(opacity=100);
          top: 3em;
          left: -17em;
          z-index: 999;
        display: block;
        }
      
      .myprofileUK .uktooltip span:after {
        content: "";
          position: absolute;
          bottom: 100%;
         // margin-left: -33px;
          border-width: 20px 15px;
          border-style: solid;
          border-color: #3F4242 transparent transparent transparent;
          transform: rotate(180deg);
          right:23px !important;
          }
      
      .myprofileUK .customthirddiv .uktooltip span:after{
          margin-left: 47px;
      }
      .myprofileUK .customAccountFirst .uktooltip span:after{
          margin-left: 70px;
      }
      .myprofileUK .customAccountDiv .uktooltip span:after{
          margin-left: 190px;
      }
      
          .myprofileUK .customthirddiv .uktooltip span:after, .usercustomheader:nth-child(4) .uktooltip span:after{
            margin-left: 47px;
          }
          .accountnonadmin{width: 100%;}
      //nonadmin table

      .nonadmin-table thead{
        width: 100%;
        display: block;
        position: relative;
      }
      .nonadmin-table thead tr,.nonadmin-tabletbody tr{
        width: 100%;
        max-width: 100%;
        position: relative;
        display: inline-flex;
      }
      .nonadmin-table tbody{
      display: block;
      width: 100%;
      max-height: 275px;
      overflow: auto;
      }
      .nonadmin-table thead tr th:nth-child(1),.nonadmin-table tbody tr td:nth-child(1){
    width: 32%;
      }
      .nonadmin-table thead tr th:nth-child(2),.nonadmin-table tbody tr td:nth-child(2){
        width: 14%;
          }
          .nonadmin-table thead tr th:nth-child(3),.nonadmin-table tbody tr td:nth-child(3){
            width: 27%;
              }
              .nonadmin-table thead tr th:nth-child(4),.nonadmin-table tbody tr td:nth-child(4){
                width: 27%;
                  }
                  .nonadmin-table{
                    overflow: hidden  !important;
                  }
                  .nonadmin-table .mdc-data-table__content::-webkit-scrollbar-track{
                    background-color: #EEF0F1;
                    border-radius: 10px;
                }
                .nonadmin-table .mdc-data-table__content::-webkit-scrollbar-thumb{
                  background-color: #AEB7BD;
                  border-radius: 10px;
              }    
                  .nonadmin-table .mdc-data-table__content::-webkit-scrollbar{
                    width: 6px;
                    background-color: #AEB7BD;
                    border-radius: 10px;
                }
                .nonadmin-table th {
                  font-family: 'Campton-semibold' !important;
                  border-bottom: none;
              }
    
                .nonadmin-table tbody tr td{
                  border-bottom: none;
              }
              .nonadmin-table .ng-trigger{
                display: none !important;
            }
            
            .nonadmin-table .mat-sort-header-container{
                display: block;
                float: left;
                margin-right:5px ;
              }
              .admintable thead th, .admintable tbody td {
                font-family: "Campton-medium" !important;
                font-size: 16px !important;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0px;
                color: #212121 !important;
                vertical-align: middle !important;
                border-bottom: none;
            }

              .admintable td:nth-child(2),.admintable td:nth-child(3),.admintable td:nth-child(4)
 {
  @media(min-width:1200px) and (max-width: 1600px){
    padding:8px;
  }
}
.admintable td:nth-child(1){
    padding: 10px 10px 10px 20px;
  }
.admintable  td{
    width: 33.33% !important;
  }
  .admintable tr:nth-child(even) td{
    background-color: #F9FAFA;
  }
  .usertable tbody tr td:first-child {
    width: 24% !important;
}
 .usertable tbody tr td:nth-child(2) {
  width: 14% !important;
}
 .usertable  tbody tr td:nth-child(3),.usertable tbody tr td:nth-child(4) {
  width: 31% !important;
}

.permissiontext,.nonpermissiontext{
  display: none;
}
.adminsreen .permissiontext{
  display: block;
}
.nonadmin .nonpermissiontext{
  display: block;
}


.custom_sort_us .mat-sort-header-container {
  display: block;
  float: left;
  margin-right: 5px;
}

.custom_sort_us .ng-trigger {
  display: none !important;
}
//For US My-profile
body.loggedin:has(app-custom-info) .AccountPageTemplate {
  padding-bottom: 0px !important;
  }
  .mat-sort-header-arrow:hover {
    transform: none !important;
  }
  .AccountPageTemplate {
    .cdk-overlay-container{
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
  body.loggedin:has(app-custom-info) .AccountPageTemplate ::ng-deep .mdc-dialog__container{
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    padding: 10px;
    position: relative;
    padding: 10px;
    border-radius: 0px;
    outline: 0;
    background-clip: padding-box;
    background-color: #fff;
  }
  .cdk-overlay-pane.mat-mdc-dialog-panel{
    padding: 0 !important;
    height: auto !important;
  }

  app-jobbagcode-uk{width: 100%;
    position: relative;
    // left: -46%;
    bottom: 0px;}

    .mat-sort-header-content{
      font-family: "Campton-medium" !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    color: #212121 !important;
    } 
    
    .mdc-data-table__header-cell{overflow: inherit !important;}

    .seqirusb2b-flu360 .mdc-dialog__container{padding: 0px !important;}
    .thank-you-dialog {
      width: 80% !important;
      padding: 30px 15px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute !important;
      margin: 30px 0;
  }

  .unlinkaccounttable{
    max-height: 160px;
}
.account-table tbody{
    display: block;
width: 100%;
max-height: 100px;
overflow: auto;
}
.seqirusb2b-uk.loggedin:has(app-custom-update-profile) .AccountPageTemplate{
   app-jobbagcode-uk{
    position: relative;
    left: -46%;
   }
}
.seqirusb2b-uk.myprofileUK .grbnumber{
  padding-top:20px;

}

.seqirusb2b-uk.myprofileUK .myprofileuk_content_cdc{
border: 1px solid #DBE0E2;
}

.seqirusb2b-uk.myprofileUK.useratabactive app-user-section{
  min-height: 250px;
}