.ResourcesLandingNewPageTemplate {
    app-custom-opefficiency-first {

        .breadcrumbs--page-nav {
            padding-left: 30px;
            top: 0;
        }

        .outermost-div .hero--circlechart-content {
            width: 90% !important;
        }

        .content-container h1 {
            margin-right: 35%
        }

        .global_greybtn {
            display: none;
        }

        .text--red-100 {
            font-family: "Campton-Bold", sans-serif;
            color: #ea181b;
        }

        .text--grey-110 {
            font-family: "Campton-Book", sans-serif;
            color: #5d6f7b
        }
    }
    app-custom-tiles {
        .tools-and-resources {
            // margin-top:80px
            margin-top: 60px;
        }
        .tools_filterboxname{
            float: left;
        }
        .tools_productboxname{
            float: left;
        }
        // .tools_filterbox .tools_filterboxparent span.tools_productarrow.glyphicon-1.glyphicon-menu-down {
        //     left: 235px !important;
        //     position: relative !important;
        //     font-family: "Glyphicons Halflings" !important;
        //     font-style: normal !important;
        //     font-weight: normal !important;
        //     line-height: 1 !important;
        // }

        // .tools_filterboxparent .glyphicon-menu-up {
        //     left: 235px;
        //     top: -23px;
        // }

        // .tools_filterboxparent .glyphicon-menu-down {
        //     top: -21px !important;
        // }

        // .tools_productbox .glyphicon-menu-up {
        //     top: -18px;
        //     left: 195px;
        //     // left: 215px;
        // }

        // .tools_productbox .glyphicon-menu-down {
        //     top: -17px !important;
        //  }

         .arrow {
             top: 0%;
             left: 0%;
         }

         .dataTables_paginate .paginate_button {
            padding: 3px 6px !important;
         }

        //  .tools_filterboxname {
        //      padding: 18px 0px 0px 40px !important
        //  }

        //  .dataTables_paginate, .tools-and-resources {
            // margin-bottom: 20px;
            // margin-top: 40px !important;
        // }

        // .tools_datasearch {
        //     height: 1570px;
        // }
    //    .tools_productbox span.tools_productarrow.glyphicon-1.glyphicon-menu-down{
    //         left: 195px !important;
    //     }
        .tools_datasearch1.loggedin {
            margin-bottom: -35px;
        }
    }
    app-custom-user-guide {
        .createprofile_helptext {
            z-index: 1;
            // top: 30px;
            margin-right: 6%;
            padding-right: 4%;
            letter-spacing: 0;
        }

    }
    .statussection{
        padding-top: 20px;
    }
    .clinical-breadcrumbs {
        display: none;
    }
    .tools-resource-breadcrumbs{
        display:  block !important;
        position: relative;
        z-index: 1;
         p{
        color: #2A3237 !important;
        }
        strong{
            font-family: 'Campton-semibold' !important;
        }
    }
    .header {
        border-bottom: 1px solid #ececec !important;
      }
}

body.loggedin .ResourcesLandingNewPageTemplate{

    app-custom-tiles {
        .tools_datasearch1 {
            // margin-top: 30px;
            margin-top: 3%;
        }
    }
    // .userguideparent {
    //     // top: 360px;
    //     top: 320px;
    // }
    .tools_datasearch{
        display: none;
    }
    .resourceFilterContainer{
        width: 100%;
    }

    .hero--circlechart-content {
        width: 103% !important;
        background-color: #5D6F7B !important;
        color: #fff;
        margin-top: -27px;
    }

    @media (min-width: 992px) {
        .col-md-8 {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
        }

        .text--grey-110 {
            color: #EEF0F1 !important;      
            width: 56%; 
        }

        .hero--circlechart-content h1 {
            color: #EEF0F1 !important;
        }
        
        .text--red-100 {
            color: #EEF0F1 !important;
            font-family: "Campton-Book", sans-serif;
            width: 553px;
        }

        .rectangle-down {
            display: none;
        }

        .content-container {
            padding: 60px;
            margin-bottom: 40px;
            padding-left: 50px !important;
        }
    }
    .pl-hero-100{
        padding: 0;
    }
    .opeff-LI-section{
        padding: 0;
        background-color: #5D6F7B;
        color: #fff;
    }
    .help-and-faq-LI{
        display: block !important;
        color: #EEF0F1;
        padding: 60px 45px;
        .help_LIheader{
            width: 58.33333333%;
            font-family: 'Campton-Medium'!important;
           font-size: 32px !important;
          letter-spacing: 0 !important;
           line-height: 40px !important;
        }
        .help_LIsubheader{
            width: 53.333333%;
            font-size: 18px !important;
            font-family: "Campton-Book" !important;
            line-height: 27px !important;
            margin-top: 12px !important;
        }
    }
    .opeff-LI-version{
        display: none;
    }
    .dataTables_wrapper{
        width: 100%;
    }
   .createprofile_helptext{
    padding-right: 6%;
   }
}
body:not(.loggedin)  .ResourcesLandingNewPageTemplate .SeqirusToolsAndResourceMainPage-Slot{
    app-custom-user-guide{
      display: none !important;
    }
    app-custom-user-guide:nth-of-type(1){
      display: block !important;
    }
  }
  body.loggedin .ResourcesLandingNewPageTemplate .SeqirusToolsAndResourceMainPage-Slot{
    app-custom-user-guide{
      display: none !important;
    }
    app-custom-user-guide:nth-of-type(2){
      display: block !important;
    }
  }
body:not(.loggedin)  .ResourcesLandingNewPageTemplate{
    .createprofile_helptext {
        top: 30px;
    }
  
}
body.loggedin .ResourcesLandingNewPageTemplate{
    .createprofile_helptext{
        margin-bottom: 0;
    }
}