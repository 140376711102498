.ResourcesDetailsPageTemplate {
    .global_line {
        height: 2px !important;
    }

    @media (min-width: 1025px) {
        .coding-billing-sbs__resource-notes-list ul li {
            font-size: 18px;
            line-height: 27px;
        }
    }

    .coding-billing-sbs__resource-notes-list ul li {
        font-family: "Campton-Book", sans-serif;
        font-weight: 500;
        color: #5d6f7b;
        margin-bottom: 15px;
        letter-spacing: 0;
    }
    .coding-billing-sbs__resource-notes-list ul {
        padding-inline-start: 30px !important;
        margin: 0;
        // padding-left: 15px !important;
    }
    /* .headerbar {
        border-bottom: 1px solid #ececec;
    } */
}
body.loggedin .ResourcesDetailsPageTemplate{
    .coding-billing-sbs--download{
        margin-top: 4em;
    }
}