.LandingPage2Template {
    .largeparagraphcontent {
        margin-left: 40px;
        margin-top: 70px;
        margin-bottom: 40px;
    }

    // app-sixth .referencesContainerHomeUK {
    //     margin-left: -68px;
    //   }

    // .grbnumber {
    //     color: #5d6f7b !important;
    // }
}

@media (min-width:1200px){
    .LandingPage2Template {
        .largeparagraphcontent.container {
            max-width: 1395px !important;
        }
    }
}
//for homepage refernce section
body:not(.loggedin):has(app-homepage-uk-pislot) .LandingPage2Template{
    .page-references{
      .container{
        width: 100% !important; 
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      .referencesContainerHomeUK{
        margin-top: 3%;
      }
      .referencesContainerHomeUK {
        padding: 0% 52px 0% 52px;
        word-break: break-all;
    }
    .referencesContainerHomeUK p {
        padding: 20px 0;
        font-size: 14px;
        color: #5d6f7b;
        letter-spacing: 0;
        line-height: 21px;
        strong {
            font-family: "Campton-Bold", sans-serif;
        }
    }
    .grbnumber {
        font-size: 14px;
        color: #2a3237 !important;
        font-family: 'Campton-Book';
        padding: 0% 52px 0% 52px;
    }
    }
  } 