

//logout us header with mobile and desktop

  .header .SiteLogo{
    display: flex !important;


    width: 32.5%;

    flex-wrap: nowrap;
    align-items: center;

    padding-left: 40px;
    padding-bottom: 2%;
    margin-top: -12px;
    @media (min-width: 1400px) and (max-width:1600px) {
      width: 30%;
      padding-bottom: 2%;
    margin-top: 0px;
    }

    @media (min-width: 1600px) {
      width: 28.5%;
      padding-bottom: 1%;
    margin-top: 0px;
    }
    @media (max-width: 991px) {
      width: 52%;
      padding-bottom: 4%;
    margin-top: 20px !important;
    padding-left: 20px;
    }


     cx-media img[title="seqirus color logo"] {

       margin-left: 15px;

       width: 80%;
       @media (min-width: 1600px) {
        width: 75%;
       }
       @media (max-width: 991px) {
        width: 90%;
        margin-left: 5px;
       }

     }
     cx-media img[title="flu360 color logo"] {

       padding-right: 20px;
       border-right: 1px solid #DBE0E2 !important;
       width: 97%;
       @media (max-width: 991px) {
        padding-right: 5px;
       }

     }

   }

   .seqirusb2b-flu360 .BottomHeaderSlot{
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .header .BottomHeaderSlot{
    float: left;
    width: 67.5%;
    @media (min-width: 1400px) and (max-width:1600px) {
      width: 70%;
    }
    @media (min-width: 1600px) {
      width: 71,5%;
    }
    @media (max-width: 991px) {
      width: 48%;

    }
  }


 //loggedin Header for desktop and mobile

 .GigyaChangePasswordTemplate{
  .header .SiteLogo{
    max-width:400px;
    cx-media img[title="flu360 color logo"] {
      padding-right: 18px;
    }
  }
}



   header .BottomHeaderSlot{
     @media(min-width:991px){
   display: block !important;
     }
 }



header .header {
  max-width: 100% !important;
  width: 100%;
  border-bottom: 1px solid #ececec !important;
}
//loggedin use this

.loggedin .header .SiteLogo{
margin-top: 30px;
padding-left: 35px;
margin-bottom: 20px;

}

.loggedin .header{
  @media (max-width: 991px) {
  padding-top: 15px;
  }
}
.loggedin .header .SiteLogo{
  @media (max-width: 991px) {
  padding-left: 20px;
  }
}
//for uk changes use this for logout
.seqirusb2b-uk {


.header .SiteLogo {
   margin-top: 10px;
}

}
.seqirusb2b-flu360.loggedin {
  .header .SiteLogo{
    margin-top: 10px !important;
  }
}

.seqirusb2b-flu360 .primary-nav ul:first-child{margin-right: 0px !important;}
.seqirusb2b-flu360 .primary-nav ul:nth-child(1) .dropdown, .seqirusb2b-flu360 .primary-nav ul:nth-child(2) .dropdown{padding-right: 11% !important;}
.seqirusb2b-flu360 .header .SiteLogo{margin-top: -10px;}
.seqirusb2b-flu360 .dropdown-menu > li > a:hover{ color: #000 !important;}
.seqirusb2b-flu360 .second_level_menu li a:hover{color: #000 !important;}
.seqirusb2b-flu360 .dropdown_first_level{ margin-top: 32px !important;padding: 3px 38px 32px 46px !important;}
.seqirusb2b-flu360 .primary-nav ul:first-child .indicator_arrow{margin-left: 72px !important;}
.seqirusb2b-flu360 .primary-nav ul:nth-child(2) .indicator_arrow{margin-left: 32px !important;}
//for uk changes use this for loggedin
.seqirusb2b-uk .loggedin{


 
}

//1024px to 1920px scope for uk

.seqirusb2b-flu360 .LandingPage2Template {
  @media (min-width: 991px) {
      .card-collapse{
      padding: 10px 30px 25px !important;
      border-radius: 2% !important;
      height:auto !important;
      background-color: #F9FAFA;
    }
  }
    #home_flu360overview{
      margin-top: 5% !important;
    }
}

//for dashboard page sitelogo change
.seqirusb2b-uk.loggedin{
  .header .SiteLogo{
  margin-bottom: 0px !important;
  }
  .header .SiteLogo cx-media img[title="seqirus color logo"] {
    margin-left: 20px;
    width: 73%;
    margin-top: 10px;
  }
  .header .SiteLogo cx-media img[title="flu360 color logo"]{
    margin-top: 10px;
  }
}
 
.seqirusb2b-flu360.loggedin .header .SiteLogo{
  margin-bottom: 0px !important;

  @media (min-width: 1400px){
    margin-bottom: 20px !important;
  }
  }

 