.LandingPage2Template {
    @media (min-width: 1024px) {
    #home_solutionssection #home_solutiontabs .solutions--btn {
        display: none;
    }
  }
}

.LandingPage1Template {
    @media (min-width: 1024px) {
        #home_leftcontent {
            position: relative;
            bottom: 10%;
            left: 1%;
            .overViewCrumbs p {
                margin-bottom: 6%;
                margin-top: 6%;
            }
            .allproducts_heading-overView { 
                padding-top: 2%;
                width: 110%;
                span {
                    color: #ea181b;
                    font-family: "Campton-Bold";
                }
            }
            .modified-h1 {
                margin-bottom: 1%;
                .global_Lshape {
                    transform: scale(1.3);
                }
            }
        }
        #home_leftcontenttext {
            width: 72%;
            margin-top: 0 !important;
        }
        #home_loginsection {
            img {
                height: fit-content;
                left: 4%;
                bottom: 18px;
                clip-path: inset(12px 0 10px 0);
                transform: scale(1.2);
                top: 32px;
            }
        }
        #home_solutionssection {
            margin-top: 0 !important;
            //bottom: 17%;
            #home_solutionheader {
                font-size: 28px;
            }
            #home_solutionsubheader {
                font-size: 18px;
            }
            #home_solutiontabs {
                .home_tabs {
                    padding-bottom: 5% !important;

                    .home_tabsimg {
                        position: relative;
                        bottom: 14px;
                        height: 78px;
                        width: 50%;
                        margin-bottom: 0;
                    }
                    .home_tabsheader {
                        font-size: 24px;
                        position: relative;
                        bottom: 23px;
                    }
                    .home_tabscontent {
                        font-size: 18px;
                        position: relative;
                        bottom: 21px;
                    }
                }
            }
        }
        #vaccine-row {
            
            position: relative;
            .flu-vaccine--callout {
                width: 80%;
            }
        }
        #laptop-row {
            position: relative;
          
            .laptopParent .laptop-paragraph-content h2 {
                margin-bottom: 2%;
            }
        }
        #multiple-needs-header {
            margin-top: 15px;
            .section-spacing {
                .whatif--card-content {
                    width: 112%;
                }
            }
        }
        .section-spacing {
            #what-if-row {
                .center-content {
                    .whatif--card:nth-child(3) {
                        .content {
                            width: 250px;
                        }
                    }
                }
            }
        }
        #home_flu360overview {
            padding-bottom: 6.5% !important;
            .overview__2x2--cell {
                position: relative;
                width: 100%;
                left: 38%;
                bottom: 114px;
                a button {
                    font-size: 16px;
                }
            }
        }
    }
    .overview-multiple-needs-container{
       .section-spacing{
        width: 100%;
        max-width: 100%;
       }
       #what-if-row{
        margin-right: 0;
        margin-left: 0;
       }
    }
}
.AccountPageTemplate app-help-section-dashboard{
position: relative;
    padding-top: 0px;
    padding-bottom: 1px;
}
.AccountPageTemplate app-hero-overview{
    padding-top:66px;
}
.LandingPage1Template .content--expand.oversectionUK.expand--content strong{
    
    font-family: "Campton-Bold", sans-serif !important;
    color: #5d6f7b !important;
    font-size: 18px;
    line-height: 27px;
}
ul{
    font-family: "Campton-Book", sans-serif;
    font-size: 18px;
    line-height: 27px;
    color: #5d6f7b;
    padding-left:10px;
}
    .LandingPage1Template app-homepage-solution{
        padding-bottom: 54px;
    }

    .seqirusb2b-flu360 .AccountPageTemplate app-news-announcement-component {
        .dashborad_readmore {
            a:hover {
                text-decoration: underline !important;
            }
        }
    }

    .seqirusb2b-flu360 .LandingPage1Template { 
        #laptop-row {
            .dashlapimage img {
                top: 13% !important;
                transform: scale(1.2);
                bottom: 13%;
                left: 1%;
            }
        }
        #vaccine-row .flu-vaccine--callout {
            width: 70% !important;
        }
        #home_solutionssection .home_tabs{
            border-radius: 0 !important;
        }
        .overview-multiple-needs-container .section-spacing{
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    @media only screen and (min-width: 769px) and (max-width: 1200px) {
        .seqirusb2b-flu360 .LandingPage1Template {
            #home_flu360overview {
                padding: 0 !important;
            }
    
            .endHero {
                display: flex;
    
                .overview__2x2--cell {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }
    
                .simple-banner {
                    flex: 0 0 66.67%;
                    max-width: 66.67%;
                }
    
                .end-hero-callout .rectangle-up {
                    top: -10px !important;
                    left: 248px !important;
                }
            }
    
            .endHero {
                margin-bottom: 5% !important;
                width: 100%;
    
                .noHeight {
                    position: relative !important;
                    width: 100% !important;
                }
    
                .end-hero-callout {
                    margin-left: auto !important;
                    margin-right: auto !important;
                    margin-top: 30px !important;
                }
    
                .overview__2x2--cell {
                    margin-bottom: 30px;
                    left: 0;
                    bottom: 0;
    
                    .end-hero-callout {
                        width: 100%;
    
                        .rectangle-down {
                            left: 0 !important;
                        }
                    }
                }
            }
    
            .overview__2x2--cell {
                margin-bottom: 30px !important;
                left: 0 !important;
                bottom: 0 !important;
    
                .end-hero-callout {
                    width: 100%;
    
                    .rectangle-down {
                        left: 0 !important;
                    }
                }
            }
    
            .end-hero-callout .headLine {
                font-size: 28px;
                line-height: 32px;
                letter-spacing: 0;
                width: 237px;
                margin: auto;
            }
        }
    }
    @media only screen and (min-width:991px) and (max-width: 1200px) {
        .seqirusb2b-flu360 .LandingPage1Template {
            #home_loginsection {
                background-position: 30% center !important;
                background-size: 120% auto !important;
            }
        }
    }
    @media (min-width: 991px) and (max-width: 1400px) {
        .seqirusb2b-flu360 .LandingPage1Template {
            #home_leftcontent .allproducts_heading-overView {
                width: 610px !important;
            }
            .overViewCrumbs p {
                margin-bottom: 6%;
                margin-top: 6%;
            }
        }
    }
    @media only screen and (min-width: 1024px) and (max-width: 1200px){
        .seqirusb2b-flu360 .LandingPage1Template {
            #home_leftcontent {
              bottom: 0 !important;
              .overViewCrumbs p{
                margin-top: 0 !important;
              }
           }
           #what-if-row .whatif--card{
            padding-left: 20px;
            padding-right: 16px;
           }
        }
    }
    @media (min-width: 1300px) {
        .seqirusb2b-flu360 .LandingPage1Template {
            #vaccine-row .flu-vaccine--callout {
                width: 65% !important;
            }
        } 
    }
    @media only screen and (max-width: 991px) {
        .seqirusb2b-flu360 .LandingPage1Template {
                #overView_loginleftsection {
                    margin-top: 0 !important;
                    padding: 0px;
                    margin-left: auto;
                    margin-right: auto;
                }
        
                .home_loginsectionUS img {
                    position: relative !important;
                }
                .overview_us_breadcrumbs a{
                    display: inline-block !important;
                }
                #home_loginsection{
                    padding: 0;
                }
                .overview_us_image{
                    height: 275px !important;
                    min-height: 100% !important;
                }
                #home_leftheader, #home_leftcontenttext{
                    padding-left: 20px;
                    margin-right: 0 !important;
                    padding-right: 10px;
                    letter-spacing: 0;
                }
                #overView_leftoverviewbtn{
                    margin-left: 20px;
                }
                #overView_loginleftsection {
                .global_Lshape{
                    position: relative !important;
                    top: 6px;
                    left: -20px;
                    margin: 0 !important;
                }
                .allproducts_heading-overView{
                    line-height: 41px;
                    font-size: 36px;
                    span{
                        color: #ea181b;
                        font-family: "Campton-Bold";
                    }
                }
              }
              .end-hero-callout{
                .rectangle-up{
                    left: 244px !important;
                }
            }
           
                #home_loginsection {
                    min-height: 251px !important;
                    background-size: cover !important;
                    background-position-x: 100% !important;
                    .home_topfirst{
                        display: none;
                    }
                }
               #mobile_overView_loginleftsection{
                display: flex !important;
                width: 98%;
                float: none !important;
                margin-left: auto;
                margin-right: auto;
               }
            }
        }
    @media (max-width: 1023px) {
        .seqirusb2b-flu360 .LandingPage1Template {
            #home_solutionssection .solutions--btn {
                position: absolute;
                top: 25px;
                right: -9px;
                width: 50px;
                height: 50px;
                cursor: pointer;
                transform: scale(1.3);
            }
    
            #home_solutionssection .not-active {
                display: none !important;
            }
    
            #home_solutiontabs .col-md-4 {
                display: flex;
                justify-content: center !important;
                // width: 300px !important;
            }
    
    
            //Second component styling
            #vaccine-row {
                flex-direction: column-reverse;
                // height: 100%;
                // max-height: 100%;
                // float: left;
                height: fit-content;
    
                .flu-vaccine--callout {
                    width: 74% !important;
                    margin: auto;
    
                    .headLine {
                        width: 100% !important;
                        margin-left: 0;
                        //     width: 41% !important;
                        //    margin: auto;
                        //     padding: 5% 0;
                    }
                }
    
                // .flu-vaccine--image{
                //     clip-path: inset(0 0 77px 0px);
                // }
                .vaccine_row_content_col,
                .vaccine_row_img_col {
                    margin-left: auto;
                    margin-right: auto;
                    // min-height: auto !important;
                    // flex: 0 0 100%;
                    // width: 100%;
                    // max-width: 100%;
                }
            }
    
            #laptop-row {
                margin: 0 !important;
                flex-direction: column-reverse;
    
                .laptopParent {
                    padding-left: 15px;
                    padding-right: 15px;
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
    
                    .laptop-paragraph-content {
                        width: 100%;
                        padding: 15px;
                    }
                }
    
                .dashlapimage {
                    margin-left: auto;
                    margin-right: auto;
    
                    img {
                        top: 0 !important;
                        left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 40px;
                    }
                }
            }
    
            #multiple-needs-header {
                margin-left: auto;
                margin-right: auto;
    
                br {
                    display: none;
                }
    
                h2 {
                    line-height: 40px;
                    letter-spacing: 0;
                }
            }
    
            #what-if-row {
                .center-content {
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                    margin-bottom: 20px;
                    padding: 0;
    
                    .whatif--card {
                        width: 85%;
                        flex: none;
                        height: 100%;
                        max-height: 100%;
                    }
                }
            }
    
            #home_solutiontabs {
                width: 100%;
                float: left;
    
                .card-collapse,
                .card-expand {
                    float: none;
                    margin-left: auto;
                    margin-right: auto;
                    width: 85% !important;
                }
            }
    
            .overview-multiple-needs-container .whatif--card {
                width: 100%;
            }
    
            #home_flu360overview {
                padding: 0;
    
                .endHero {
                    margin-bottom: 5% !important;
                    width: 100%;
    
                    .noHeight {
                        position: relative;
                        width: 100%;
                    }
    
                    .end-hero-callout {
                        margin-left: auto !important;
                        margin-right: auto !important;
                        margin-top: 30px !important;
                    }
    
                    .overview__2x2--cell {
                        margin-bottom: 30px;
                        left: 0;
                        bottom: 0;
    
                        .end-hero-callout {
                            width: 100%;
    
                            .rectangle-down {
                                left: 0 !important;
                            }
                        }
                    }
    
                    .button--solid-red {
                        left: 0;
                    }
    
                    .create_account_link {
                        right: 0;
                        display: flex;
                        justify-content: center;
                    }
                }
    
                .end-hero-callout .headLine {
                    font-size: 28px;
                    line-height: 32px;
                    letter-spacing: 0;
                    width: 237px;
                    margin: auto;
                }
            }
        }
    }
    @media only screen and (max-width: 1024px){
        .seqirusb2b-flu360 .LandingPage1Template {
        .overview_us_breadcrumbs{
            display: block !important;
            padding: 10px 10px 10px 40px;
            strong{
              font-family: campton-Bold;
            }
        }
        .overViewCrumbs{
            display: none !important;
        }
       }
    }
    @media (max-width: 1200px) {
        .seqirusb2b-flu360 .LandingPage1Template {
            .flu-vaccine--callout .headLine {
                width: 100% !important;
            }
            .laptop-paragraph-content{
                width: 100% !important;
            }
            #laptop-row{
                margin-left: 0;
                margin-right: 0;
                .laptop-paragraph-content{
                    margin-left: 2%;
                }
            }
        }
    }