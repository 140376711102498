// .CartPageTemplate {
//   .header .SiteLogo {
//       width: 272px !important;
//       display: flex !important;
//       flex-wrap: nowrap;
//       align-items: flex-start;
//       justify-content: flex-start;
//       padding-left: 3%;
//       padding-bottom: 5%;

//       cx-media img[title="seqirus color logo"] {
//           padding-top: 18%;
//           margin-left: 10%;
//           width: 140px;
//       }

//       cx-media img[title="flu360 color logo"] {
//           padding-top: 10px;
//           padding-right: 10px;
//           border-right: 1px solid #DBE0E2 !important;
//           width: 158px;
//       }

//   }
// }

// @media only screen and (max-width: 600px) {
//   .CartPageTemplate {
//       .header .SiteLogo {
//           padding-top: 2%;

//           cx-media {
//               img[title="seqirus color logo"] {
//                   margin-left: -20px;
//                   width: 60% !important;
//               }
//           }

//           cx-media img[title="flu360 color logo"] {
//               padding-top: 10px;
//               width: 75% !important;
//           }
//       }
//   }
// }

// .CartPageTemplate .header .SiteLogo {
//   margin-right: -7% !important;
//   display: flex !important;
//   flex-wrap: nowrap;
//   align-items: flex-start;
//   justify-content: flex-start;
//   padding-bottom: 3%;
//   padding-top: 1%;

//   cx-media img[title="seqirus color logo"] {
//       padding-top: 12%;
//       width: 90%;
//       padding-left: 15px;
//   }

//   cx-media img[title="flu360 color logo"] {
//       padding-right: 10px;
//       width: 100%;
//       border-right: 1px solid #DBE0E2 !important;
//   }

// }

// @media only screen and (max-width: 600px) {
//   .CartPageTemplate .header .SiteLogo {
//       padding-top: 2%;

//       cx-media img[title="seqirus color logo"] {
//           padding-top: 22%;
//           width: 100%;
//       }

//       cx-media img[title="flu360 color logo"] {
//           padding-top: 10px;
//           padding-right: 10px;
//           width: 100%;
//       }

//       header .SiteLogo {
//           max-width: none;
//       }
//   }
// }

// header .BottomHeaderSlot {
//   @media(min-width:991px) {
//       display: block !important;
//   }
// }

// @media (min-width: 1024px) {
//   .CartPageTemplate .header .SiteLogo {
//       cx-media img[title="seqirus color logo"] {
//           padding-left: 20px;
//       }
//   }

//   .CartPageTemplate .BottomHeaderSlot {
//       width: 75%;
//       justify-content: end;
//   }
// }

// .CartPageTemplate .header .SiteLogo {
//   padding-left: 2%;
// }

// cx-page-layout.CartPageTemplate cx-page-slot {
//   align-items: flex-start;
//   max-width: none;
//   padding-top: 0;
//   padding-inline-end: 0;
//   padding-bottom: 0;
//   padding-inline-start: 0;
//   margin: 0px;
// }

// cx-page-layout.CartPageTemplate {
//   .SideContent {
//       width: 15%;
//       float: left;
//       max-width: none;
//       padding-top: 0;
//       padding-inline-end: 0;
//       padding-bottom: 0;
//       padding-inline-start: 0;
//       margin: 0px;
//       border-right: 1px solid #EEF0F1;
//   }

//   .CenterLeftContentSlot {
//       width: 85%;

//       @media (max-width:991px) {
//           width: 100%;
//       }

//       max-width: none;
//       padding-top: 0;
//       padding-inline-end: 0;
//       padding-bottom: 0;
//       padding-inline-start: 0;
//       margin: 0px;
//       align-items: flex-start;
//   }

// }

// .CartPageTemplate .header {
//   justify-content: space-between;
//   width: 100%;
// }

// .CartPageTemplate .header .SiteLogo {
//   width: 25% !important;
// }

// .CartPageTemplate .BottomHeaderSlot {
//   // width: 75%;
//   justify-content: end;
// }

// @media (min-width: 1024px) {
//   cx-page-layout.CartPageTemplate {
//       .SideContent {
//           width: 15% !important;
//       }

//       .CenterLeftContentSlot {
//           width: 85% !important;
//       }
//   }
// }

// .CartPageTemplate .header .SideContent {
//   width: 100% !important;
// }

.CartPageTemplate {
  display: block !important;
  padding: 0px !important;
  margin: 0px !important;
  max-width: 100% !important;
   main {
    cx-page-layout.CartPageTemplate {
         float: left;
    }
   }
   cx-page-layout.footer {
    float: left;
   }
   cx-media img[title="flu360 color logo"] {
    padding-top: 0px;
   }
   .header .SiteLogo {
    padding-left: 2.8% !important;
    }
    cx-media img[title="seqirus color logo"] {
        padding-top: 17%;
    }
//   .startmembership-startOrderPage3,
//   .startmembership-startOrderPage2,
//   .startmembership-startOrderPage5 {
//       display: none !important;
//   }
    iframe{
        #toolbar, #sidenav-container {
            display: none !important;
        }
}
}

.CartPageTemplate cx-page-layout.footer {
  background-color: white !important;
}

.showContent {
  display: block;

  p {
      color: #2A3237;
      font-family: 'Campton-Book' !important;
      font-size: 18px !important;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 27px;

      .membercustomerlink {
          font-family: 'Campton-Medium' !important;
          text-decoration: underline;
      }
  }
}

#start_order .selected {
  outline: 1.5px solid #6D7E88 !important;

  .selectedtick {
      display: block !important;
  }

  td:nth-of-type(1) {
      border-left: 1.5px solid #6D7E88 !important;
  }

  td:nth-of-type(4) {
      border-right: 1.5px solid #6D7E88 !important;
  }
}
#start_order {
  #cart_deletemodal .modal-content {
    padding: 15px;
}
.cart_deleteheader {
    color: #2A3237;
    font-family: 'Campton-Medium';
    font-size: 24px;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 22px;
}
.cart_deletesubheader {
    color: #5D6F7B;
    font-family: 'Campton-Book';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    padding: 0px 0 10px 10px;
}
.cart-delete_close {
    font-size: 30px;
    color: #2A3237;
    cursor: pointer;
    line-height: 27px;
}
.cart_deletebuttons {
    padding-left: 10px;
    margin-top: 15px;
}
.cart_deleteNo,
.cart_deleteYes {
padding: 10px 50px;
font-family: 'Campton-Medium';
line-height: 21px;
}
.blackarrow {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../assets/images/blackarrow.png);
  margin: 0% 0% 0% 10px;
  width: 28px;
  height: 22px;
}
.productmemberdetails {
  a {
  text-decoration: underline !important;
  }
  a:hover, a:focus {
    text-decoration: none !important;
  }
}
.cart_bulkOrder-instruction ol a, .cart_bulkOrder-instruction ol a:hover, .cart_bulkOrder-instruction ol a:focus {
  text-decoration: underline !important;
}
.cart_bulkOrder-info a, .cart_bulkOrder-info a:hover, .cart_bulkOrder-info a:focus, .cart_bulkOrder-info a:active {
  text-decoration: underline !important;
}
.radio_container {
  margin-bottom: 23px;
  align-items: center;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 10px;
  input[type=radio]::after {
    height: 0px !important;
    width: 0px !important;
  }
  .memberradiobtn:after {
    accent-color: #2A3237 !important;
}
.addmemberfield {
  padding-left: 30px;
  margin-bottom: 23px;
}
.memberquestionsection label {
  color: #2A3237;
}
}
.cartmember_input[_ngcontent-ng-c2540870191] {
  border-radius: 0;
  border: 1px solid #5D6F7B;
  display: block;
  margin-top: -14px;
  width: 100%;
  height: 40px;
  padding: 7px;
}
@media only screen and (max-width:991px) { 

    .tablecontainer tr {
        height: max-content !important;
        // width: 355% !important;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        border: 1px solid #DBE0E2 !important;
        padding: 15px !important;
        outline: none !important;
        margin-bottom: 20px;
    }
    .tablecontainer td {
        width: 100% !important;
        padding: 0px !important;
        border: none !important;
    }
    .tablecontainer tr td:nth-of-type(4) {
        width: 100% !important;
        border: none !important;
    }
    .tablecontainer tr td:nth-of-type(1) {
        border: none !important;
        padding-bottom: 20px !important;
    }
    .tablecontainer tr td:nth-of-type(2), .tablecontainer tr td:nth-of-type(3) {
        padding-bottom: 5px !important;
    }
    .membercustomerlink, .cart_summaryshippingtext a, .cart_summaryexpectedtext a {
        display: inline !important;
    }
}
}
.checkbox-button {
  padding: 0px !important;
}

.prebook_locationselect {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] {
  margin: 4px 0 0;
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
}

.prebookselectlocation .checkbox-button__control {
  border-color: #2A3237;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-color: inherit;
  color: #EA181B;
  border: 1.5px solid #666;
  transform: scale(0.75);
}

.prebook_locationselect:checked + .checkbox-button__control:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 13px;
  height: 13px;
  background-color: #EA181B;
} 
.membercustomerlink {
  font-family: 'Campton-Medium';
  text-decoration: underline;
}
.caption_1 {
  color: #fff !important;
  font-family: 'Campton-semibold' !important;
  font-size: 18px !important;
  margin-bottom: 10px;
}
.caption_email {
  font-family: campton-medium;
  font-size: 14px;
  color: #fff;
}
.caption_email a {
  font-family: campton-medium;
  font-size: 14px;
  text-decoration: underline !important;
  color: #fff;
}