//operational efficiency
.OperationalEfficiencyNewPageTemplate .text--grey-110 span{
    color:  #ea181b;
    font-weight: bold;
    font-family: "Campton-Bold", sans-serif;
  }
  .container.breadcrumbs--page-nav.breadcrumbs-opeff.d-none{
    left: 1.2%;
  }
  .text-gray span{
    color:  #ea181b; 
    font-weight: bold;
  }
  .OperationalEfficiencyNewPageTemplate, .ClinicalSupportNewPageTemplate{
    overflow-x: hidden;
  }
  .op-ef-firstcontainer .content-container .bottom_line strong{
    font-family: "Campton-SemiBold", sans-serif !important;
    color: #5d6f7b;
}
  @media(min-width:1024px){
    .OperationalEfficiencyNewPageTemplate .op-center-content, .ClinicalSupportNewPageTemplate .op-center-content{
      flex: 0 0 50%;
        max-width: 50%;
    }
    }
    @media(min-width: 1024px) {
      .OperationalEfficiencyNewPageTemplate .container, .ClinicalSupportNewPageTemplate .op-center-content{
          max-width: 100%;
      }
    }
    .OperationalEfficiencyNewPageTemplate .join360-clinical-container{
      display:none !important
     }
.OperationalEfficiencyNewPageTemplate .global_greybtn, .ClinicalSupportNewPageTemplate .global_greybtn{
    display: block !important;
}
.OperationalEfficiencyNewPageTemplate .breadcrumbs-opeff{
    display: block !important;
}
.OperationalEfficiencyNewPageTemplate .whatif--card{
    padding: 40px 30px 30px 40px !important;
}
@media  only screen and (min-width: 768px) and (max-width:1023px){
.OperationalEfficiencyNewPageTemplate .op-center-content{
    max-width: 51% !important;
    flex-basis: 100% !important;
}
}
@media  only screen and (min-width: 768px) and (max-width:1023px){
    .OperationalEfficiencyNewPageTemplate .join360-container #join360-footer #join360-row .join360--hero-container .join360--hero-container--content{
        padding-left: 11% !important;
    }
}
@media(min-width:1024px){
    .OperationalEfficiencyNewPageTemplate #what-if-row{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
.OperationalEfficiencyNewPageTemplate .callout{
  font-size: 16px !important;
  margin-top: 30px !important;
}
.OperationalEfficiencyNewPageTemplate .calloutteal{
  margin-top: 30px !important;
}
// .ClinicalSupportNewPageTemplate #join360-row .join360-clinical-container{
//   background-color: red !important;
// }
.OperationalEfficiencyNewPageTemplate .breadcrumbs--page-nav{
  display: none;
}

.OperationalEfficiencyNewPageTemplate .afluria {
  display: none !important;
}
.OperationalEfficiencyNewPageTemplate .global_redbtn{
border:1px solid #EA181B !important;
}

.OperationalEfficiencyNewPageTemplate .global_greybtn{
  border:1px solid #5d6f7b !important;
}
.OperationalEfficiencyNewPageTemplate .container.breadcrumbs--page-nav.breadcrumbs-opeff.d-none{
  // margin-top: 6%;
  position: relative !important;
  /* bottom: -23px; */
  top: 0px !important;
}
.OperationalEfficiencyNewPageTemplate .container.breadcrumbs--page-nav.breadcrumbs-opeff.d-none{
  padding-left: 6px;
}
.OperationalEfficiencyNewPageTemplate .container.vaccine-portfolio-row{
  padding-left: 5px;
  padding-right: 5px;
  }
  .OperationalEfficiencyNewPageTemplate .container.breadcrumbs--page-nav.breadcrumbs-opeff.d-none{
    width: 50%;
    position: relative;
    top: 10%;
  }

  