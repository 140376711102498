.GigyaRegistrationPageTemplate,
.GigyaProfileVerificationPageTemplate,
.ProfileActivationPageTemplate,
.VerificationEmailSentPageTemplate,
.VerificationLinkExpiredPageTemaplate,
.GigyaChangePasswordTemplate,
.GigyaChangePasswordSuccessTemplate{
    border-top: 2px solid #EEF0F1;
    display: flex;

    .LeftContentSlot {
        flex-grow: 1;
        flex: 0 0 29em;
        background-color: #F9FAFA;

        .headline {
            float: left;
            color: #2A3237;
            font-family: campton-medium;
            font-size: 32px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 40px;
            padding-top: 80px;
            text-shadow: none;
        }

        .content {
            text-shadow: none;
            color: #5D6F7B;
            font-family: campton-book;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 27px;
            margin-top: 110px;
            margin-bottom: 30px !important;
        }
    }

    .BodyContent {
        //temporary change
        display: block;
        float: right;

        cx-paragraph .createprofile_helptext {
            padding-right: 53px;
            padding-top: 20px;
        }

    }
}

.GigyaProfileVerificationPageTemplate,
.VerificationEmailSentPageTemplate,
.VerificationLinkExpiredPageTemaplate {
    .LeftContentSlot {
        background: none;
    }
}

.helpWidget {
    a {
        display: inline;
    }
}

.userguidegrey {
    color: #5D6F7B;
    font-family: campton-book;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
}

#userguideanchor:hover,
#userguideanchor:focus {
    text-decoration: underline;
}

.userguideblack {
    color: #2A3237;
    margin-left: 7px;
    font-family: campton-medium;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    top: -1px;
    position: relative;
}

.global_blackarrow {
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/blackarrow.png);
    width: 28px;
    position: absolute;
    height: 22px;
    display: inline-block;
}

.createprofile_container {
    padding-left: 0;
}



.cdcupdatepwd_left {
    padding-top: 50px !important;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit !important;
}

.home_container a,
.home_container a:hover,
.home_container a:focus,
.home_container a:active {

    height: inherit;
}

.createprofile_contentArea {
    padding-left: 0;
    display: flex;
    border-top: 1px solid #EEF0F1;
}

.createprofile_leftimagecontainer {
    background: #F9FAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.GigyaProfileVerificationPageTemplate,
.VerificationEmailSentPageTemplate,
.VerificationLinkExpiredPageTemaplate {
    .createprofile_container .createprofile_leftimagecontainer {
        background: #fff !important;
    }
}

.createprofile_leftHeader {
    color: #2A3237;
    font-family: campton-medium;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    padding-top: 80px;
}

.createprofile_leftsubHeader {
    color: #5D6F7B;
    font-family: campton-book;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 27px;
    padding-left: 0;
}

.createprofile_helptext {
    text-align: right;
    padding: 0px 25px;
    padding-right: 125px;

    @media(min-width: 1000px) and (max-width:1100px) {
        padding: 10px 0px;
    }
}

.createprofile_helplighttext {
    color: #5D6F7B;
    font-family: campton-book;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
}

.createprofile_helpdarktext {
    color: #2A3237;
    margin-left: 7px;
    font-family: campton-medium;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    top: -1px;
    position: relative;
}

.createprofile_container .global_blackarrow {
    margin-left: 7px !important;
}

.changepwd_customtop {
    padding-top: 40px;
}

/*for login page - Start*/
.nopadding_login {
    padding: 0 !important;
}

.hidebreadcrumb {
    display: none !important;
}

/*for login page- End*/
@media only screen and (min-width:469px) and (max-width:991px) {
    .createprofile_container .breadcrumb {
        margin-top: -190px !important;
        margin-left: 50px;
    }

    .createprofile_helptext {
        padding: 10px 110px;
    }
}

@media only screen and (min-width:1800px) and (max-width:1920px) {
    .createprofile_leftimagecontainer {
        padding-left: 70px;
    }

    .createprofile_cdccontent {
        padding-right: 110px;
    }
}

@media only screen and (max-width:1024px) {
    .moveupmargin {
        padding-right: 42px !important;
    }
}

@media only screen and (max-width:991px) {
    .GigyaRegistrationPageTemplate,.GigyaChangePasswordTemplate,.GigyaChangePasswordSuccessTemplate,.GigyaForgotPasswordTemplate,.GigyaResetPasswordTemplate{
        display: inline-block;
    }

    .GigyaRegistrationPageTemplate,.GigyaChangePasswordTemplate {

    .LeftContentSlot {
        background: none;
    }
}
.GigyaChangePasswordTemplate {
    .BodyContent {
        float:none;
    }
}

    .GigyaRegistrationPageTemplate,.GigyaChangePasswordTemplate {
        .BodyContent {
            display: flex;
        }
    }

    .create-profile-content {
        padding-left: 20px !important;
    }

    .createprofile_helptext {
        padding-right: 0 !important;
    }

    .moveupmargin,
    .userguideparent {
        display: flex;
        justify-content: end;
        padding-top: 0px !important;
        padding-right: 40px;
    }

    .moveupmargin {
        margin-left: 3%;
    }

    .createprofile_contentArea {
        flex-direction: column;
    }

    .createprofile_container,
    .createprofile_contentArea {
        padding-right: 0;
    }

    .createprofile_cdccontent {
        padding: 0;
        margin-top: 25px;
    }

    .createprofile_leftimagecontainer {
        background: #fff;
    }

    .createprofile_container .breadcrumb {
        padding: 10px 10px !important;
        position: absolute;
        margin-top: -240px;

    }

    .createprofile_helptext {
        margin-bottom: 15px;

    }

    .hidecomp {
        height: 300px;
        background: #fff;
    }
}

@media only screen and (max-width:912px) and (min-width: 768px) {

    .moveupmargin,
    .userguideparent {
        justify-content: end;
    }

    app-custom-user-guide .createprofile_helptext {
        margin-left: -6%;
        ;
    }
}

@media only screen and (max-width:540px) and (min-width: 540px) {

    .moveupmargin,
    .userguideparent {
        display: flex;
        justify-content: end;
        padding-top: 0px !important;
    }

    .userguideparent {
        margin-right: 50px;
    }

    .moveupmargin {
        margin-left: -50px;
    }

}

@media only screen and (max-width:1024px) {
    .GigyaProfileVerificationPageTemplate, 
    .VerificationEmailSentPageTemplate,
    .VerificationLinkExpiredPageTemaplate {
        .LeftContentSlot {
            flex: 0 0 20em;
        }
    }
}

@media only screen and (max-width:991px) {

    .GigyaProfileVerificationPageTemplate,
    .VerificationEmailSentPageTemplate,
    .VerificationLinkExpiredPageTemaplate {
        display: inline-block;

    }
}

.VerificationEmailSentPageTemplate, .VerificationLinkExpiredPageTemaplate{
    border-top: 2px solid #EEF0F1;

 .BodyContentSlot1 {
    margin-top: 40px;
    cx-paragraph:nth-child(1){
        padding-bottom: 30px;
    }
    cx-paragraph:nth-child(2) {
        font-size: 24px;
        font-family: "Campton-Medium";
        color: #2A3237;
        padding-bottom: 40px;
    }

    cx-paragraph:nth-child(3),
    cx-paragraph:nth-child(4) {
        font-size: 16px;
        color: #5D6F7B;
        font-family: "Campton-Book";
        padding-bottom: 10px;
        div{
            width: 71%;
        }
    }
}
}

@media only screen and (max-width:991px) {
    .VerificationEmailSentPageTemplate, .VerificationLinkExpiredPageTemaplate{
        border-top: 2px solid #EEF0F1;
        cx-paragraph:nth-child(2),
        cx-paragraph:nth-child(3),
        cx-paragraph:nth-child(4) {
            padding-left: 25px;
        }
        cx-paragraph:nth-child(3),
        cx-paragraph:nth-child(4) {
            width: 100%;
            div{
                width: 100% !important;
            }
        }
        .createprofile_helptext{
            padding-right: 60px !important;
        }
    }
}



@media only screen and (max-width: 991px) {
    #gigya-change-password-success-screen {
        width: 100% !important;
        padding-left: 20px !important;
    }
}


.GigyaForgotPasswordTemplate,.GigyaResetPasswordTemplate{
    border-top: 1px solid #EEF0F1 !important;
    .LeftContentSlot{
        width:33%;
        float:left;
        min-height: 450px;
        background-color: #F9FAFA;
    }
    .BodyContent{
        width:64%;
        float:left;
    }
}
.GigyaResetPasswordTemplate{
    .LeftContentSlot{
        min-height: 550px;
    }
}
@media only screen and (max-width: 991px) {
    .GigyaForgotPasswordTemplate,.GigyaResetPasswordTemplate{

        border-top: 1px solid #EEF0F1 !important;

        .LeftContentSlot{
            width:100%;
            min-height: 0px;
            .createprofile_leftimagecontainer{
                padding-left: 20px;
                padding-right: 10px;
            }
            .createprofile_leftHeader,.create-profile-content{
                padding-left: 0px !important;
            }
        }
        .BodyContent{
            width:100%;
            float:none;
        }
    }

}
@media (min-width:767px) and (max-width: 991px) {
    .GigyaForgotPasswordTemplate,.GigyaResetPasswordTemplate{
        .create-profile-content{
            max-width :75% !important;
        }
    }
}
.GigyaChangePasswordTemplate .BodyContent,.GigyaChangePasswordSuccessTemplate .BodyContent{
    width: 64%;
    flex: none;
    padding-left: 20px;
}
.GigyaChangePasswordTemplate .uk_changePass{
    padding: 0 10px !important; 
    padding-top: 40px !important;
}
.GigyaChangePasswordSuccessTemplate .uk_changePass{
    padding: 0 10px !important;
    padding-top:50px !important;
}
.footer_container .fotter_txt {
    a:hover {
        text-decoration: underline !important;
    }
}

.modal-backdrop.show{opacity:0.3 !important;}

app-custom-gigya-raas{
    letter-spacing: normal;
}
.GigyaRegistrationPageTemplate{
    .breadcrumb .bc_bold{
        display: flex !important;
    }
    .verifylink_helpheader{
      width: auto !important;
      float: none !important;
      padding-right: 49px;
    }
    .outer_blackarrow{
        display: none;
    }
}
.GigyaRegistrationPageTemplate #login-container_content .with-two-inputs .gigya-composite-control-textbox .error_icon{
    position: absolute !important;
}
.GigyaRegistrationPageTemplate #login-container_content .gigya-composite-control-textbox .error_icon{
    position: absolute !important;
}