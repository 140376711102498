.Influenza65PlusNewPageTemplate {
    #influenza-burden-65plus .hero--overview .hero--overview-content img {
      top: 20px;
      right: 10px;
    }
  
    @media (min-width: 1025px) {
      .hero--overview {
        background-position: top;
        background-size: cover;
        // background-image: url(/assets/images/influenza-65plus-hero.png) !important;
        height: 475px;
        top: -50px;
      }
    }
  
    @media (max-width: 1023px) {
      #influenza-hero {
        flex-direction: column-reverse;
      }
    }
  
    .two-row--card .two-row--card-content .card-header {
      margin-left: 5%;
    }
  
    @media (max-width: 932px) {
      #influenza-hero .influenza-hero-container .small-callout {
        padding-left: 50px;
        margin-left: 0px;
      }
    }
  
    @media (max-width: 600px) {
      .small-callout {
        position: relative;
        left: 20%;
        font-size: 14px;
      }
    }
  
    .influenza-fourth-rowflex {
      display: flex;
      box-sizing: border-box;
      margin-right: -15px;
      margin-left: -15px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -webkit-box-flex: 0;
      flex-wrap: wrap;
    }
  
    // from 1023 px we are hiding the mobile version content in the first compoennt
    @media(min-width:1023px) {
      .hide-desktop {
        display: none;
      }
    }
  
    #influenza-burden-hero .col-lg-8 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  
    .influenza-breadcrumbs {
      display: none !important;
    }
    .Influenza65PlusNewPageTemplate{
      #influenza-burden-hero a {
     
        text-transform: capitalize;
      }
    }
  }
  .Influenza65PlusNewPageTemplate
    .breadcrumbs--page-nav{
      left:13px;
    }
    .Influenza65PlusNewPageTemplate .breadcrumbs--page-nav.influenza-65breadcrumbs {
      top:3em;
    }
    
  .Influenza65PlusNewPageTemplate{
    p.text{
      width: 720px !important;
    }
    p.reference{
      line-break: auto;
      letter-spacing: normal;
      margin-bottom: 48px !important;
    }
    .row-flex.page-references {
      margin-right: 4%;
    }
    .container.col-flex-lg-12.col-flex-md-12.col-flex-sm-12.col-flex-xs-12.px-sm-20{
      max-width: 1240px !important;
      position: relative;
    }
 .strong{
  font-family: "Campton-Bold", sans-serif;
 }
  }