.coding-and-billing-container {
    .bg--teal-110 {
        background-color: #3c9a90;
    }

    .bg--grey-0 {
        background-color: #f9fafa;
    }

    .fluad-chart-container-desktop table .bottom-row td {
        font-family: "Campton-Book", sans-serif;
        font-size: 16px;
        line-height: 21px;
        color: #5d6f7b;
        padding-left: 21px;
        padding-top: 20px;
        padding-bottom: 5px;
        padding-right: 15px;
        border-right: 1px solid #92a1ac;
    }

    .flucelvax-chart-container-desktop table .bottom-row td {
        font-family: "Campton-Book", sans-serif;
        font-size: 16px;
        line-height: 21px;
        color: #5d6f7b;
        padding-left: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-right: 1px solid #92a1ac;
    }

    .afluria-chart-container-desktop table .bottom-row td {
        font-family: "Campton-Book", sans-serif;
        font-size: 16px;
        line-height: 21px;
        color: #5d6f7b;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        border-right: 1px solid #92a1ac;
    }

    .flucelvax-chart-container-desktop,
    .afluria-chart-container-desktop {
        position: relative;
        padding-left: 20px;
        border: none !important;
    }

    .flucelvax-chart-container-desktop table {
        width: 97%;
    }

    .flucelvax-chart-container-desktop .row--2 {
        border-top: 1px solid #92a1ac;
    }

    .afluria-chart-container-desktop {
        .bottom-row .border-top {
            border-top: 1px solid #92a1ac !important;
        }

        .row--2 {
            border-bottom: 1px solid #92a1ac;
        }
    }

    .semibold-row {
        font-family: "Campton-SemiBold", sans-serif !important;
        color: #2a3237 !important;
    }

    table td {
        // padding: 20px 10px;
        vertical-align: top;
    }

    .italic-footnote,
    .text-align-left {
        font-size: 16px;
        line-height: 22px;
        padding-right: 0;
        border-bottom: none !important;
        margin-bottom: 0;
        font-family: "Campton-Book", sans-serif;
        color: #5d6f7b;
        font-style: italic;
        padding-left: 0px;
    }

    .tabs--container .tabs--container--holder .tabs--container-content a {
        font-family: "Campton-SemiBold", sans-serif;
        color: #2a3237;
        font-size: 16px;
        line-height: 24px;
        position: relative;
        right: 0px;
        top: -44px;
        display: flex;
        justify-content: end;
        padding-right: 33px;
    }

    .fluad-chart-container-desktop table .top-row th,
    .flucelvax-chart-container-desktop .top-row th,
    .afluria-chart-container-desktop .top-row th {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 21px;
        font-family: "Campton-SemiBold", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #f9fafa;
        border-right: 1px solid #ffffff;
    }

    .fluad-chart-container-desktop table .bottom-row td:last-child {
        border-right: none;
    }

    .flucelvax-chart-container-desktop table .bottom-row td:last-child {
        border-right: none;
    }

    .fluad-chart-container-desktop p,sub {
        font-size: 16px;
        line-height: 22px;
        padding-right: 0;
        border-bottom: none !important;
        margin-bottom: 0;
        font-family: "Campton-Book", sans-serif;
        color: #5d6f7b;
        font-style: italic;
        padding-left: 0px;
    }
    .afluria-chart-container-desktop{
        position: relative;
    padding-left: 11px !important;
    padding-right: 24px !important;
    padding-top: 23px !important;
    }

    // .afluria-chart-container-desktop table .bottom-row td:last-child {
    //     border-right: none;
    // }

    #quick-coding-header--row table th {
        background-color: #3c9a90;
        font-weight: 400;
        text-transform: uppercase;
        vertical-align: top;
        padding-right: 38px;
        padding-bottom: 30px;
    }

    .fluad-chart-container-desktop table {
        transform: scale(0.97);
        position: relative;
        left: -15px;
        width: 100%;
    }

    .tabs--container .tabs--container--holder .tabs--container-content a img {
        // margin-top: -10px;
        margin-left: 10px;
    }
   
}
.CodingAndBillingNewPageTemplate {

    .row.slide--content.d-flex {
        margin-bottom:60px;
    }

    .col-lg-4.col-md-12.col-sm-12.left-column {
        left: 0px;
    }

    .col-lg-8.col-md-12.col-sm-12.right-column {
        top: 20px;
    }

    .d-block.w-100 {
        right: 0px !important;
    }

    .container.col-flex-lg-12.col-flex-md-12.col-flex-sm-12.col-flex-xs-12.px-sm-20 {
        margin-left: 10px;
    }

    .mdc-tab--active .mdc-tab__text-label {
        border-bottom: 3px solid red !important;
    }
    
    .mdc-tab__text-label {
        padding-bottom: 5px;
        margin-bottom: 20px;
    }

    //first component
    .hero--circlechart-content {
        h1 {
            font-size: 40px !important;
        }

        .global_greybtn {
            display: none;
        }

        width: 73% !important;
        letter-spacing: 0;

        .rectangle-down {
            left: 0 !important;
            top: 20px !important;
        }

        .content-container {
            h1 {
                margin-top: 20px !important;
                margin-bottom: 10px !important;
            }

            .text--grey-110 {
                color: #5d6f7b;
            }

            .text--red-100 {
                font-family: "Campton-Bold", sans-serif;
                color: #ea181b;
            }

            .tab--desktop-holder {
                padding-top: 10px;
            }
        }
    }

    //second
    .top-header-container {
        width: 100%;
        max-width: 100%;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .top-text {
        width: 100% !important;
        letter-spacing: 0;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    @media(max-width:991px) {
        #laptop-row {
            flex-direction: column-reverse;

            .laptop-paragraph-content {
                text-align: center;

                h2 {
                    text-align: center !important;
                }
            }

            .col-md-6 {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }

        .hero--circlechart-content {
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100% !important;
        }

        .opeffciency-circlechart .col-md-8 {
            height: auto !important;
        }

        .breadcrumbs--page-nav {
            padding-left: 0 !important;
            margin-left: 30px;
        }
    }

    .codingnbillingimage {
        display: block !important;
    }

    .laptop-paragraph-content ul {
        display: none;
    }

    .coding-billing-boldContent {
        font-family: "Campton-Medium";
        color: #2A3237;

        a {
            font-family: 'CamptonSemiBold' !important;
            color: #2A3237 !important;
            cursor: pointer;
            text-decoration: underline !important;
        }
    }

    .laptop-paragraph-content {
        padding-left: 50px !important;
        padding-top: 72px !important;
        padding-right: 60px !important;
        // text-align: left;
        margin-left: 0% !important;
        margin-top: 0% !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    @media (min-width: 1025px) {
        .laptop-paragraph-content h2 {
            font-size: 28px !important;
            line-height: 32px !important;
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }

    @media (min-width: 1025px) {
        #laptop-row {
            margin-top: 5%;
            background-color: #f9fafa;
            padding-bottom: 50px;
        }
    }

    #home_resourcessection {
        // padding-bottom: 0px !important;
        // padding-left: 10px !important;
        // padding-right: 10px !important;
        // padding-top: 0px !important;
        padding: 0px 10px !important;
        margin-top: 0px !important;
    }

    .global_greybtncontainer {
        margin-top: 72px !important;
        margin-bottom: 72px !important;
    }

    .global_line {
        height: 2px !important;
    }

    #home_dashleftpadding {
        margin-top: 72px;
        margin-bottom: 30px;
    }

    .home_resources {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .home_resources:nth-of-type(2) {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    #home_clinicalheader,
    #home_clinicalsubheader,
    #home_clinicaltext,
    #home_clinicallink {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .home_resourcelink {
        border-top: 1px solid #eef0f1 !important;
    }

    .global_greybtn {
        font-family: "Campton-SemiBold", sans-serif;
        border: 1px solid #5d6f7b !important;
        padding: 17px 29px 17px 29px !important;
    }

    //third styling
    .carousel-inner .left-column {
        max-width: 40%;
        padding-left: 4% !important;
        padding-right: 8% !important;
    }

    .carousel-inner .right-column {
        max-width: 60%;
        padding-left: 0px !important;

        img {
            max-width: 79% !important;
            clip-path: inset(0 1px 0 0);
            // width: 628px;
            height: auto;
            margin-left: 90px;
        }
    }

    .large--carousel-btn-container {
        padding-left: 4% !important;
        margin-bottom: 0px !important;
    }

    .large--carousel-btn-container .prev-icon {
        margin-right: 40px !important;
    }

    .carousel-inner .left-column p {
        font-family: "Campton-Medium", sans-serif !important;
    }

    @media(max-width:767px) {
        .custom-carousel-control-next {
            padding-left: 48% !important;
        }
    }

    @media (min-width:768px) and (max-width:991px) {
        .custom-carousel-control-next {
            padding-left: 30% !important;
        }
    }

    @media(max-width:991px) {
        .slide--content {
            justify-content: center !important;
        }
    }

    //refernces styling 
    .mb-30 {
        margin-bottom: 30px;
    }

    .about-ref {
        margin-right: 0 !important;
        margin-left: 0 !important;
        font-size: 16px !important;

        strong {
            font-family: "Campton-Bold", sans-serif;
        }
    }

    @media(max-width:1023px) {
        .about-ref {
            line-height: 24px;
            color: #5d6f7b;
            width: 90%;
            margin-left: auto !important;
            margin-right: auto !important;
            word-break: break-word;
        }
    }

    @media (min-width: 1024px) {
        .about-ref {
            width: 100%;
            word-break: initial;
        }

        .CodingAndBillingNewPageTemplate .page-references .reference {
            width: 115% !important;
        }

        .CodingAndBillingNewPageTemplate .col-sm-12.text-center {
            top: -60px !important;
        }

        .CodingAndBillingNewPageTemplate .laptop-image{
            right: 4% !important;
            margin-top: 140px;
            left: 115px;        
        }
    }

    .clinical-breadcrumbs {
        display: none;
    }

    .codingnbilling-breadcrumbs,
    .coding-billing-boldContent {
        display: block !important;
    }

    .button--download-outline-red {
        display: inline-block !important;
    }

    .codingnbilling-breadcrumbs a {
        display: inline;
    }

    @media (min-width: 1024px) {
        .codingnbilling-breadcrumbs {
            top: 2em !important;
        }
    }

    .mobile--coding-and-billing-container {
        .chart-header {
            font-size: 16px;
            font-family: "Campton-SemiBold", sans-serif;
            line-height: 22px;
            text-align: left;
            padding-top: 30px;
            color: #5d6f7b;
        }
        hr{
            border: 1px solid #aeb7bd;
        }
        @media only screen and (max-width: 768px) {
            hr {
                width: 90%;
            }
        }
        ul{
            padding-left: 0 !important;
        }
        ul li {
            list-style: none;
            text-align: left;
            padding-top: 30px;
            font-family: "Campton-Book", sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #5d6f7b;
            .row-header {
                font-size: 16px;
                font-family: "Campton-SemiBold", sans-serif;
                line-height: 22px;
            }
        }
    }
}
 .CodingAndBillingNewPageTemplate{
    .breadcrumbs--page-nav.codingnbilling-breadcrumbs.d-none{
        margin-left: 39px !important;
    }
    .row-flex.page-references{
        padding: 0px 6%;
        line-height: 24px;
        position: relative;
        right: 75px ;
        margin-bottom:30px;
    }
    .reference{
        font-size: 16px !important;
    }
    p.reference1 strong{
        font-family: "Campton-Bold", sans-serif;
    }
}
.CodingAndBillingNewPageTemplate{
    header{
      border-bottom: 1px solid #ececec !important;
    }
}